import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '../constants'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'
import { ProjectModel } from '@/models/responces/project.model'
import { TaskModel } from '@/models/responces/task.model'

export class ProjectSubscription {
  static projectList(workspaceId: string, callback: DefaultSubscribeCallback<ProjectModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.PROJECTS)
      .where('workspaceId', '==', workspaceId)
      .onSnapshot(data => {
        callback(
          data.docs.map(
            doc =>
              ({
                ...doc.data(),
              } as ProjectModel)
          )
        )
      })
  }

  static unassignedTasksByProject(project: ProjectModel, callback: DefaultSubscribeCallback<string, TaskModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.TASKS)
      .where('projectId', '==', project.id)
      .where('employeeId', '==', null)
      .onSnapshot(data => {
        callback(
          project.id,
          data.docs.map(doc => ({ ...doc.data() } as TaskModel))
        )
      })
  }

  static assignedTasksByProjectAndDate(
    project: ProjectModel,
    startDate: number,
    endDate: number,
    callback: DefaultSubscribeCallback<string, TaskModel[]>
  ) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.TASKS)
      .where('projectId', '==', project.id)
      .where('centerDate', '>=', startDate)
      .where('centerDate', '<=', endDate)
      .onSnapshot(data => {
        callback(
          project.id,
          data.docs.map(d => ({ ...d.data() } as TaskModel))
        )
      })
  }
}
