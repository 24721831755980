import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { runTransaction } from '@/firebase/helpers'
import { EmployeeModel } from '@/models/responces/employee.model'
import { Invitation } from '@/models/responces/invitation.model'
import { ProcessModel } from '@/models/responces/process.model'
import { ProjectModel } from '@/models/responces/project.model'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { Member, MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'

export abstract class WorkspaceTransactions {
  @HandleAsyncErrors()
  public static async editWorkspace(workspace: WorkspaceModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.WORKSPACES).doc(workspace.id).update(workspace)
  }

  @HandleAsyncErrors()
  public static async addMemberToWorkspace(workspaceId: string, memberId: string, memberEmail: string) {
    const workspace = await FirebaseUsage.database()
      .collection(COLLECTIONS.WORKSPACES)
      .where('id', '==', workspaceId)
      .get()
      .then(doc => doc.docs[0].data() as WorkspaceModel)

    const workspaceMembers = workspace && workspace.members ? [...workspace.members] : []

    if (!workspaceMembers.find(m => m.userId === memberId)) {
      const newMember: Member = {
        userId: memberId,
        role: MemberRoles.VIEWER,
        userEmail: memberEmail
      }

      workspaceMembers.push(newMember)
    }

    await FirebaseUsage.database()
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspace.id)
      .update({ members: workspaceMembers })
  }

  @HandleAsyncErrors()
  public static async createWorkspace(workspace: WorkspaceModel) {
    const workspaceRef = FirebaseUsage.database().collection(COLLECTIONS.WORKSPACES).doc()

    await runTransaction(async transaction => {
      await transaction.set(workspaceRef, { ...workspace, id: workspaceRef.id })
    })

    return workspaceRef.id
  }

  @HandleAsyncErrors()
  public static async deleteWorkspace(workspace: WorkspaceModel) {
    await runTransaction(async transaction => {
      const employees: EmployeeModel[] = await FirebaseUsage.database()
        .collection(COLLECTIONS.EMPLOYEES)
        .where('workspaceId', '==', workspace.id)
        .get()
        .then(data => data.docs.map(d => ({ ...d.data() } as EmployeeModel)))

      employees.forEach(e => {
        const employeeRef = FirebaseUsage.database().collection(COLLECTIONS.EMPLOYEES).doc(e.id)

        transaction.del(employeeRef)
      })

      const invitations: (Invitation & { id: string })[] = await FirebaseUsage.database()
        .collection(COLLECTIONS.INVITATIONS)
        .where('workspaceId', '==', workspace.id)
        .get()
        .then(data => data.docs.map(d => ({ ...d.data(), id: d.id } as Invitation & { id: string })))

      invitations.forEach(i => {
        const invitationRef = FirebaseUsage.database().collection(COLLECTIONS.INVITATIONS).doc(i.id)
        transaction.del(invitationRef)
      })

      const processes: ProcessModel[] = await FirebaseUsage.database()
        .collection(COLLECTIONS.PROCESSES)
        .where('workspaceId', '==', workspace.id)
        .get()
        .then(data => data.docs.map(d => ({ ...d.data() } as ProcessModel)))

      processes.forEach(p => {
        const processRef = FirebaseUsage.database().collection(COLLECTIONS.PROCESSES).doc(p.id)
        transaction.del(processRef)
      })

      const projects: ProjectModel[] = await FirebaseUsage.database()
        .collection(COLLECTIONS.PROJECTS)
        .where('workspaceId', '==', workspace.id)
        .get()
        .then(data => data.docs.map(d => ({ ...d.data() } as ProjectModel)))

      projects.forEach(p => {
        const projectRef = FirebaseUsage.database().collection(COLLECTIONS.PROJECTS).doc(p.id)
        transaction.del(projectRef)
      })

      const timeoffs: TimeoffModel[] = await FirebaseUsage.database()
        .collection(COLLECTIONS.TIMEOFFS)
        .where('workspaceId', '==', workspace.id)
        .get()
        .then(data => data.docs.map(d => ({ ...d.data() } as TimeoffModel)))

      timeoffs.forEach(t => {
        const timeoffRef = FirebaseUsage.database().collection(COLLECTIONS.TIMEOFFS).doc(t.id)
        transaction.del(timeoffRef)
      })

      const workspaceRef = FirebaseUsage.database().collection(COLLECTIONS.WORKSPACES).doc(workspace.id)

      await transaction.del(workspaceRef)
    })
  }
}
