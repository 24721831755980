import store from '../../store/store'
import { HttpRequestError } from '@/utils/decorators/errors'
import { SnackbarTypes } from '@/shared/snackbars/snackbar'
import { SnackbarActionNames } from '@/store/snackbar/snackbar.actions'

/**
 * Config to be passed to the function below used as decorator.
 * @property ignoreError: if set to true (defaults to false) will ignore error handling and will rethrow the error to be handled another way
 *                         Useful in case if we want to handle the error some specific way (e.g. show form validation error)
 * @property handleOnlyHttpErrors: if set to true will handle ONLY instances of HttpRequestError. Consult restAxios.ts to see usage example
 * @property messageMapping: allows to pass a map containing mapping for the statuses and errors. Let's say we want to have different error
 *                         messages for different error types and the BE does not provide the specific error message.
 *                         If nothing is passed or there's not mapping for specific status - the decorator will try to use BE response or empty string
 *                         Example: {"401" => "You're not authorized, get the **** out of here", "500" => "BE is down, **** this!"}
 *
 */
export class AsyncErrorHandlerConfig {
  ignoreError: boolean = false
  handleOnlyHttpErrors: boolean = false
  messageMapping: Map<number, string> = new Map<number, string>()
}

// const defaultConfig = new AsyncErrorHandlerConfig();

export default function HandleAsyncErrors(config?: AsyncErrorHandlerConfig) {
  // note: for some reasons TS can't access AsyncErrorHandlerConfig oustide of function so keep it that way :(
  const conf: AsyncErrorHandlerConfig = config || {
    ignoreError: false,
    messageMapping: new Map<number, string>(),
    handleOnlyHttpErrors: false,
  }
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const fn = descriptor.value
    descriptor.value = async (...params: any[]) => {
      try {
        // @ts-ignore
        return await fn.apply(this, params)
      } catch (err) {
        if (conf.ignoreError) {
          throw err
        }
        if (!conf.handleOnlyHttpErrors || (conf.handleOnlyHttpErrors && err instanceof HttpRequestError)) {
          let text
          // if we have some specific FE-side mapping for error (e.g. - custom message for 401 Unauthorized error)
          if (err.message && err.status && conf.messageMapping.has(err.status)) {
            text = conf.messageMapping.get(err.status)!
          } else if (err.message) {
            text = err.message
          } else {
            text = 'Unknown Error - try again later'
          }
          store.dispatch({
            type: SnackbarActionNames.SNACKBAR_ADD,
            payload: {
              snackbar: {
                title: text,
                text: '',
                type: SnackbarTypes.ERROR,
              },
            },
          })
          throw err
        }
      }
    }
  }
}
