import React, { useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControlLabel,
  IconButton,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ProjectModel } from '@/models/responces/project.model'
import { TaskModel } from '@/models/responces/task.model'
import css from './css.module.scss'
import ConfirmationDialog from '@/shared/dialog/ConfirmationDialog'
import { useDispatch, useSelector } from 'react-redux'
import ProjectThunk from '@/store/project/project.thunk'
import { setEditableProject } from '@/store/project/project.actions'
import { RootStateModel } from '@/store/root-reducer'
import { getTaskCenterDate, recoordinateStage } from '@/utils/helpers/get-valid-task-time'
import { EmployeeModel } from '@/models/responces/employee.model'
import TaskThunk from '@/store/task/task.thunk'
import { roundCalendarTime } from '@/utils/transform-time'
import editProjectIcon from '../../../../assets/icons/edit-project.svg'
import expandIcon from '../../../../assets/icons/expand-icon.svg'
import CreateProjectDialog from '@/pages/project/CreateProjectDialog'
import TaskListItem from '@/pages/project/components/project-list-sidebar/TaskListItem'
import DraggableSharedTask from '@/pages/calendar/DraggableSharedTask'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import classNames from 'classnames'
import { MemberRoles } from '@/models/responces/workspace.model'
import { useRequireRole } from '@/utils/useRequireRole'

interface InjectedProps {
  project: ProjectModel
  tasksList: TaskModel[]
  containerRef: React.RefObject<HTMLDivElement>
  isDraggable: boolean
}

const useStyles = makeStyles({
  expandIcon: {
    margin: '0 0 0 10px !important',
  },
})

const ProjectListItem: React.FC<InjectedProps> = ({ project, tasksList, containerRef, isDraggable }) => {
  const scrollRef = useSelector<RootStateModel, any>(state => state.calendar.scrollRef)
  const timelineRef = useSelector<RootStateModel, any>(state => state.calendar.timelineRef)
  const employeesList = useSelector<RootStateModel, EmployeeModel[] | null>(state => state.employee.employeesList)
  const [isDragged, setIsDragged] = useState(false)
  const [draggedElementInitialOffsetTop, setDraggedElementInitialOffsetTop] = useState<number>(0)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const dispatch = useDispatch()
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialog(!deleteDialog)
  }, [deleteDialog])

  const deleteCurrentProject = useCallback(() => {
    dispatch(ProjectThunk.deleteProject(project.id, tasksList))
    toggleDeleteDialog()
  }, [dispatch, project, tasksList, toggleDeleteDialog])

  const handleItemDrop = useCallback(
    ({ task, start, groupKey }: any) => {
      if (employeesList) {
        const employeeIndex = parseInt(groupKey, 10)
        let taskToSave: TaskModel = { ...task, employeeId: employeesList[employeeIndex].id }
        const newStartTime = roundCalendarTime(start)
        const stagesToSave = recoordinateStage(task.process.stages[0], [], task.process.stages, newStartTime.valueOf())

        dispatch(
          TaskThunk.editTask({
            ...taskToSave,
            process: { ...taskToSave.process, stages: stagesToSave },
            centerDate: getTaskCenterDate(stagesToSave),
          })
        )
      }
    },
    [employeesList, dispatch]
  )

  const [openEditDialog, setOpenEditDialog] = useState(false)

  const toggleEditDialog = useCallback(() => {
    setOpenEditDialog(!openEditDialog)
  }, [openEditDialog])

  const closeEditDialog = useCallback(() => {
    setOpenEditDialog(false)
  }, [])

  const onEditClick = useCallback(
    event => {
      event.stopPropagation()
      dispatch(setEditableProject(project))
      toggleEditDialog()
    },
    [project, dispatch, toggleEditDialog]
  )

  const onDragStart = (e: DraggableEvent, data: DraggableData) => {
    setDraggedElementInitialOffsetTop(data.node.getBoundingClientRect().top)
  }

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    setIsDragged(true)
  }

  const onStop = (e: DraggableEvent, data: DraggableData) => {
    setIsDragged(false)
    const { node } = data

    if (containerRef && containerRef.current) {
      const projectElements = Array.from(containerRef.current.children)
      let newIndex = 0
      const containerOffsetTop = containerRef.current.getBoundingClientRect().top
      const relativeDraggedElementTopPosition = node.getBoundingClientRect().top - containerOffsetTop
      const relativeDraggedElementBottomPosition = node.getBoundingClientRect().bottom - containerOffsetTop
      const draggedElementHeight = node.getBoundingClientRect().height
      const relativeInitialDraggedElementTopPosition = draggedElementInitialOffsetTop - containerOffsetTop
      const halfHeight = Math.floor(draggedElementHeight / 2)

      // preserve element at its own position
      if (
        relativeDraggedElementTopPosition <= relativeInitialDraggedElementTopPosition + halfHeight &&
        relativeDraggedElementTopPosition >= relativeInitialDraggedElementTopPosition - halfHeight
      ) {
        return
      }

      projectElements.forEach((el: Element, index: number) => {
        if (el.className === node.className) {
          return
        }

        const elementOffsetTop = el.getBoundingClientRect().top
        const relativeCurrentElementTopPosition = elementOffsetTop - containerOffsetTop
        const currentElementHeight = el.getBoundingClientRect().height
        const halfElementHeight = Math.floor(currentElementHeight / 2)

        // dragging above
        if (
          relativeCurrentElementTopPosition <= relativeDraggedElementTopPosition &&
          relativeDraggedElementTopPosition <= relativeCurrentElementTopPosition + halfElementHeight
        ) {
          newIndex = index
        }
        // dragging below
        else if (relativeDraggedElementBottomPosition >= relativeCurrentElementTopPosition + halfElementHeight) {
          newIndex = index
        }
      })

      dispatch(ProjectThunk.reorderProjects(project.orderIndex, newIndex))
    }
  }

  const styles = useStyles()

  return (
    <>
      <Draggable
        defaultClassNameDragged={css['dragged']}
        defaultClassNameDragging={css['dragging']}
        defaultClassName={css[isDragged ? '' : 'draggable-root']}
        onStop={onStop}
        onDrag={onDrag}
        onStart={onDragStart}
        axis={'y'}
        position={{ x: 0, y: 0 }}
        disabled={!isDraggable}
      >
        <Accordion
          className={classNames(css['accordion'], {
            [css['draggable']]: isDragged,
          })}
        >
          <AccordionSummary
            expandIcon={<img src={expandIcon} alt="" />}
            classes={{ content: css['summary'], root: css['root'], expandIcon: styles.expandIcon }}
          >
            <Box className={css['accordion__info']}>
              <Typography variant={'h2'} className={css['accordion__info__name']}>
                {project.name}
              </Typography>
            </Box>
            <Box>
              {isRoleAllowed && (
                <FormControlLabel
                  className={css['accordion__icon']}
                  onClick={onEditClick}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <IconButton>
                      <img src={editProjectIcon} alt="" />
                    </IconButton>
                  }
                  label={''}
                />
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails className={css['accordion__details']}>
            <Container className={css['tasks']}>
              <List className={css['tasks__tasks-list']}>
                {tasksList.map((task, index) => {
                  return (
                    !task.employeeId && (
                      <DraggableSharedTask
                        key={task.id}
                        handleDrop={handleItemDrop}
                        timelineRef={timelineRef}
                        scrollRef={scrollRef}
                        task={task}
                      >
                        <TaskListItem task={task} key={index} />
                      </DraggableSharedTask>
                    )
                  )
                })}
              </List>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Draggable>
      <ConfirmationDialog
        title={`Are you sure you want to delete ${project.name} project?`}
        open={deleteDialog}
        onClose={toggleDeleteDialog}
        confirmAction={deleteCurrentProject}
      />

      {openEditDialog && <CreateProjectDialog onClose={closeEditDialog} open={openEditDialog} />}
    </>
  )
}

export default ProjectListItem
