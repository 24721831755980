import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, TextField, Typography } from '@material-ui/core'
import { ProcessModel } from '@/models/responces/process.model'
import css from './css.module.scss'
import expandIcon from '@/assets/icons/expand-icon-2.svg'
import { DropdownActionModel } from '@/models/dropdown-action.model'
import { ProjectModel } from '@/models/responces/project.model'

interface InjectedProps {
  options: (ProcessModel | DropdownActionModel | ProjectModel)[] 
  label: string
  value: DropdownActionModel | ProcessModel | ProjectModel | null
  onChange: (value: ProcessModel | DropdownActionModel | ProjectModel | null) => void
  disabled?: boolean
}

const AutocompleteShared: React.FC<InjectedProps> = ({ options, label, value, onChange, disabled }) => {
  return (
    <Box className={css['wrapper']}>
      <Typography variant={'body2'} className={css['wrapper__label']}>
        {label}
      </Typography>
      <Autocomplete
        popupIcon={<img src={expandIcon} alt="" />}
        className={css['autocomplete']}
        options={options}
        getOptionLabel={(option: ProcessModel | DropdownActionModel | ProjectModel) => option.name}
        value={value}
        inputValue={value === null ? "" : value.name}
        onChange={(event, newValue: ProcessModel | DropdownActionModel | ProjectModel | null) => {
          onChange(newValue)
        }}
        disabled={disabled}
        renderInput={params => (
          <TextField
            classes={{ root: css['autocomplete__input'] }}
            {...params}
            placeholder={label}
            variant="outlined"
            disabled={disabled}
          />
        )}
      />
    </Box>
  )
}

export default AutocompleteShared
