import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { ProjectModel } from '@/models/responces/project.model'
import { SetTaskModel } from '@/models/responces/task.model'

export enum ActionNames {
  ADD_PROJECT = '[Project] Add Project',
  ADD_PROJECT_SUCCESS = '[Project] Add Project Success',
  ADD_PROJECT_FAILED = '[Project] Add Project Failed',

  SET_PROJECTS = '[Project] Set Projects',
  SET_UNASSIGNED_TASKS = '[Project] Set Unassigned Tasks',
  SET_ASSIGNED_TASKS = '[Project] Set Assigned Tasks',

  DELETE_PROJECT = '[Project] Delete Project',
  DELETE_PROJECT_SUCCESS = '[Project] Delete Project Success',
  DELETE_PROJECT_FAILED = '[Project] Delete Project Failed',

  EDIT_PROJECT = '[Project] Edit Project',
  EDIT_PROJECT_SUCCESS = '[Project] Edit Project Success',
  EDIT_PROJECT_FAILED = '[Project] Edit Project Failed',

  SET_EDITABLE_PROJECT = '[Project] Set Editable Project',

  RESET_LOADING = '[Project] Reset Loading',
}

export const addProjectAsync = createAsyncAction(
  ActionNames.ADD_PROJECT,
  ActionNames.ADD_PROJECT_SUCCESS,
  ActionNames.ADD_PROJECT_FAILED
)<undefined, undefined, Error>()

export const setProjects = createAction(ActionNames.SET_PROJECTS)<ProjectModel[]>()
export const setUnassignedTasks = createAction(ActionNames.SET_UNASSIGNED_TASKS)<SetTaskModel>()
export const setAssignedTasks = createAction(ActionNames.SET_ASSIGNED_TASKS)<SetTaskModel>()

export const deleteProjectAsync = createAsyncAction(
  ActionNames.DELETE_PROJECT,
  ActionNames.DELETE_PROJECT_SUCCESS,
  ActionNames.DELETE_PROJECT_FAILED
)<undefined, string, Error>()

export const editProjectAsync = createAsyncAction(
  ActionNames.EDIT_PROJECT,
  ActionNames.EDIT_PROJECT_SUCCESS,
  ActionNames.EDIT_PROJECT_FAILED
)<undefined, undefined, Error>()

export const setEditableProject = createAction(ActionNames.SET_EDITABLE_PROJECT)<ProjectModel | null>()

export const resetLoading = createAction(ActionNames.RESET_LOADING)()

export type TProjectActions = ActionType<
  | typeof addProjectAsync
  | typeof resetLoading
  | typeof setUnassignedTasks
  | typeof setAssignedTasks
  | typeof setProjects
  | typeof deleteProjectAsync
  | typeof setEditableProject
  | typeof editProjectAsync
>
