import { StageModel } from '@/models/responces/stage.model'

export const getTaskStartDate = (stages: StageModel[]): number => {
  return stages[0].date[0].startDate
}

export const getTaskEndDate = (stages:  StageModel[]): number => {
  const lastStageDates = stages[stages.length - 1].date
  return lastStageDates[lastStageDates.length - 1].endDate
}
