import React, { useCallback, useMemo, useState } from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import sharedCss from '../workspace/css.module.scss'
import css from './css.module.scss'
import ProjectManaging from '@/pages/project-managing/project-managing'
import ProcessSettings from '@/pages/process/ProcessSettings'
import ProjectList from '@/pages/project/components/project-list-sidebar/ProjectList'
import Header from '@/shared/header/Header'
import logo from '../../assets/icons/logo.svg'
import UserTransactions from '@/store/user/user.transactions'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { UserModel } from '@/models/responces/user.model'
import * as uuid from 'uuid'
import TextInput from '@/shared/text-input/InputShared'
import { Cancel } from '@material-ui/icons'
import editProjectIcon from '../../assets/icons/edit-project.svg'
import moment from 'moment'
import ButtonShared from '@/shared/button/ButtonShared'
import { addSnackbar } from '@/store/snackbar/snackbar.actions'
import { SnackbarTypes } from '@/shared/snackbars/snackbar'
import { CollapseIcon } from '../workspace/CollapseIcon'
import classNames from 'classnames'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { useHistory } from 'react-router-dom'
import RouteNameConstants from '@/constants/route-name.constants'

enum CredentialMode {
  EDIT = 'edit',
  VIEW = 'view',
}

interface UserCredentialsView {
  mode: CredentialMode
  value: string
  key: keyof UserModel
  id: string
  label: string
}

export const Profile = () => {
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)
  const selectedWorkspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const dispatch = useDispatch()
  const history = useHistory()
  const defaultUserCredentialsViews: UserCredentialsView[] = useMemo(() => {
    if (user) {
      const userName: UserCredentialsView = {
        mode: CredentialMode.VIEW,
        key: 'name',
        value: user.name,
        id: uuid.v4(),
        label: 'Name',
      }
      const userSurname: UserCredentialsView = {
        mode: CredentialMode.VIEW,
        key: 'surname',
        value: user.surname,
        id: uuid.v4(),
        label: 'Surname',
      }
      return [userName, userSurname]
    }
    return []
  }, [user])
  const [userCredentialsViews, setUserCredentialsViews] = useState<UserCredentialsView[]>(defaultUserCredentialsViews)
  const userDateOfCreationToDisplay = useMemo(() => {
    if (user && user.dateOfCreation) {
      return moment(user.dateOfCreation.toDate()).format('dddd, MMMM Do YYYY, h:mm:ss a')
    }

    return ''
  }, [user])

  const changeUserCredentials = useCallback(
    (val: string, id: string) => {
      setUserCredentialsViews(c => {
        const credential = c.find(cred => cred.id === id)

        if (credential) {
          const copy = [...c]
          const credentialIndex = c.findIndex(cred => cred.id === id)
          copy[credentialIndex] = { ...credential, value: val }
          return copy
        }

        return c
      })
    },
    [setUserCredentialsViews]
  )

  const toggleMode = useCallback((id: string, mode: CredentialMode) => {
    setUserCredentialsViews(c => {
      const credential = c.find(cred => cred.id === id)

      if (credential) {
        const copy = [...c]
        const credentialIndex = c.findIndex(cred => cred.id === id)
        copy[credentialIndex] = { ...credential, mode }
        return copy
      }

      return c
    })
  }, [])

  const onSave = useCallback(async () => {
    if (user) {
      try {
        const newUser: UserModel = { ...user }
        userCredentialsViews.forEach(c => {
          newUser[c.key as 'name' | 'surname'] = c.value
        })
        await UserTransactions.editUser(newUser)
        dispatch(
          addSnackbar({
            snackbar: {
              text: '',
              type: SnackbarTypes.SUCCESS,
              title: 'Credentials have been updated',
            },
          })
        )
      } catch (err) {}
    }
  }, [userCredentialsViews, user, dispatch])

  const onLogoClick = useCallback(() => {
    if (!selectedWorkspace) {
      history.push(`/${RouteNameConstants.WORKSPACES}`)
      return
    }

    history.push(`/${RouteNameConstants.WORKSPACES}/${selectedWorkspace.id}`)
    return
  }, [selectedWorkspace, history])

  return (
    <Box className={sharedCss['root']}>
      <Box className={sharedCss['wrapper']}>
        <Box
          className={classNames(sharedCss['wrapper__sidebar'], {
            [sharedCss['wrapper__sidebar--collapsed']]: isSidebarCollapsed,
          })}
        >
          <img onClick={onLogoClick} src={logo} alt="" className={sharedCss['wrapper__sidebar__logo']} />
          <ProjectList />
          <ProjectManaging />
          <ProcessSettings />
          <CollapseIcon />
        </Box>
        <Box
          className={classNames(sharedCss['wrapper__calendar'], {
            [sharedCss['wrapper__calendar--collapsed']]: isSidebarCollapsed,
          })}
        >
          <Header />
          <Box className={css['credentials-wrapper']}>
            {userCredentialsViews.map(c => {
              if (c.mode === CredentialMode.VIEW) {
                return (
                  <Box key={c.id} className={css['credential']}>
                    <Box className={css['inner']}>
                      <Typography className={css['label']} variant={'body2'}>
                        {c.label}
                      </Typography>
                      <Typography className={css['val']} variant={'body2'}>
                        {c.value}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => toggleMode(c.id, CredentialMode.EDIT)} className={css['icon']}>
                      <img src={editProjectIcon} alt="" />
                    </IconButton>
                  </Box>
                )
              }

              if (c.mode === CredentialMode.EDIT) {
                return (
                  <Box key={c.id} className={css['credential']}>
                    <TextInput label={c.label} value={c.value} onChange={e => changeUserCredentials(e, c.id)} />
                    <IconButton onClick={() => toggleMode(c.id, CredentialMode.VIEW)} className={css['icon']}>
                      <Cancel />
                    </IconButton>
                  </Box>
                )
              }

              return null
            })}
            <Box className={classNames(css['credential'], css['mb-0'])}>
              <Typography style={{ color: '#667A84' }} variant={'body2'}>
                Date of creation
              </Typography>
              <Typography variant={'body2'}>{userDateOfCreationToDisplay}</Typography>
            </Box>
            <Box className={css['credential']}>
              <Typography style={{ color: '#667A84' }} variant={'body2'}>
                Email
              </Typography>
              <Typography variant={'body2'}>{user?.email}</Typography>
            </Box>
            <ButtonShared color={'primary'} text={'Save'} onClick={onSave} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
