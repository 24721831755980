import React, { useCallback, useEffect, useState } from 'react'
import { Box, Drawer, IconButton, Typography } from '@material-ui/core'
import expandIcon from '../../assets/icons/expand-icon.svg'
import css from './css.module.scss'
import TextAreaShared from '@/shared/text-input/TextAreaShared'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { NoteModel } from '@/models/responces/note.model'
import { RequestState } from '@/constants/request-state'
import NoteThunk from '@/store/note/note.thunk'
import { UserModel } from '@/models/responces/user.model'

interface InjectedProps {
  open: boolean
  toggleDrawer: () => void
}

const NoteDrawer: React.FC<InjectedProps> = ({ open, toggleDrawer }) => {
  const [text, setText] = useState('')

  const dispatch = useDispatch()
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const note = useSelector<RootStateModel, NoteModel | null>(state => state.note.note)
  const loadingSaveNote = useSelector<RootStateModel, RequestState>(state => state.note.loadingSaveNote)

  useEffect(() => {
    if (note) {
      setText(note.text)
    }
  }, [note])

  const changeText = useCallback((value: string) => {
    setText(value)
  }, [])

  const save = useCallback(() => {
    if (note && note.text !== text) {
      dispatch(NoteThunk.editNote({ ...note, text }))
    }
    if (!note && user) {
      dispatch(NoteThunk.addNote({ ownerId: user.id, text }))
    }
  }, [dispatch, note, user, text])

  const close = useCallback(() => {
    save()
    toggleDrawer()
  }, [toggleDrawer, save])

  return (
    <Drawer classes={{ paper: css['paper'], root: css['drawer'] }} variant="persistent" anchor="right" open={open}>
      <Box className={css['drawer__header']} onClick={close}>
        <Typography variant="h1" className={css['drawer__header__text']}>
          Your Note
        </Typography>
        <IconButton>
          <img src={expandIcon} alt="" className={css['drawer__img']} />
        </IconButton>
      </Box>
      <Box className={css['drawer__content']}>
        <TextAreaShared
          onChange={changeText}
          value={text}
          placeholder={'Start typing your note'}
          onBlur={save}
          isLoading={loadingSaveNote === RequestState.SENDING}
        />
      </Box>
    </Drawer>
  )
}

export default NoteDrawer
