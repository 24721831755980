import { ActionType, createAction } from 'typesafe-actions'
import { Group, ItemModel } from '@/models/calendar.model'
import { TaskModel } from '@/models/responces/task.model'
import { CoordinatesModel } from '@/models/coordinates.model'

export enum ActionNames {
  SET_TIMELINE_REF = '[Calendar] Set Timeline Ref',
  SET_SCROLL_REF = '[Calendar] Set Scroll Ref',
  SET_CALENDAR_ITEMS = '[Calendar] Set Calendar Items',

  SET_CURRENTLY_DRAGGABLE = '[Calendar] Set Currently Draggable',
  SET_DRAGGABLE_COORDINATES = '[Calendar] Set Draggable Coordinates',

  SET_GROUPS = '[Calendar] Set Groups',

  REMOVE_ITEM = '[Calendar] Remove Item',
}

export const setTimelineRef = createAction(ActionNames.SET_TIMELINE_REF)<any>()
export const setScrollRef = createAction(ActionNames.SET_SCROLL_REF)<any>()
export const setCalendarItems = createAction(ActionNames.SET_CALENDAR_ITEMS)<ItemModel[]>()
export const removeItem = createAction(ActionNames.REMOVE_ITEM)<{ id: string }>()
export const setCurrentlyDraggable = createAction(ActionNames.SET_CURRENTLY_DRAGGABLE)<TaskModel | null>()
export const setDraggableCoordinates = createAction(ActionNames.SET_DRAGGABLE_COORDINATES)<CoordinatesModel | null>()
export const setGroups = createAction(ActionNames.SET_GROUPS)<Group[] | null>()

export type TCalendarActions = ActionType<
  | typeof setTimelineRef
  | typeof setScrollRef
  | typeof setCalendarItems
  | typeof removeItem
  | typeof setCurrentlyDraggable
  | typeof setDraggableCoordinates
  | typeof setGroups
>
