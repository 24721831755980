export enum COLLECTIONS {
  USERS = 'users',
  WORKSPACES = 'workspaces',
  PROCESSES = 'processes',
  EMPLOYEES = 'employees',
  PROJECTS = 'projects',
  TASKS = 'tasks',
  NOTES = 'notes',
  TIMEOFFS = 'timeoffs',
  INVITATIONS = 'invitations'
}

export const FIREBASE_WRITES_LIMIT = 200
