import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { AuthorizationSelectors } from '@/store/authorization/authorization.reducer'
import { useEffect } from 'react'
// import RouteNameConstants from "../constants/route-name.constants";
import Loading from '@/components/wrappers/loading'
import RouteNameConstants from '@/constants/route-name.constants'

type PropsType = RouteComponentProps

const unauthRequired = (Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) => {
  return (props: PropsType) => {
    const checkedAuth = useSelector<RootStateModel, boolean>(state => state.authorization.checkedAuth)
    const authorized = useSelector<RootStateModel, boolean>(state =>
      AuthorizationSelectors.authorized(state.authorization)
    )

    useEffect(() => {
      if (authorized && checkedAuth) {
        props.history.push(RouteNameConstants.WORKSPACES);
      } else {
        props.history.push(RouteNameConstants.LOGIN);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, [authorized, checkedAuth])

    return checkedAuth ? <Component {...props} /> : <Loading isLoading={true} />
  }
}

export default unauthRequired
