import { ActionType, createAction } from 'typesafe-actions'
import { UserModel } from '@/models/responces/user.model'

export enum ActionNames {
  SET_USERS = '[User] Set Users',
}

export const setUsers = createAction(ActionNames.SET_USERS)<UserModel[]>()

export type TUsersActions = ActionType<typeof setUsers>
