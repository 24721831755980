import React, { useCallback } from 'react'
import { Box, Container, IconButton, Typography } from '@material-ui/core'
import InputShared from '../../../../shared/text-input/InputShared'
import css from './css.module.scss'
import InputTimeShared from '../../../../shared/text-input/InputTimeShared'
import { StageTemplateModel } from '@/models/responces/stage-template.model'
import { getHours } from '@/utils/transform-time'
import deleteIcon from '@/assets/icons/delete-icon.svg'

export enum FormFields {
  name = 'name',
  durationHours = 'workHours',
  isActive = 'isActive',
}

interface InjectedProps {
  stage: StageTemplateModel
  changeStage: (id: string, field: FormFields, value: string | boolean) => void
  removeStage: (id: string) => void
  index: number
  stagesNumber: number
}

const StageTemplate: React.FC<InjectedProps> = ({ stage, changeStage, index, stagesNumber, removeStage }) => {
  const remove = useCallback(() => {
    removeStage(stage.id)
  }, [stage, removeStage])

  return (
    <Container className={css['form']}>
      <Box className={css['form__top']}>
        <Typography variant={'h1'} className={css['form__count']}>
          Stage {index + 1}
        </Typography>
        <div>
          {stage.isActive && (
            <IconButton onClick={remove} disabled={stagesNumber <= 1} className={css['form__delete-icon']}>
              <img src={deleteIcon} alt="" />
            </IconButton>
          )}
        </div>
      </Box>
      <Box className={css['form__bottom']}>
        <InputShared
          label={'Name'}
          value={stage.name}
          onChange={(value: string) => changeStage(stage.id, FormFields.name, value)}
        />
        <InputTimeShared
          hours={getHours(Number(stage.workHours))}
          onChange={(value: string) => changeStage(stage.id, FormFields.durationHours, value)}
        />
      </Box>
    </Container>
  )
}

export default StageTemplate
