import { Member, MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'
import { RootStateModel } from '@/store/root-reducer'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import css from './css.module.scss'
import { Box, Drawer, IconButton, Typography } from '@material-ui/core'
import expandIcon from '../../assets/icons/expand-icon.svg'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import RouteNameConstants from '@/constants/route-name.constants'
import { Invitation } from '@/models/responces/invitation.model'
import { Invitation as InvitationComponent } from '@/shared/invitation/Invitation'
import addIconGreen from '@/assets/icons/add-icon-green.svg'
import editIcon from '@/assets/icons/edit-project.svg'
import deleteIcon from '@/assets/icons/delete-icon-green.svg'
import { AvatarShared } from '../avatar/AvatarShared'
import { ManageWorkspaceDialog, WorkspaceDialogModes } from './ManageWorkspaceDialog'
import { InviteMembersDialog } from './InviteMembersDialog'
import { UserModel } from '@/models/responces/user.model'
import { WorkspaceTransactions } from '@/store/workspace/workspace.transactions'
import ConfirmationDialog from '@/shared/dialog/ConfirmationDialog'
import { useRequireRole } from '@/utils/useRequireRole'
import { Tooltip } from '@material-ui/core'

interface InjectedProps {
  open: boolean
  toggleDrawer: () => void
}

export const WorkspacesDrawer: React.FC<InjectedProps> = ({ open, toggleDrawer }) => {
  const ownedWorkspaces = useSelector<RootStateModel, WorkspaceModel[] | null>(state => state.workspace.ownedWorkspaces)
  const memberWorkspaces = useSelector<RootStateModel, WorkspaceModel[] | null>(
    state => state.workspace.memberWorkspaces
  )
  const selectedWorkspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const workspaceInvitations = useSelector<RootStateModel, Invitation[] | null>(
    state => state.invitation.workspaceInvitations
  )
  const userInvitations = useSelector<RootStateModel, Invitation[]>(state => state.invitation.userInvitations)
  const users = useSelector<RootStateModel, UserModel[] | null>(state => state.user.users)
  const selectedWorkspaceRole = useSelector<RootStateModel, MemberRoles | null>(state => state.workspace.workspaceRole)
  const history = useHistory()
  const [createWorkspaceDialogOpen, setCreateWorkspaceDialogState] = useState(false)
  const [workspaceToEdit, setWorkspaceToEdit] = useState<WorkspaceModel | null>(null)
  const [inviteMembersDialogOpen, setInviteMembersDialogState] = useState(false)
  const [deleteWorkspaceDialogOpen, setDeleteWorkspaceDialogOpenState] = useState(false)
  const [deleteMemberDialogOpen, setDeleteMemberDialogOpenState] = useState(false)
  const [workspaceToDelete, setWorkspaceToDelete] = useState<WorkspaceModel | null>(null)
  const [memberToDelete, setMemberToDelete] = useState<Member | null>(null)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)

  const toggleDeleteWorkspaceDialogState = useCallback(() => {
    setDeleteWorkspaceDialogOpenState(val => !val)
  }, [])

  const toggleDeleteMemberDialogState = useCallback(() => {
    setDeleteMemberDialogOpenState(val => !val)
  }, [])

  const onEditWorkspaceClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>, workspace: WorkspaceModel) => {
      e.stopPropagation()
      setWorkspaceToEdit(workspace)
    },
    []
  )

  const cancelWorkspaceEdit = useCallback(() => {
    setWorkspaceToEdit(null)
  }, [])

  const workspaces = useMemo(() => {
    if (ownedWorkspaces && memberWorkspaces) {
      return [...ownedWorkspaces, ...memberWorkspaces]
    }

    if (ownedWorkspaces) {
      return [...ownedWorkspaces]
    }

    if (memberWorkspaces) {
      return [...memberWorkspaces]
    }

    return []
  }, [ownedWorkspaces, memberWorkspaces])

  const onWorkspaceClick = useCallback(
    (workspace: WorkspaceModel) => {
      if (selectedWorkspace && workspace.id === selectedWorkspace.id) {
        return null
      }

      history.push(`/${RouteNameConstants.WORKSPACES}/${workspace.id}`)
      return
    },
    [history, selectedWorkspace]
  )

  const deleteMember = useCallback(async () => {
    if (!selectedWorkspace || !selectedWorkspaceRole || !memberToDelete) return

    const workspaceMembers = [...selectedWorkspace.members].filter(m => m.userId !== memberToDelete.userId)
    const workspaceToSet: WorkspaceModel = { ...selectedWorkspace, members: workspaceMembers }

    await WorkspaceTransactions.editWorkspace(workspaceToSet)
    toggleDeleteMemberDialogState()
  }, [selectedWorkspace, selectedWorkspaceRole, memberToDelete, toggleDeleteMemberDialogState])

  const onMemberDeleteIconClick = useCallback(
    (member: Member) => {
      setMemberToDelete(member)
      toggleDeleteMemberDialogState()
    },
    [toggleDeleteMemberDialogState, setMemberToDelete]
  )

  const toggleWorkspaceDialogState = useCallback(() => {
    setCreateWorkspaceDialogState(val => !val)
  }, [])

  const toggleInviteMembersDialogState = useCallback(() => {
    setInviteMembersDialogState(val => !val)
  }, [])

  const ownerInfo = useMemo(() => {
    if (!users || !selectedWorkspace) {
      return null
    }

    const owner = users.find(u => u.id === selectedWorkspace.ownerId)

    return owner
  }, [users, selectedWorkspace])

  const selectedWorkspaceLength = useMemo(() => {
    if (selectedWorkspace && selectedWorkspace.members && selectedWorkspace.members.length) {
      if (ownerInfo) {
        return selectedWorkspace.members.length + 1
      }

      return selectedWorkspace.members.length
    }

    if (ownerInfo) {
      return 1
    }

    return 0
  }, [selectedWorkspace, ownerInfo])

  const onDeleteWorkspaceIconClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>, workspace: WorkspaceModel) => {
      e.stopPropagation()
      setWorkspaceToDelete(workspace)
      toggleDeleteWorkspaceDialogState()
    },
    [setWorkspaceToDelete, toggleDeleteWorkspaceDialogState]
  )

  const deleteWorkspace = useCallback(async () => {
    if (!workspaceToDelete) return

    await WorkspaceTransactions.deleteWorkspace(workspaceToDelete)
    toggleDeleteWorkspaceDialogState()
  }, [workspaceToDelete, toggleDeleteWorkspaceDialogState])

  return (
    <Drawer classes={{ paper: css['paper'], root: css['drawer'] }} variant="persistent" anchor="right" open={open}>
      <Box className={css['drawer__header']} onClick={toggleDrawer}>
        <Typography variant="h1" className={css['drawer__header__text']}>
          Workspaces
        </Typography>
        <IconButton>
          <img src={expandIcon} alt="" className={css['drawer__img']} />
        </IconButton>
      </Box>
      {userInvitations.length > 0 && (
        <Box className={css['invitations']}>
          <Typography variant="h1" className={css['section-header']}>
            You have invites
            <span className={css['invitations-counter']}>{userInvitations.length}</span>
          </Typography>
          {userInvitations.map(i => {
            return <InvitationComponent key={i.createdAt.toDate().toString()} invitation={i} />
          })}
        </Box>
      )}
      <Box className={css['workspaces']}>
        <div style={{ marginBottom: '16px' }} className={css['section-header']}>
          <Typography variant="h3" className={css['section-header']}>
            Your workspaces {workspaces && `(${workspaces.length})`}
          </Typography>
          <img
            onClick={toggleWorkspaceDialogState}
            className={css['add-icon-green']}
            src={addIconGreen}
            alt={'add-icon-green'}
          />
        </div>
        {workspaces?.map(w => {
          const isOwner = user && w.ownerId === user.id
          return (
            <div
              className={classNames(css['workspace'], {
                [css['active']]: selectedWorkspace && selectedWorkspace.id === w.id,
              })}
              key={w.id}
              onClick={() => onWorkspaceClick(w)}
            >
              <span className={css['title']}>{w.name || 'Default Name'}</span>
              {isOwner && (
                <>
                  <img
                    onClick={e => onEditWorkspaceClick(e, w)}
                    src={editIcon}
                    alt={'Edit icon'}
                    className={css['edit-icon']}
                  />
                  <img onClick={e => onDeleteWorkspaceIconClick(e, w)} alt={'Delete icon'} src={deleteIcon} />
                </>
              )}
            </div>
          )
        })}
      </Box>
      <Box className={css['workspace-members']}>
        <div style={{ marginBottom: '16px' }} className={css['section-header']}>
          <Typography variant="h3" className={css['section-header']}>
            Members of this workspace ({selectedWorkspaceLength && selectedWorkspaceLength})
          </Typography>
          {isRoleAllowed && (
            <img
              onClick={toggleInviteMembersDialogState}
              className={css['add-icon-green']}
              src={addIconGreen}
              alt={'add-icon-green'}
            />
          )}
        </div>

        {ownerInfo && (
          <Tooltip title={<span className={css['tooltip']}>{ownerInfo.email || ''}</span>}>
            <div className={css['member']}>
              <AvatarShared>
                {ownerInfo.name[0]}{ownerInfo.surname ? ownerInfo.surname[0] : ''}
              </AvatarShared>
              <span className={css['name']}>{`${ownerInfo.name} ${ownerInfo.surname}`}</span>
              <div className={css['owner-badge']}>Owner</div>
            </div>
          </Tooltip>
        )}

        {selectedWorkspace &&
          selectedWorkspace.members &&
          selectedWorkspace.members.map(m => {
            let userName = ''
            const correspondingUser = users && users.find((u: UserModel) => u.id === m.userId)

            if (correspondingUser) {
              userName = `${correspondingUser.name} ${correspondingUser.surname}`
            }

            return (
              <Tooltip
                title={
                  <span className={css['tooltip']}>
                    {correspondingUser && correspondingUser.email ? correspondingUser.email : ''}
                  </span>
                }
              >
                <div className={css['member']} key={m.userId}>
                  <AvatarShared>
                    {correspondingUser ? correspondingUser.name[0] : 'D'}
                    {correspondingUser ? correspondingUser.surname[0] : ''}
                  </AvatarShared>
                  <span className={css['name']}>{userName}</span>
                  {isRoleAllowed && (
                    <img
                      onClick={() => onMemberDeleteIconClick(m)}
                      className={css['delete-icon']}
                      src={deleteIcon}
                      alt={'Delete icon'}
                    />
                  )}
                </div>
              </Tooltip>
            )
          })}
      </Box>

      {workspaceInvitations && workspaceInvitations.length > 0 && (
        <Box className={css['workspace-members']}>
          <div style={{ marginBottom: '16px' }} className={css['section-header']}>
            <Typography variant="h3" className={css['section-header']}>
              Pending members ({workspaceInvitations && workspaceInvitations.length})
            </Typography>
            {isRoleAllowed && (
              <img
                onClick={toggleInviteMembersDialogState}
                className={css['add-icon-green']}
                src={addIconGreen}
                alt={'add-icon-green'}
              />
            )}
          </div>

          {workspaceInvitations.map(m => {
            return <InvitationComponent key={m.createdAt.toDate().toString()} viewOnly invitation={m} />
          })}
        </Box>
      )}

      <ManageWorkspaceDialog
        open={createWorkspaceDialogOpen || !!workspaceToEdit}
        onClose={workspaceToEdit ? cancelWorkspaceEdit : toggleWorkspaceDialogState}
        mode={workspaceToEdit ? WorkspaceDialogModes.EDIT : WorkspaceDialogModes.CREATE}
        workspaceToEdit={workspaceToEdit || undefined}
      />
      <InviteMembersDialog open={inviteMembersDialogOpen} onClose={toggleInviteMembersDialogState} />
      <ConfirmationDialog
        open={deleteWorkspaceDialogOpen}
        onClose={toggleDeleteWorkspaceDialogState}
        title={'Are you sure you want to delete the workspace?'}
        confirmAction={deleteWorkspace}
      />
      <ConfirmationDialog
        open={deleteMemberDialogOpen}
        onClose={toggleDeleteMemberDialogState}
        title={'Are you sure you want to delete the member?'}
        confirmAction={deleteMember}
      />
    </Drawer>
  )
}
