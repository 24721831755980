import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '../constants'
import { Member, MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'

export class WorkspaceSubscription {
  static ownedWorkspaces(userId: string, callback: DefaultSubscribeCallback<WorkspaceModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.WORKSPACES)
      .where('ownerId', '==', userId)
      .onSnapshot(data => {
        callback(data.docs.map(d => ({ ...d.data() } as WorkspaceModel)))
      })
  }

  static memberWorkspaces(userId: string, userEmail: string, callback: DefaultSubscribeCallback<WorkspaceModel[]>) {
    const member: Member = {
      userId,
      role: MemberRoles.VIEWER,
      userEmail,
    }

    return FirebaseUsage.database()
      .collection(COLLECTIONS.WORKSPACES)
      .where('members', 'array-contains', member)
      .onSnapshot(data => {
        callback(data.docs.map(d => ({ ...d.data() } as WorkspaceModel)))
      })
  }
}
