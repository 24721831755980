import React from 'react'
import { Tabs, Tab, TabProps, TabsProps, makeStyles } from '@material-ui/core'

interface InjectedProps {
  tabs: TabProps[]
  onChange: (event: React.ChangeEvent<{}>, newValue: any) => void
}

const useStyles = makeStyles({
  selected: {
    color: '#18A57E !important',
  },
})

const TabShared: React.FC<InjectedProps & Omit<TabsProps, 'onChange'>> = props => {
  const tabStyles = useStyles()

  return (
    <Tabs {...props}>
      {props.tabs.map((t, i) => {
        return <Tab classes={{ selected: tabStyles.selected }} {...t} key={i} />
      })}
    </Tabs>
  )
}

export default TabShared
