import FirebaseUsage from './firebase.usage'
import * as firebase from 'firebase/app'

export type SmartRef = string | firebase.firestore.DocumentReference
export const getDocumentRef = (smartRef: SmartRef) =>
  typeof smartRef === 'string' ? FirebaseUsage.database().doc(smartRef) : smartRef

class BaseSmart<T extends firebase.firestore.WriteBatch | firebase.firestore.Transaction> {
  private readonly intentionalWriteLimitPerSecond = 100

  constructor(public operator: T) {}

  smartRef(smartRef: SmartRef) {
    return getDocumentRef(smartRef)
  }

  set<T>(smartRef: SmartRef, data?: T, options?: firebase.firestore.SetOptions) {
    if (data) {
      //@ts-ignore
      this.operator.set(getDocumentRef(smartRef), data, options)
    }
    return this
  }

  update<T>(smartRef: SmartRef, data: T) {
    this.operator.update(getDocumentRef(smartRef), data)
    return this
  }

  del(smartRef: SmartRef) {
    this.operator.delete(getDocumentRef(smartRef))
    return this
  }
}

class SmartBatch extends BaseSmart<firebase.firestore.WriteBatch> {
  constructor(public batch: firebase.firestore.WriteBatch) {
    super(batch)
  }
}

export class SmartTransaction extends BaseSmart<firebase.firestore.Transaction> {
  constructor(public transaction: firebase.firestore.Transaction) {
    super(transaction)
  }

  async get<T>(smartRef: string | firebase.firestore.DocumentReference) {
    return (await this.transaction.get(getDocumentRef(smartRef))).data() as T
  }
}

export async function runTransaction<T = void>(f: (transaction: SmartTransaction) => Promise<T>) {
  await FirebaseUsage.database().runTransaction(tx => f(new SmartTransaction(tx)))
}

export const runBatch = async (f: (batch: SmartBatch) => void) => {
  const batch = FirebaseUsage.database().batch()
  f(new SmartBatch(batch))
  await batch.commit()
}
