import SnackbarComponent from './snackbar'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { removeSnackbar, Snackbar } from '@/store/snackbar/snackbar.actions'

const SnackbarsContainer = () => {
  const dispatch = useDispatch()
  const snackbars = useSelector<RootStateModel, Snackbar[] | null>(state => state.snackbar.snackbars)

  const onClose = useCallback(
    (id: number) => {
      dispatch(removeSnackbar({ id }))
    },
    [dispatch]
  )

  return (
    <>
      {snackbars &&
        snackbars.map((snackbar, index) => (
          <SnackbarComponent
            key={snackbar.id!}
            variant={snackbar.type}
            message={`${snackbar.title}${snackbar.text}`}
            type={snackbar.type}
            open={snackbars.length >= index}
            onClose={() => {
              onClose(snackbar.id!)
            }}
          />
        ))}
    </>
  )
}

export default SnackbarsContainer
