import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#18A57E',
    },
    secondary: {
      main: '#f5faee',
    },
  },
  typography: {
    fontFamily: 'ProximaNova Regular',
    h1: {
      fontFamily: 'Mont Heavy',
      fontSize: '18px',
      letterSpacing: 0,
      lineHeight: '27px',
      color: '#2A405A',
    },
    h2: {
      fontFamily: 'ProximaNova Bold',
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body1: {
      fontFamily: 'ProximaNova Light',
      fontSize: '11px',
      letterSpacing: 0,
      lineHeight: '19px',
    },
    body2: {
      fontFamily: 'ProximaNova Regular',
      fontSize: '14px',
      letterSpacing: 0,
      lineHeight: '21px',
    },
    button: {
      fontFamily: 'ProximaNova Semibold',
      fontSize: '12px',
      letterSpacing: 0,
      lineHeight: '21px',
      textTransform: 'initial',
    },
  },

  overrides: {
    MuiFormControl: {
      root: {
        display: 'block',
        margin: '0 !important',
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        '&$disabled': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: `#a1abb6 !important`,
      },
    },
    MuiButton: {
      root: {
        minWidth: '85px',
        height: '48px',
        padding: '6px 18px !important',
        borderRadius: '5px',
        fontSize: '15px',
        boxShadow: 'none !important',
        '&:hover': {
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
        },
        '&$disabled': {
          opacity: 0.5,
        },
      },
      sizeSmall: {
        height: '30px',
        width: '80px',
        padding: '0 !important',
        fontSize: '11px',
      },
    },
    MuiCheckbox: {
      root: {
        '& span': {
          '& svg': {
            width: '24px',
            height: '24px',
          },
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(1px, -12px) scale(0.75) !important',
      },
    },
    MuiDialogContent: {
      root: {
        padding: `0 !important`,
      },
    },

    MuiSwitch: {
      root: {
        padding: '11px 6px !important',
      },
      switchBase: {
        padding: '10px 2px',
        background: 'none !important',
      },
      thumb: {
        width: '22px',
        height: '22px',
        boxShadow: 'none',
      },
      track: {
        width: '38px',
        height: '20px',
        borderRadius: '10px',
        opacity: '0.12 !important',
      },
    },
    //@ts-ignore
    MuiToggleButton: {
      root: {
        '&$selected': {
          border: '1px solid #8ACD3E !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '0 !important',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 !important',
      },
      expandIcon: {
        margin: '-20px 0 0 10px !important',
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: 'transparent !important',
        height: '16px !important',
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#2A425A !important',
        borderRadius: '10px !important',
      },
      list: {
        padding: '10px 0 !important',
      },
    },
    MuiMenuItem: {
      root: {
        height: '22px !important',
        '&$selected': {
          borderRight: '1px solid #fff !important',
          backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0 !important',
        border: '1px solid #fafafb',
        borderRadius: '8px !important',
      },
      endAdornment: {
        marginTop: '4px',
        '& button': {
          marginLeft: '8px',
        },
      },
      // TODO Add selected style
      paper: {
        // boxShadow: 'none !important',
        borderRadius: '0 !important',
      },
      listbox: {
        padding: '0 !important',
      },
      option: {
        height: '40px',
        color: '#0C2E4A',
        fontFamily: 'ProximaNova Regular, serif',
        '&$selected': {
          borderRight: '1px solid #fff !important',
          backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: '0 !important',
      },
    },
    MuiListItem: {
      container: {
        listStyle: 'none',
      },
    },
    MuiTooltip: {
      popper: {
        marginTop: '10px',
        width: '150px',
        display: 'flex',
      },
      tooltip: {
        padding: 0,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
})

export default theme
