import { Invitation } from '@/models/responces/invitation.model'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'
import { COLLECTIONS } from '../constants'
import FirebaseUsage from '../firebase.usage'

export class InvitationSubscription {
  static userInvitations(userEmail: string, callback: DefaultSubscribeCallback<Invitation[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.INVITATIONS)
      .where('userEmail', '==', userEmail)
      .onSnapshot(data => {
        callback(data.docs.map(d => ({ ...d.data(), id: d.id } as Invitation)))
      })
  }

  static workspaceInvitations(workspaceId: string, callback: DefaultSubscribeCallback<Invitation[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.INVITATIONS)
      .where('workspaceId', '==', workspaceId)
      .onSnapshot(data => {
        callback(data.docs.map(d => ({ ...d.data(), id: d.id } as Invitation)))
      })
  }
}
