import { MemberRoles } from '@/models/responces/workspace.model'
import React from 'react'
import { useRequireRole } from './useRequireRole'

interface InjectedProps {
  role: MemberRoles
}

export const RequireRoleHOC: React.FC<InjectedProps> = ({ role, children }) => {
  const { isRoleAllowed } = useRequireRole(role)

  if (!isRoleAllowed || !children) {
    return null
  }

  return (
      <>
        {children}
      </>
  )
}
