import React from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'

const ColorBoxShared: React.FC<{ color: string }> = ({ color }) => {
  return (
    <Box className={css['picker-wrapper']}>
      <Box className={css['picker-wrapper__color-box']} style={{ backgroundColor: color }} />
    </Box>
  )
}

export default ColorBoxShared
