import { UserModel } from '@/models/responces/user.model'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { RootStateModel } from '@/store/root-reducer'
import { WorkspaceTransactions } from '@/store/workspace/workspace.transactions'
import { Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ButtonShared from '../button/ButtonShared'
import DefaultDialog from '../dialog/DefaultDialog'
import InputShared from '../text-input/InputShared'
import css from './css.module.scss'
import researchProject from '@/assets/images/research-project.svg'
import { useHistory } from 'react-router-dom'
import RouteNameConstants from '@/constants/route-name.constants'

export enum WorkspaceDialogModes {
  EDIT = 'edit',
  CREATE = 'create',
}

export const ManageWorkspaceDialog: React.FC<{
  open: boolean
  onClose: () => void
  mode: WorkspaceDialogModes
  workspaceToEdit?: WorkspaceModel
}> = ({ open, onClose, mode, workspaceToEdit }) => {
  const [workspaceName, setWorkspaceName] = useState('')
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const history = useHistory()

  useEffect(() => {
    if (workspaceToEdit && !workspaceName) {
      setWorkspaceName(workspaceToEdit.name || 'Default Name')
    }
  }, [workspaceToEdit, workspaceName])

  const onInputChange = useCallback((val: string) => {
    setWorkspaceName(val)
  }, [])

  const clearDataOnClose = useCallback(() => {
    setWorkspaceName('')
    onClose()
  }, [onClose])

  const addWorkspace = useCallback(async () => {
    if (!user || !workspaceName) return

    const newWorkspace: WorkspaceModel = {
      ownerId: user.id,
      members: [],
      name: workspaceName,
      id: '',
    }

    const id = await WorkspaceTransactions.createWorkspace(newWorkspace)

    setTimeout(() => {
      history.push(`/${RouteNameConstants.WORKSPACES}/${id}`)
    }, 300)
    clearDataOnClose()
  }, [user, workspaceName, clearDataOnClose, history])

  const editWorkspace = useCallback(async () => {
    if (workspaceToEdit && workspaceName) {
      const editedWorkspace: WorkspaceModel = {
        ...workspaceToEdit,
        name: workspaceName,
      }

      await WorkspaceTransactions.editWorkspace(editedWorkspace)

      clearDataOnClose()
    }
  }, [workspaceName, workspaceToEdit, clearDataOnClose])

  return (
    <DefaultDialog open={open} onClose={clearDataOnClose}>
      <div className={css['manage-workspace-dialog']}>
        <div className={css['row']} style={{ marginBottom: '40px' }}>
          <img alt={'Project Research'} src={researchProject} />
          <div style={{ flexDirection: 'column', marginLeft: '17px' }} className={css['row']}>
            <Typography variant={'h1'}>
              {mode === WorkspaceDialogModes.CREATE ? 'Name your workspace' : 'Edit workspace name'}
            </Typography>
            <span className={css['sub-text']}>Add the name</span>
          </div>
        </div>
        <InputShared value={workspaceName || ''} onChange={onInputChange} label={'Workspace name'} />
      </div>
      <div className={css['footer']}>
        <ButtonShared color={'secondary'} className={css['cancel-button']} onClick={clearDataOnClose} text={'Cancel'} />
        <ButtonShared
          disabled={!workspaceName}
          color={'primary'}
          onClick={mode === WorkspaceDialogModes.CREATE ? addWorkspace : editWorkspace}
          text={mode === WorkspaceDialogModes.CREATE ? 'Create workspace' : 'Save changes'}
        />
      </div>
    </DefaultDialog>
  )
}
