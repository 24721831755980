import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cancelProcessImg from '@/assets/images/cancel-process.svg'
import CancelDialog from '@/shared/dialog/CancelDialog'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import { Box, IconButton, Typography } from '@material-ui/core'
import css from '@/pages/employee/add-employee/css.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { TaskModel, TaskModelMap } from '@/models/responces/task.model'
import EmployeeThunk from '@/store/employee/employee.thunk'
import { resetLoading, setEditableEmployee } from '@/store/employee/employee.actions'
import { DefaultEmployee, EmployeeModel } from '@/models/responces/employee.model'
import InputShared from '@/shared/text-input/InputShared'
import InfoBlock, { InjectedPropsInfo } from '@/shared/info-block/InfoBlock'
import { EEntityAction } from '@/constants/entity-action'
import { DetailsInfo } from '@/constants/content/employee'
import { RequestState } from '@/constants/request-state'
import ButtonShared from '@/shared/button/ButtonShared'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import Loading from '@/components/wrappers/loading'
import deleteIcon from '../../../assets/icons/delete-icon.svg'
import RemoveDialog from '@/shared/dialog/RemoveDialog'
import { isEmpty } from '@/utils/helpers/string-validator'
import { UserModel } from '@/models/responces/user.model'

interface InjectedProps {
  onClose: () => void
  open: boolean
}

export enum FormFields {
  name = 'name',
  email = 'email',
  position = 'position',
}

const CreateEmployeeDialog: React.FC<InjectedProps> = ({ open, onClose }) => {
  const [cancelDialog, setCancelDialog] = useState<boolean>(false)
  const [employee, setEmployee] = useState<EmployeeModel>(DefaultEmployee)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [editAction, setEditAction] = useState(false)

  const workspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const loadingAddEmployee = useSelector<RootStateModel, RequestState>(state => state.employee.loadingAddEmployee)
  const loadingEditEmployee = useSelector<RootStateModel, RequestState>(state => state.employee.loadingEditEmployee)
  const loadingDeleteEmployee = useSelector<RootStateModel, RequestState>(state => state.employee.loadingDeleteEmployee)
  const tasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.tasksMap)
  const editableEmployee = useSelector<RootStateModel, EmployeeModel | null>(state => state.employee.editableEmployee)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const employeesList = useSelector<RootStateModel, EmployeeModel[] | null>(state => state.employee.employeesList)
  const dispatch = useDispatch()

  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null)

  const tasksList: TaskModel[] = useMemo(() => {
    if (tasksMap) {
      return Object.keys(tasksMap).reduce((res: TaskModel[], v: string) => {
        return res.concat(tasksMap[v])
      }, [])
    }
    return []
  }, [tasksMap])

  const resetForm = useCallback(() => {
    setEmployee(DefaultEmployee)
  }, [])

  const resetEmployee = useCallback(() => {
    dispatch(setEditableEmployee(null))
    resetForm()
  }, [dispatch, resetForm])

  const toggleCancelDialog = useCallback(() => {
    if (!editAction) {
      onClose()
      resetEmployee()
      return
    }
    setCancelDialog(!cancelDialog)
  }, [cancelDialog, editAction, onClose, resetEmployee])

  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialog(!deleteDialog)
  }, [deleteDialog])

  useEffect(() => {
    if (editableEmployee) {
      setEmployee(editableEmployee)
    } else {
      resetForm()
    }
  }, [editableEmployee, resetForm])

  const changeEmployee = useCallback((field: FormFields, value: string) => {
    setEmployee(employee => ({ ...employee, [field]: value }))
    setEditAction(true)
  }, [])

  const addEmployee = useCallback(() => {
    if (workspace && employee && user && employeesList) {
      dispatch(
        EmployeeThunk.addEmployee({
          ...employee,
          workspaceId: workspace.id,
          authorUid: user.id,
          index: employeesList.length ? employeesList[0].index + 1 : 0,
        })
      )
      resetForm()
    }
  }, [dispatch, employee, workspace, resetForm, user, employeesList])

  const confirmCancel = useCallback(() => {
    toggleCancelDialog()
    onClose()
    resetEmployee()
    dispatch(resetLoading())
  }, [onClose, toggleCancelDialog, resetEmployee, dispatch])

  const editEmployee = useCallback(() => {
    dispatch(EmployeeThunk.editEmployee({ ...employee }))
  }, [employee, dispatch])

  const deleteEmployee = useCallback(() => {
    if (editableEmployee) {
      dispatch(EmployeeThunk.deleteEmployee(editableEmployee.id))
    }
  }, [editableEmployee, dispatch])

  const employeeHasTasks = useMemo(() => {
    if (editableEmployee && tasksList) {
      return !!tasksList.filter(task => task.employeeId === editableEmployee.id).length
    }
    return false
  }, [tasksList, editableEmployee])

  const getInfoText = useCallback((): InjectedPropsInfo => {
    const id = editableEmployee ? EEntityAction.EDIT : EEntityAction.ADD
    const info = DetailsInfo.find(info => info.id === id)
    return { text: info ? info.text : '', heading: info ? info.heading : '', icon: info ? info.icon : '' }
  }, [editableEmployee])

  const isLoading = useMemo(() => {
    return loadingAddEmployee === RequestState.SENDING || loadingEditEmployee === RequestState.SENDING
  }, [loadingAddEmployee, loadingEditEmployee])

  const saveChanges = useCallback(() => {
    if (editableEmployee) {
      editEmployee()
    } else {
      addEmployee()
    }
  }, [editableEmployee, editEmployee, addEmployee])

  useEffect(() => {
    if (
      loadingAddEmployee === RequestState.SENT_SUCCESS ||
      loadingAddEmployee === RequestState.SENT_FAILED ||
      loadingEditEmployee === RequestState.SENT_SUCCESS ||
      loadingEditEmployee === RequestState.SENT_FAILED ||
      loadingDeleteEmployee === RequestState.SENT_SUCCESS ||
      loadingDeleteEmployee === RequestState.SENT_FAILED
    ) {
      resetForm()
      timerRef.current = setTimeout(() => {
        dispatch(resetLoading())
        onClose()
      }, 3000)
    }
  }, [loadingAddEmployee, loadingEditEmployee, dispatch, resetForm, onClose, loadingDeleteEmployee, resetEmployee])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (loadingDeleteEmployee === RequestState.SENT_SUCCESS || loadingDeleteEmployee === RequestState.SENT_FAILED) {
      setDeleteDialog(false)
    }
  }, [loadingDeleteEmployee])

  const isFormValid = useMemo(() => {
    let valid = true
    if (isEmpty(employee.name)) {
      valid = false
    }
    return valid
  }, [employee])

  return (
    <DefaultDialog onClose={toggleCancelDialog} open={open}>
      <Loading isLoading={isLoading}>
        <Box className={css['wrapper']}>
          <Box className={css['wrapper__info']}>
            <InfoBlock {...getInfoText()} />
          </Box>

          <Box className={css['wrapper__form']}>
            <Box className={css['wrapper__form__input-field-name']}>
              <InputShared
                value={employee.name}
                onChange={(value: string) => changeEmployee(FormFields.name, value)}
                label={'Name'}
              />
              {editableEmployee && (
                <IconButton
                  disabled={employeeHasTasks}
                  onClick={toggleDeleteDialog}
                  className={css['wrapper__form__delete-icon']}
                >
                  <img src={deleteIcon} alt="" />
                </IconButton>
              )}
            </Box>
            <Box className={css['wrapper__form__input-field-info']}>
              <InputShared
                value={employee.email}
                onChange={(value: string) => changeEmployee(FormFields.email, value)}
                label={'Email'}
              />
              <InputShared
                value={employee.position}
                onChange={(value: string) => changeEmployee(FormFields.position, value)}
                label={'Position'}
              />
            </Box>
            {employeeHasTasks && (
              <Typography variant={'body1'} className={css['wrapper__form__info']}>
                You can not delete this employee. Make sure there are no assigned tasks.
              </Typography>
            )}
          </Box>
          <Box className={css['wrapper__manage-stages']}>
            <Box className={css['wrapper__btn-group']}>
              <CancelLinkShared onClick={toggleCancelDialog} />
              <ButtonShared onClick={saveChanges} disabled={!isFormValid} text={'Save Changes'} color={'primary'} />
            </Box>
          </Box>
        </Box>
      </Loading>

      <CancelDialog
        open={cancelDialog}
        onClose={toggleCancelDialog}
        image={cancelProcessImg}
        confirmAction={confirmCancel}
      />

      <RemoveDialog
        heading={'Are you sure you want to Delete the Employee?'}
        text={'All Completed data will be deleted'}
        open={deleteDialog}
        onClose={toggleDeleteDialog}
        confirmAction={deleteEmployee}
        isLoading={loadingDeleteEmployee === RequestState.SENDING}
      />
    </DefaultDialog>
  )
}

export default CreateEmployeeDialog
