import React, { useCallback, useMemo } from 'react'
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import css from './css.module.scss'
import { useDispatch } from 'react-redux'
import { setEditableProject } from '@/store/project/project.actions'
import deleteIcon from '@/assets/icons/delete-icon.svg'
import { ProjectModel } from '@/models/responces/project.model'
import { Restore } from '@material-ui/icons'

interface InjectedProps {
  project: ProjectModel
  isEditable: boolean
  deleteClick: (id: string) => void
  unarchiveClick?: (id: string) => void
}

const ProjectListItem: React.FC<InjectedProps> = ({ project, isEditable, deleteClick, unarchiveClick }) => {
  const dispatch = useDispatch()

  const edit = useCallback(() => {
    dispatch(setEditableProject(project))
  }, [project, dispatch])

  const getClassName = useMemo(() => {
    return isEditable ? 'editable' : 'default'
  }, [isEditable])

  const onDeleteClick = useCallback(() => {
    deleteClick(project.id)
  }, [project, deleteClick])

  const onUnarchiveClick = useCallback(() => {
    if (unarchiveClick) {
      unarchiveClick(project.id)
    }
  }, [project, unarchiveClick])

  return (
    <>
      <ListItem className={css[getClassName]} button onClick={edit}>
        <ListItemText primary={<Typography variant={'button'}>{project.name}</Typography>} />
        <ListItemSecondaryAction>
          {unarchiveClick && (
            <IconButton onClick={onUnarchiveClick} className={css['restore-icon']}>
              <Restore />
            </IconButton>
          )}
          <IconButton onClick={onDeleteClick} className={css['delete-icon']}>
            <img src={deleteIcon} alt="" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}

export default ProjectListItem
