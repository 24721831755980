import { runTransaction } from '@/firebase/helpers'
import { COLLECTIONS } from '@/firebase/constants'
import { UserModel } from '@/models/responces/user.model'
import FirebaseUsage from '@/firebase/firebase.usage'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'
import firebase from 'firebase/app'

export const DEFAULT_USERNAME = 'Name'
export const DEFAULT_USERSURNAME = 'Surname'

export default abstract class AuthorizationTransactions {
  @HandleAsyncErrors()
  public static async AddUser(user: Pick<UserModel, 'email' | 'id'>) {
    await runTransaction(async transaction => {
      const currentUser = await transaction.get<UserModel>(`${COLLECTIONS.USERS}/${user.id}`)

      if (!currentUser) {
        await transaction.set<UserModel>(FirebaseUsage.database().collection(COLLECTIONS.USERS).doc(user.id), {
          ...user,
          name: DEFAULT_USERNAME,
          surname: DEFAULT_USERSURNAME,
          dateOfCreation: firebase.firestore.Timestamp.fromDate(new Date()),
        })
      }
    })
  }
}
