import * as uuid from 'uuid'
import { AuthorModel } from '@/models/author.model'

export interface EmployeeModel extends AuthorModel {
  id: string
  index: number,
  name: string
  email: string
  position: string
  workspaceId: string
}

export const DefaultEmployee: EmployeeModel = {
  id: uuid.v4(),
  index: 0,
  name: '',
  email: '',
  position: '',
  workspaceId: '',
  authorUid: '',
}
