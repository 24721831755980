import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '../constants'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'
import { EmployeeModel } from '@/models/responces/employee.model'

export class EmployeeSubscription {
  static employeesList(workspaceId: string, callback: DefaultSubscribeCallback<EmployeeModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.EMPLOYEES)
      .where('workspaceId', '==', workspaceId)
      .onSnapshot(data => {
        callback(
          data.docs.map(
            doc =>
              ({
                ...doc.data(),
              } as EmployeeModel)
          )
        )
      })
  }
}
