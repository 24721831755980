import { ProcessModel } from '@/models/responces/process.model'
import * as uuid from 'uuid'
import { getRandomColor } from '@/utils/random-color'
import { AuthorModel } from '@/models/author.model'

export const NO_EMPLOYEE = 'NO_EMPLOYEE'

export interface TaskModel extends AuthorModel {
  id: string
  projectId: string
  name: string
  color: string
  process: ProcessModel
  employeeId: string | null
  centerDate?: number
  originalTaskId?: string
}

export interface TaskModelMap {
  [projectId: string]: TaskModel[]
}

export interface SetTaskModel {
  projectId: string
  taskList: TaskModel[]
}

export interface AddTaskModel extends AuthorModel {
  id: string
  name: string
  color: string
  employeeId: string | null
  process: ProcessModel | null
}

export const DefaultTask: AddTaskModel = {
  id: uuid.v4(),
  name: '',
  color: getRandomColor(),
  process: null,
  authorUid: '',
  employeeId: null
}
