import moment, { Moment } from 'moment'

export const isSaturdayMidnight = (date: number, day: number) => {
  return day === 6 && new Date(date).getDay() === 6 && new Date(date).getHours() === 0
}

export const isMondayMidnight = (date: number, day: number) => {
  return day === 1 && new Date(date).getDay() === 1 && new Date(date).getHours() === 0
}

export const isWeekendDay = (dayOfWeek: number) => dayOfWeek === 6 || dayOfWeek === 0

export function countWeekends(start: number, end: number) {
  var weekendDayCount = isSaturdayMidnight(end, moment(end).day()) ? -1 : 0
  let fromDate = new Date(start)
  let toDate = new Date(end)

  while (fromDate < toDate) {
    if (fromDate.getDay() === 0 || fromDate.getDay() === 6) {
      ++weekendDayCount
    }
    fromDate.setDate(fromDate.getDate() + 1)
  }

  return weekendDayCount
}

export const isWeekendIncluded = (startDate: number, endDate: number): boolean => {
  const weekendCount = countWeekends(startDate, endDate)
  return weekendCount >= 2
}

export const isWeekendOverlap = (startDate: number, endDate: number): boolean => {
  const d1 = moment(startDate).endOf('day')
  const d2 = moment(endDate).endOf('day')
  while (d1 <= d2) {
    const day = d1.day()
    if (isWeekendDay(day)) {
      return !isSaturdayMidnight(endDate, day)
    }
    d1.add(1, 'day')
  }
  return false
}

export const getMidPoint = (startDate: Moment, endDate: Moment) => {
  return (startDate.valueOf() + endDate.valueOf()) / 2
}

const MILLISECONDS_IN_DAY = 60 * 60 * 24 * 1000

export const getDaysBetweenDates = (startDate: Moment, endDate: Moment) => {
  return (endDate.valueOf() - startDate.valueOf()) / MILLISECONDS_IN_DAY
}

export const getSeasonStartEndDate = (month: number) => {
  if (month < 3) {
    return [moment().subtract(1, 'year').set('month', 11), moment().set('month', 2)]
  }
  if (month < 6) {
    return [moment().set('month', 2), moment().set('month', 5)]
  }
  if (month < 9) {
    return [moment().set('month', 5), moment().set('month', 8)]
  }

  return [moment().set('month', 8), moment().set('month', 11)]
}
