import React, { useCallback, useMemo, useState } from 'react'
import css from './css.module.scss'
import crossIcon from '@/assets/icons/cross-icon.svg'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import classNames from 'classnames'

interface ChipProps {
  text: string
  onRemove: (text: string) => void
  isValid: boolean
}

export const Chip: React.FC<ChipProps> = ({ text, isValid, onRemove }) => {
  return (
    <div
      className={classNames(css['chip'], {
        [css['chip--invalid']]: !isValid,
      })}
    >
      <span className={css['text']}>{text}</span>
      <img onClick={() => onRemove(text)} alt={'Cross Icon'} src={crossIcon} />
    </div>
  )
}

interface InjectedProps {
  onEmailsChange: (emails: Email[]) => void
}

export interface Email {
  email: string
  isValid: boolean
}

export const EmailTextArea: React.FC<InjectedProps> = ({ onEmailsChange }) => {
  const [newEmail, setNewEmail] = useState('')
  // might be better to receive emails from parent?
  const [emails, setEmails] = useState<Email[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const selectedWorkspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)

  const isEmailValid = useMemo(() => {
    let isValid = true

    const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const isAlreadyMember = selectedWorkspace && selectedWorkspace.members.find(m => m.userEmail === newEmail)

    if (!newEmail.match(emailRegExp) || isAlreadyMember) {
      isValid = false
    }

    return isValid
  }, [newEmail, selectedWorkspace])

  const addEmail = useCallback(
    (isValid: boolean) => {
      setEmails(e => {
        const email: Email = {
          email: newEmail,
          isValid,
        }
        const emailsToSet = [...e, email]
        onEmailsChange(emailsToSet)
        return emailsToSet
      })
      setNewEmail('')
    },
    [newEmail, onEmailsChange, setNewEmail]
  )

  const removeEmail = useCallback(
    (email: string) => {
      setEmails(e => {
        const emailsToSet = e.filter(ee => ee.email !== email)
        onEmailsChange(emailsToSet)
        return emailsToSet
      })
    },
    [onEmailsChange]
  )

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const ENTER_KEY_CODE = 13
      const SPACE_KEY_CODE = 32

      if (!newEmail.length) return

      if ((e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) && isEmailValid) {
        addEmail(true)
        return
      }

      if ((e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) && !isEmailValid) {
        addEmail(false)
      }
      return
    },
    [isEmailValid, addEmail, newEmail]
  )

  const onBlur = useCallback(() => {
    if (!newEmail) return

    if (isEmailValid) {
      addEmail(true)
    } else {
      addEmail(false)
    }
  }, [isEmailValid, addEmail, newEmail])

  const onEmailRemove = useCallback(
    (email: string) => {
      removeEmail(email)
    },
    [removeEmail]
  )

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const spacesPattern = /[\s\/\\]+/
    const trimValue = e.currentTarget.value.trim().replace(spacesPattern, '')
    setNewEmail(trimValue)
    setErrors([])
  }, [])

  return (
    <div className={css['email-input-container']}>
      <div className={css['emails-container']}>
        {emails.map(e => {
          return <Chip key={e.email} isValid={e.isValid} text={e.email} onRemove={onEmailRemove} />
        })}
      </div>

      <input
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        value={newEmail}
        onChange={onInputChange}
        className={css['email-input']}
      />
      {errors.map(e => {
        return (
          <span key={e} className={css['error']}>
            {e}
          </span>
        )
      })}
    </div>
  )
}
