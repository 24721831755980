import { combineReducers } from 'redux'
import * as fromUser from './authorization/authorization.reducer'
import * as fromProcess from './process/process.reducer'
import * as fromWorkspace from './workspace/workspace.reducer'
import * as fromEmployee from './employee/employee.reducer'
import * as fromProject from './project/project.reducer'
import * as fromCalendar from './calendar/calendar.reducer'
import * as fromSnackbar from './snackbar/snackbar.reducer'
import * as fromNote from './note/note.reducer'
import * as fromTimeoff from './timeoff/timeoff.reducer'
import * as fromInvitation from './invitation/invitation.reducer'
import * as fromUsers from './user/user.reducer'
import * as fromSidebar from './sidebar/sidebar.reducer'

export interface RootStateModel {
  authorization: fromUser.StateModel
  process: fromProcess.StateModel
  workspace: fromWorkspace.StateModel
  employee: fromEmployee.StateModel
  project: fromProject.StateModel
  calendar: fromCalendar.StateModel
  snackbar: fromSnackbar.StateModel
  note: fromNote.StateModel
  timeoff: fromTimeoff.StateModel
  invitation: fromInvitation.StateModel
  user: fromUsers.StateModel
  sidebar: fromSidebar.StateModel
}

export const rootReducer = combineReducers({
  authorization: fromUser.UserReducer,
  process: fromProcess.ProcessReducer,
  workspace: fromWorkspace.ProcessReducer,
  employee: fromEmployee.EmployeeReducer,
  project: fromProject.ProjectReducer,
  calendar: fromCalendar.CalendarReducer,
  snackbar: fromSnackbar.SnackbarReducer,
  note: fromNote.NoteReducer,
  timeoff: fromTimeoff.TimeoffReducer,
  invitation: fromInvitation.InvitationReducer,
  user: fromUsers.UserReducer,
  sidebar: fromSidebar.SidebarReducer,
})

export type TRootState = ReturnType<typeof rootReducer>
