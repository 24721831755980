import { ThunkResult } from '@/store/store.types'
import { AddEmployeeModel } from '@/models/request-body-models/add-employee'
import {
  addEmployeeAsync,
  deleteEmployeeAsync,
  editEmployeeAsync,
  setEditableEmployee,
} from '@/store/employee/employee.actions'
import EmployeeTransactions from './employee.transactions'
import { EmployeeModel } from '@/models/responces/employee.model'

export default class EmployeeThunk {

  static addEmployee(employee: AddEmployeeModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(addEmployeeAsync.request(employee))
        await EmployeeTransactions.AddEmployee(employee)
        dispatch(addEmployeeAsync.success())
      } catch (e) {
        dispatch(addEmployeeAsync.failure(e))
        console.error('Add Process Error: ', e)
      }
    }
  }

  static editEmployee(employee: EmployeeModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(editEmployeeAsync.request(employee))
        dispatch(setEditableEmployee(null))
        await EmployeeTransactions.EditEmployee(employee)
        dispatch(editEmployeeAsync.success())
      } catch (e) {
        dispatch(editEmployeeAsync.failure(e))
        console.error('Edit Process Error: ', e)
      }
    }
  }

  static reorderEmployees(employee: EmployeeModel, newIndex: number): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await EmployeeTransactions.ReorderEmployees(employee, newIndex)
      } catch (e) {
        dispatch(editEmployeeAsync.failure(e))
        console.error('Reorder Employees Error: ', e)
      }
    }
  }

  static switchEmployees(employeeOne: EmployeeModel, employeeTwo: EmployeeModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await EmployeeTransactions.SwitchEmployees(employeeOne, employeeTwo)
      } catch (e) {
        dispatch(editEmployeeAsync.failure(e))
        console.error('Switch Employees Error: ', e)
      }
    }
  }
  static deleteEmployee(employeeId: string): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(deleteEmployeeAsync.request())
        dispatch(setEditableEmployee(null))
        await EmployeeTransactions.DeleteEmployee(employeeId)
        dispatch(deleteEmployeeAsync.success())
      } catch (e) {
        dispatch(deleteEmployeeAsync.failure(e))
        console.error('Delete Process Error: ', e)
      }
    }
  }
}