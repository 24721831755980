import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '@/firebase/constants'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'

export class TimeoffSubscription {
  static timeoffList(workspaceId: string, callback: DefaultSubscribeCallback<TimeoffModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.TIMEOFFS)
      .where('workspaceId', '==', workspaceId)
      .onSnapshot(snapshot => {
        const docs = snapshot.docs

        callback(
          docs.map(d => {
            return { ...d.data(), id: d.id } as TimeoffModel
          })
        )
      })
  }
}
