import React from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'


const ColorTaskShared: React.FC<{color: string}> = ({color}) => {
  return <Box className={css['task-color']} style={{backgroundColor: color}}/>
}

export default ColorTaskShared
