import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import css from './css.module.scss'
import classNames from "classnames"

interface InjectedProps {
  color: 'primary' | 'secondary'
  text: string
  onClick?: () => void
  disabled?: boolean
  size?: BtnSize
  className?: string
}
export enum BtnSize {
  SMALL= 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

const ButtonShared: React.FC<InjectedProps> = ({ color, onClick, disabled, text, children, size, className }) => {
  const click = useCallback(() => {
    !disabled && onClick && onClick()
  }, [onClick, disabled])
  return (
    <Button variant="contained" className={classNames(css[color], className)} onClick={click} disabled={disabled} size={size}>
      {children}
      <span>{text}</span>
    </Button>
  )
}

export default ButtonShared
