import { UserModel } from '@/models/responces/user.model'
import { ActionNames, TUserActions } from '@/store/authorization/authorization.actions'
import { RequestState } from '@/constants/request-state'

export class StateModel {
  checkedAuth: boolean = false
  user: UserModel | null = null
  loading: boolean = false
  sendLinkToEmail: RequestState = RequestState.UNSENT
  loginPending: RequestState = RequestState.UNSENT
  signupPending: RequestState = RequestState.UNSENT
  forgotPasswordPending: RequestState = RequestState.UNSENT
}

export const initialState = new StateModel()

export function UserReducer(state = initialState, action: TUserActions): StateModel {
  switch (action.type) {
    case ActionNames.AUTH:
      return {
        ...state,
        checkedAuth: true,
        user: action.payload,
      }

    case ActionNames.NOT_AUTH:
      return {
        ...state,
        checkedAuth: true,
        user: null,
      }

    case ActionNames.LOGIN: {
      return {
        ...state,
        loginPending: RequestState.SENDING,
      }
    }

    case ActionNames.LOGIN_SUCCESS: {
      return {
        ...state,
        loginPending: RequestState.SENT_SUCCESS,
      }
    }

    case ActionNames.LOGIN_FAILURE: {
      return {
        ...state,
        loginPending: RequestState.SENT_FAILED,
      }
    }

    case ActionNames.SIGN_UP: {
      return {
        ...state,
        signupPending: RequestState.SENDING,
      }
    }

    case ActionNames.SIGN_UP_SUCCESS: {
      return {
        ...state,
        signupPending: RequestState.SENT_SUCCESS,
      }
    }

    case ActionNames.SIGN_UP_FAILURE: {
      return {
        ...state,
        signupPending: RequestState.SENT_FAILED,
      }
    }

    case ActionNames.FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordPending: RequestState.SENDING,
      }
    }

    case ActionNames.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordPending: RequestState.SENT_SUCCESS,
      }
    }

    case ActionNames.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        forgotPasswordPending: RequestState.SENT_FAILED,
      }
    }

    default:
      return state
  }
}

export const AuthorizationSelectors = {
  authorized: (state: StateModel) => state.checkedAuth && !!state.user,
  unAuthorized: (state: StateModel) => state.checkedAuth && !state.user,
}
