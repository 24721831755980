import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import AuthorizationThunk from '@/store/authorization/authorization.thunks'
import FirebaseUsage from '@/firebase/firebase.usage'
import { Route, Switch } from 'react-router'
import Loading from '@/components/wrappers/loading'
import routingConstants from '@/routingConstants'
import { notAuth } from './store/authorization/authorization.actions'

export const AppRouting: React.FC = () => {
  const dispatch = useDispatch()
  const checkedAuth = useSelector<RootStateModel, boolean>(state => state.authorization.checkedAuth)

  useEffect(() => {
    FirebaseUsage.auth().onAuthStateChanged(async user => {
      if (user) {
        dispatch(
          AuthorizationThunk.auth({
            id: user.uid,
            email: user.email,
          })
        )
      } else {
        dispatch(notAuth())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })
  }, [dispatch])

  return checkedAuth ? (
    <Switch>
      {routingConstants.map((props, index) => (
        <Route key={index} {...props} />
      ))}
    </Switch>
  ) : (
    <Loading isLoading={true} />
  )
}

export default AppRouting
