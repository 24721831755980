import { MILLISECONDS_IN_HOUR } from './helpers/get-valid-task-time'

class Backlash {
  private current: number
  private threshold: number

  constructor(config: { current: number; threshold: number }) {
    this.current = config.current
    this.threshold = config.threshold
  }

  moveTo(newPosition: number) {
    const delta = Math.floor(Math.abs(newPosition - this.current))
    if (delta > this.threshold) {
      this.current = newPosition
    }
  }

  getCurrent() {
    return this.current
  }

  getThreshold() {
    return this.threshold
  }
}

const TWO_MONTH_MILLISECONDS = MILLISECONDS_IN_HOUR * 24 * 60

export const timelineBacklash = new Backlash({ current: Date.now(), threshold: TWO_MONTH_MILLISECONDS })
