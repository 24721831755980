import React from 'react'
import LayoutAppPage from '@/components/layouts/app-page'

export const Home: React.FC = () => {
  return (
    <LayoutAppPage>
    </LayoutAppPage>
  )
}
export default Home
