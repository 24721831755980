import React, { useCallback } from 'react'
import css from '@/pages/project/components/project-list-sidebar/css.module.scss'
import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import ColorTaskShared from '@/shared/color-box/ColorTaskShared'
import { TaskModel } from '@/models/responces/task.model'
import { millisecondsToHours } from '@/utils/transform-time'

const TaskListItem: React.FC<{ task: TaskModel }> = ({ task }) => {
  const getDuration = useCallback((task: TaskModel) => {
    return millisecondsToHours(
      task.process.stages.reduce((acc, cur) => {
        return acc + cur.workHours
      }, 0)
    )
  }, [])

  return (
    <ListItem button className={css['tasks__task']}>
      <ListItemAvatar className={css['tasks__task__color-box']}>
        <ColorTaskShared color={task.color} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant={'body2'} className={css['tasks__task__info']}>
            {task.name}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Typography variant={'body2'} className={css['tasks__task__info']}>
          {getDuration(task)} h
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default TaskListItem
