export const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#558b2f',
  '#ef6c00',
  '#ff5722',
  '#795548',
  '#757575',
  '#607d8b',
]

export const desaturatedColors = [
  '#000000',
  '#D3D9E3',
  '#FDCCD1',
  '#F9E6AC',
  '#BBE3CD',
  '#BBEFE8',
  '#B2E5FA',
  '#AFC0FD',
  '#C4BFD9',
  '#D5BCCB',
  '#D1C3C0',
  '#E9A00F',
  '#C93626',
  '#6C3050',
  '#5D6676',
  '#BA2A1B',
  '#656565',
]

export const palette = [
  '#000000',
  '#4B4B4B',
  '#656565',
  '#7F7F7F',
  '#989898',
  '#B2B2B2',
  '#CBCBCB',
  '#D3D9E3',
  '#939DAF',
  '#707A8A',
  '#5D6676',
  '#1C2533',
  '#FD9897',
  '#E84533',
  '#D63B2C',
  '#C93626',
  '#BA2A1B',
  '#F9E6AC',
  '#F3D57A',
  '#ECB81B',
  '#E9A00F',
  '#E98D0C',
  '#E95C08',
  '#BBE3CD',
  '#8ED1AE',
  '#2FB479',
  '#33945F',
  '#91E6DB',
  '#00D6C5',
  '#038A7F',
  '#056A5F',
  '#014E41',
  '#B2E5FA',
  '#80D3F9',
  '#2AB5F3',
  '#0A9CE3',
  '#0A89CE',
  '#08589A',
  '#AFC0FD',
  '#87A3F7',
  '#5874CB',
  '#2649AC',
  '#193FA0',
  '#0F3593',
  '#03247F',
  '#9F97C0',
  '#7E6CAC',
  '#584B8E',
  '#39275E',
  '#D5BCCB',
  '#A7547B',
  '#953B63',
  '#803559',
  '#6C3050',
  '#4B263F',
  '#B39F99',
  '#614238',
  '#5D3F36',
  '#3E2623',
]
