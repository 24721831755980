import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { NoteModel } from '@/models/responces/note.model'
import { AddNoteModel } from '@/models/request-body-models/add-note.model'

export enum ActionNames {
  SET_NOTE = '[NOTE] Set Note',

  ADD_NOTE = '[NOTE] Add Note',
  ADD_NOTE_SUCCESS = '[NOTE] Add Note Success',
  ADD_NOTE_FAILED = '[NOTE] Add Note Failed',

  EDIT_NOTE = '[NOTE] Edit Note',
  EDIT_NOTE_SUCCESS = '[NOTE] Edit Note Success',
  EDIT_NOTE_FAILED = '[NOTE] Edit Note Failed',

  RESET_LOADING = '[Note] Reset Loading',
}

export const setNote = createAction(ActionNames.SET_NOTE)<NoteModel>()

export const resetLoading = createAction(ActionNames.RESET_LOADING)()

export const addNoteAsync = createAsyncAction(
  ActionNames.ADD_NOTE,
  ActionNames.ADD_NOTE_SUCCESS,
  ActionNames.ADD_NOTE_FAILED
)<AddNoteModel, undefined, Error>()

export const editNoteAsync = createAsyncAction(
  ActionNames.EDIT_NOTE,
  ActionNames.EDIT_NOTE_SUCCESS,
  ActionNames.EDIT_NOTE_FAILED
)<NoteModel, undefined, Error>()

export type TNoteActions = ActionType<typeof addNoteAsync | typeof editNoteAsync | typeof setNote | typeof resetLoading>
