import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import { Box, Container } from '@material-ui/core'
import InputShared from '@/shared/text-input/InputShared'
import css from './css.module.scss'
import { AddTaskModel, DefaultTask, NO_EMPLOYEE, TaskModel, TaskModelMap } from '@/models/responces/task.model'
import TaskTemplate, { FormFields } from '@/pages/project/components/task/TaskTemplate'
import * as uuid from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { ProcessModel } from '@/models/responces/process.model'
import ProjectThunk from '@/store/project/project.thunk'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { RequestState } from '@/constants/request-state'
import { resetLoading, setEditableProject } from '@/store/project/project.actions'
import { getRandomColor } from '@/utils/random-color'
import { ProjectModel } from '@/models/responces/project.model'
import InfoBlock, { InjectedPropsInfo } from '@/shared/info-block/InfoBlock'
import { EEntityAction } from '@/constants/entity-action'
import { DetailsInfo } from '@/constants/content/project'
import ProjectsList from '@/pages/project/components/project-list/ProjectsList'
import ButtonShared from '@/shared/button/ButtonShared'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import AddLinkShared from '@/shared/link/AddLinkShared'
import cancelProjectImg from '@/assets/images/cancel-project.svg'
import CancelDialog from '@/shared/dialog/CancelDialog'
import { getValidBudget } from '@/utils/number'
import Loading from '@/components/wrappers/loading'
import { UserModel } from '@/models/responces/user.model'

interface InjectedProps {
  onClose: () => void
  open: boolean
}

const CreateProjectDialog: React.FC<InjectedProps> = ({ open, onClose }) => {
  const [name, setName] = useState('')
  const [budgetUSD, setBudgetUSD] = useState('')
  const [tasks, setTasks] = useState<AddTaskModel[]>([DefaultTask])
  const [deletedTasks, setDeletedTasks] = useState<string[]>([])
  const [cancelDialog, setCancelDialog] = useState<boolean>(false)
  const [editAction, setEditAction] = useState(false)

  const dispatch = useDispatch()
  const processesList = useSelector<RootStateModel, ProcessModel[] | null>(state => state.process.processesList)
  const workspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const editableProject = useSelector<RootStateModel, ProjectModel | null>(state => state.project.editableProject)
  const tasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.tasksMap)
  const assignedTasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.assignedTasksMap)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const projectsList = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)

  const loadingAddProject = useSelector<RootStateModel, RequestState>(state => state.project.loadingAddProject)
  const loadingEditProject = useSelector<RootStateModel, RequestState>(state => state.project.loadingEditProject)

  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null)

  const isFormValid = useMemo(() => {
    let valid = true
    if (!name.length) {
      valid = false
    }
    tasks.forEach(task => {
      if (!task.name.length || !task.process) {
        valid = false
      }
    })
    return valid
  }, [name, tasks])

  const resetForm = useCallback(() => {
    setName('')
    setBudgetUSD('')
    setTasks([DefaultTask])
    setDeletedTasks([])
  }, [])

  const tasksList: TaskModel[] = useMemo(() => {
    if (tasksMap && assignedTasksMap) {
      let map: TaskModel[] = []
      
      map = map.concat(
        Object.keys(tasksMap).reduce((res: TaskModel[], v: string) => {
          return res.concat(tasksMap[v])
        }, [])
      )

      map = map.concat(
        Object.keys(assignedTasksMap).reduce((res: TaskModel[], v: string) => {
          return res.concat(assignedTasksMap[v])
        }, [])
      )

      return map
    }
    if (tasksMap) {
      return Object.keys(tasksMap).reduce((res: TaskModel[], v: string) => {
        return res.concat(tasksMap[v])
      }, [])
    }
    return []
  }, [tasksMap, assignedTasksMap])

  useEffect(() => {
    if (editableProject && tasksMap && user) {
      setName(editableProject.name)
      setBudgetUSD(editableProject.budgetUSD.toString())
      setTasks(
        tasksList
          .filter(task => task.projectId === editableProject.id)
          .map(task => ({
            id: task.id,
            name: task.name,
            color: task.color,
            process: task.process,
            employeeId: task.employeeId ? task.employeeId : NO_EMPLOYEE,
            authorUid: user.id,
          }))
      )
    } else {
      resetForm()
    }
  }, [editableProject, resetForm, tasksMap, tasksList, user])

  const resetProject = useCallback(() => {
    dispatch(setEditableProject(null))
    resetForm()
  }, [dispatch, resetForm])

  const toggleCancelDialog = useCallback(() => {
    if (!editAction) {
      resetProject()
      onClose()
      return
    }
    setCancelDialog(!cancelDialog)
  }, [cancelDialog, editAction, onClose, resetProject])

  const changeName = useCallback((value: string) => {
    setName(value)
    setEditAction(true)
  }, [])

  const changeBudget = useCallback((value: string) => {
    setBudgetUSD(value ? getValidBudget(value) : '')
    setEditAction(true)
  }, [])

  const changeTask = useCallback((id: string, field: FormFields, value: string | ProcessModel | null) => {
    setTasks(tasks =>
      tasks.map(task => {
        return task.id === id
          ? {
              ...task,
              [field]: value,
            }
          : task
      })
    )
    setEditAction(true)
  }, [])

  const addTask = useCallback(() => {
    setTasks(tasks => [...tasks, { ...DefaultTask, id: uuid.v4(), color: getRandomColor() }])
  }, [])

  const removeTask = useCallback(
    (id: string) => {
      setTasks(tasks => tasks.filter(task => task.id !== id))
      if (editableProject) {
        setDeletedTasks([...deletedTasks, id])
      }
    },
    [editableProject, deletedTasks]
  )

  const tasksListToSave = useCallback(
    user => {
      return tasks.map(task => {
        task.employeeId === NO_EMPLOYEE && delete task.employeeId
        return { ...task, authorUid: user.id }
      })
    },
    [tasks]
  )

  const editProject = useCallback(() => {
    if (workspace && editableProject && isFormValid && user) {
      dispatch(
        ProjectThunk.editProject({
          tasks: tasksListToSave(user),
          project: {
            ...editableProject,
            id: editableProject.id,
            budgetUSD: Number(budgetUSD),
            name,
            workspaceId: workspace.id,
          },
          deletedTasksId: deletedTasks,
        })
      )
    }
  }, [dispatch, editableProject, budgetUSD, name, workspace, tasksListToSave, deletedTasks, isFormValid, user])

  const addProject = useCallback(() => {
    if (workspace && isFormValid && user) {
      let orderIndex = 0

      if (projectsList && projectsList.length) {
        const [lastProject] = projectsList.sort((a, b) => b.orderIndex - a.orderIndex)
        if (lastProject && lastProject.hasOwnProperty('orderIndex')) {
          orderIndex = lastProject.orderIndex + 1
        }
      }

      dispatch(
        ProjectThunk.addProject({
          tasks: tasksListToSave(user),
          project: {
            id: '',
            budgetUSD: Number(budgetUSD),
            name,
            workspaceId: workspace.id,
            authorUid: user.id,
            isArchived: false,
            orderIndex,
          },
        })
      )
    }
  }, [dispatch, workspace, budgetUSD, name, isFormValid, tasksListToSave, user, projectsList])

  useEffect(() => {
    if (
      loadingAddProject === RequestState.SENT_FAILED ||
      loadingAddProject === RequestState.SENT_SUCCESS ||
      loadingEditProject === RequestState.SENT_FAILED ||
      loadingEditProject === RequestState.SENT_SUCCESS
    ) {
      resetForm()
      timerRef.current = setTimeout(() => {
        dispatch(resetLoading())
        onClose()
      }, 3000)
    }
  }, [loadingAddProject, loadingEditProject, dispatch, resetForm, onClose])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  const isLoading = useMemo(() => {
    return loadingAddProject === RequestState.SENDING
  }, [loadingAddProject])

  const getInfoText = useCallback((): InjectedPropsInfo => {
    const id = editableProject ? EEntityAction.EDIT : EEntityAction.ADD
    const info = DetailsInfo.find(info => info.id === id)
    return { text: info ? info.text : '', heading: info ? info.heading : '', icon: info ? info.icon : '' }
  }, [editableProject])

  const getAddProjectForm = useCallback(() => {
    if (editableProject) {
      dispatch(setEditableProject(null))
    }
  }, [editableProject, dispatch])

  const saveChanges = useCallback(() => {
    if (editableProject) {
      editProject()
    } else {
      addProject()
    }
  }, [editableProject, editProject, addProject])

  const confirmCancel = useCallback(() => {
    resetProject()
    toggleCancelDialog()
    onClose()
  }, [onClose, toggleCancelDialog, resetProject])

  return (
    <DefaultDialog open={open} onClose={toggleCancelDialog}>
      <Loading isLoading={isLoading}>
        <Box className={css['wrapper']}>
          <ProjectsList getAddProjectForm={getAddProjectForm} />

          <Box className={css['wrapper__define-block']}>
            <Box className={css['wrapper__define-block__form']}>
              <InfoBlock {...getInfoText()} />

              <Box className={css['project']}>
                <Container className={css['project__form']}>
                  <InputShared value={name} onChange={changeName} label={'Project Name'} />
                  <InputShared
                    value={budgetUSD}
                    onChange={changeBudget}
                    label={'Project Budget ($)'}
                    placeholder={'Project Budget'}
                    number
                  />
                </Container>
              </Box>
              <Box className={css['tasks']}>
                <h3>Project Tasks</h3>
                <Box className={css['tasks__form']}>
                  {processesList &&
                    tasks.map((task, index) => (
                      <TaskTemplate
                        processesList={processesList}
                        task={task}
                        changeTask={changeTask}
                        removeTask={removeTask}
                        taskNumber={tasks.length}
                        index={index}
                        key={index}
                      />
                    ))}
                  <Box className={css['tasks__form__add-task']}>
                    <AddLinkShared text={'Add New Task'} onClick={addTask} />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={css['manage-stages']}>
              <Box className={css['btn-group']}>
                <CancelLinkShared onClick={toggleCancelDialog} />
                <ButtonShared onClick={saveChanges} disabled={!isFormValid} text={editableProject ? 'Save Changes' : 'Add Project'} color={'primary'} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Loading>
      <CancelDialog
        open={cancelDialog}
        onClose={toggleCancelDialog}
        image={cancelProjectImg}
        confirmAction={confirmCancel}
      />
    </DefaultDialog>
  )
}

export default CreateProjectDialog
