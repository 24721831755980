import { Dispatch, MiddlewareAPI } from 'redux'
import * as authorizationActions from './authorization.actions'
import { subsManager } from '@/store/middleware/subs-manager/subs-manager'
import { RootStateModel } from '@/store/root-reducer'

export function authorizationMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
  return (next: Dispatch) => (action: authorizationActions.TUserActions) => {
    const returnValue = next(action)

    switch (action.type) {
      case authorizationActions.ActionNames.AUTH: {
        const user = api.getState().authorization.user
        if (user) {
          subsManager.subscribeOwnedWorkspaces(user.id)
          subsManager.subscribeMemberWorkspaces(user.id, user.email!)
          subsManager.subscribeNotes(user.id)
          user.email && subsManager.subscribeUserInvitations(user.email)
          subsManager.subscribeUsers()
        }
        break
      }
      case authorizationActions.ActionNames.NOT_AUTH: {
        subsManager.removeAllSubscriptions()
        break
      }
      default: {
        break
      }
    }

    return returnValue
  }
}
