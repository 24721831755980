import { COLLECTIONS } from '../constants'
import FirebaseUsage from '../firebase.usage'
import { UserModel } from '@/models/responces/user.model'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'

export class UserSubscription {
  static user(userId: string, callback: DefaultSubscribeCallback<UserModel>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.USERS)
      .where('id', '==', userId)
      .onSnapshot(snapshot => {
        if (snapshot.docs && snapshot.docs.length) {
          callback(snapshot.docs[0].data() as UserModel)
        }
      })
  }

  static userList(callback: DefaultSubscribeCallback<UserModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.USERS)
      .onSnapshot(snapshot => {
        callback(snapshot.docs.map(d => ({ ...(d.data() as UserModel) })))
      })
  }
}
