import { ThunkResult } from '@/store/store.types'
import { AddNoteModel } from '@/models/request-body-models/add-note.model'
import { addNoteAsync, editNoteAsync } from '@/store/note/note.actions'
import { NoteModel } from '@/models/responces/note.model'
import NoteTransactions from '@/store/note/note.transactions'

export default class NoteThunk {
  static addNote(note: AddNoteModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(addNoteAsync.request(note))
        await NoteTransactions.AddNote(note)
        dispatch(addNoteAsync.success())
      } catch (e) {
        dispatch(addNoteAsync.failure(e))
        console.error('Add Note Error: ', e)
      }
    }
  }
  static editNote(note: NoteModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(editNoteAsync.request(note))
        await NoteTransactions.EditNote(note)
        dispatch(editNoteAsync.success())
      } catch (e) {
        dispatch(editNoteAsync.failure(e))
        console.error('Edit Note Error: ', e)
      }
    }
  }
}