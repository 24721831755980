import { SafeMap } from '@/utils/safe-map'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import store from '@/store/store'
import { setMemberWorkspaces, setOwnedWorkspaces } from '@/store/workspace/workspace.actions'
import { WorkspaceSubscription } from '@/firebase/subscriptions/workspaceSubscription'
import { AvailableSubscriptions } from '@/store/middleware/subs-manager/subs-manage.types'
import { ProcessSubscription } from '@/firebase/subscriptions/processesSubscription'
import { ProcessModel } from '@/models/responces/process.model'
import { setProcesses } from '@/store/process/process.actions'
import { EmployeeModel } from '@/models/responces/employee.model'
import { EmployeeSubscription } from '@/firebase/subscriptions/employeeSubscription'
import { setEmployees } from '@/store/employee/employee.actions'
import { setProjects, setAssignedTasks, setUnassignedTasks } from '@/store/project/project.actions'
import { ProjectModel } from '@/models/responces/project.model'
import { ProjectSubscription } from '@/firebase/subscriptions/projectSubscription'
import { TaskModel } from '@/models/responces/task.model'
import { NoteModel } from '@/models/responces/note.model'
import { setNote } from '@/store/note/note.actions'
import { setTimeoffs } from '@/store/timeoff/timeoff.actions'
import { NoteSubscription } from '@/firebase/subscriptions/noteSubscription'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { TimeoffSubscription } from '@/firebase/subscriptions/timeoffSubscription'
import { UserModel } from '@/models/responces/user.model'
import { auth } from '@/store/authorization/authorization.actions'
import { UserSubscription } from '@/firebase/subscriptions/userSubscription'
import { Invitation } from '@/models/responces/invitation.model'
import { setUserInvitations, setWorkspaceInvitations } from '@/store/invitation/invitation.actions'
import { InvitationSubscription } from '@/firebase/subscriptions/invitationSubscription'
import { setUsers } from '@/store/user/user.actions'

export enum Subscriptions {
  PROCESSES = 'processes',
  EMPLOYEES = 'employees',
  PROJECTS = 'projects',
  TASKS = 'tasks',
  NOTES = 'notes',
  TIMEOFFS = 'timeoffs',
  USERS = 'users',
  OWNED_WORKSPACES = 'owned_workspaces',
  MEMBER_WORKSPACES = 'member_workspaces',
  USER_INVITATIONS = 'user_invitations',
  WORKSPACE_INVITATIONS = 'workspace_invitations',
}

class SubsManager {
  unsubscribe = new SafeMap<AvailableSubscriptions, () => void>()

  private static handlerProcesses = (processes: ProcessModel[]) => store.dispatch(setProcesses(processes))
  private static handlerEmployees = (employees: EmployeeModel[]) => store.dispatch(setEmployees(employees))
  private static handlerProjects = (projects: ProjectModel[]) => store.dispatch(setProjects(projects))
  private static handlerUnassignedTasks = (projectId: string, taskList: TaskModel[]) => {
    store.dispatch(setUnassignedTasks({ projectId, taskList }))
  }
  private static handlerAssignedTasks = (projectId: string, taskList: TaskModel[]) => {
    store.dispatch(setAssignedTasks({ projectId, taskList }))
  }
  private static handlerNotes = (note: NoteModel) => store.dispatch(setNote(note))
  private static handlerTimeoffs = (timeoffs: TimeoffModel[]) => store.dispatch(setTimeoffs(timeoffs))
  private static handlerUser = (user: UserModel) => store.dispatch(auth(user))
  private static handlerOwnedWorkspaces = (workspaces: WorkspaceModel[]) =>
    store.dispatch(setOwnedWorkspaces(workspaces))
  private static handlerMemberWorkspaces = (workspaces: WorkspaceModel[]) =>
    store.dispatch(setMemberWorkspaces(workspaces))
  private static handlerUserInvitations = (invitations: Invitation[]) => store.dispatch(setUserInvitations(invitations))
  private static handlerWorkspaceInvitations = (invitations: Invitation[]) =>
    store.dispatch(setWorkspaceInvitations(invitations))
  private static handlerUsers = (users: UserModel[]) => store.dispatch(setUsers(users))

  private safeSubscribe(type: AvailableSubscriptions, unsubscribe: () => void) {
    this.unsubscribe.forEachKey(type, u => u())
    this.unsubscribe.set(type, unsubscribe)
  }

  subscribeOwnedWorkspaces(userId: string) {
    this.safeSubscribe(
      Subscriptions.OWNED_WORKSPACES,
      WorkspaceSubscription.ownedWorkspaces(userId, SubsManager.handlerOwnedWorkspaces)
    )
  }

  subscribeMemberWorkspaces(userId: string, userEmail: string) {
    this.safeSubscribe(
      Subscriptions.MEMBER_WORKSPACES,
      WorkspaceSubscription.memberWorkspaces(userId, userEmail, SubsManager.handlerMemberWorkspaces)
    )
  }

  subscribeUserInvitations(userEmail: string) {
    this.safeSubscribe(
      Subscriptions.USER_INVITATIONS,
      InvitationSubscription.userInvitations(userEmail, SubsManager.handlerUserInvitations)
    )
  }

  subscribeWorkspaceInvitations(workspaceId: string) {
    this.safeSubscribe(
      Subscriptions.WORKSPACE_INVITATIONS,
      InvitationSubscription.workspaceInvitations(workspaceId, SubsManager.handlerWorkspaceInvitations)
    )
  }

  subscribeProcesses(workspaceId: string) {
    this.safeSubscribe(
      Subscriptions.PROCESSES,
      ProcessSubscription.processesList(workspaceId, SubsManager.handlerProcesses)
    )
  }

  subscribeEmployees(workspaceId: string) {
    this.safeSubscribe(
      Subscriptions.EMPLOYEES,
      EmployeeSubscription.employeesList(workspaceId, SubsManager.handlerEmployees)
    )
  }

  subscribeUsers() {
    this.safeSubscribe(`${Subscriptions.USERS}_all` as any, UserSubscription.userList(SubsManager.handlerUsers))
  }

  subscribeProjects(workspaceId: string) {
    this.safeSubscribe(
      Subscriptions.PROJECTS,
      ProjectSubscription.projectList(workspaceId, SubsManager.handlerProjects)
    )
  }

  // TODO handle typing
  subscribeProjectTasks(project: ProjectModel) {
    this.safeSubscribe(
      `${Subscriptions.TASKS}_${project.id}_unassigned` as any,
      ProjectSubscription.unassignedTasksByProject(project, SubsManager.handlerUnassignedTasks)
    )
  }

  subscribeAssignedProjectTasks(project: ProjectModel, startDate: number, endDate: number) {
    this.safeSubscribe(
      `${Subscriptions.TASKS}_${project.id}_assigned` as any,
      ProjectSubscription.assignedTasksByProjectAndDate(project, startDate, endDate, SubsManager.handlerAssignedTasks)
    )
  }

  subscribeNotes(userId: string) {
    this.safeSubscribe(Subscriptions.NOTES, NoteSubscription.note(userId, SubsManager.handlerNotes))
  }

  subscribeUser(userId: string) {
    this.safeSubscribe(Subscriptions.USERS, UserSubscription.user(userId, SubsManager.handlerUser))
  }

  subscribeTimeoffs(workspaceId: string) {
    this.safeSubscribe(
      Subscriptions.TIMEOFFS,
      TimeoffSubscription.timeoffList(workspaceId, SubsManager.handlerTimeoffs)
    )
  }

  removeSubscription(type: AvailableSubscriptions) {
    const unsubscribe = this.unsubscribe.get(type)
    if (unsubscribe) {
      unsubscribe()
    }
  }

  removeAllSubscriptions() {
    this.unsubscribe.forEach(u => u())
  }
}

export const subsManager = new SubsManager()
