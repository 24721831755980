import { ActionNames, TUsersActions } from './user.actions'
import { UserModel } from '@/models/responces/user.model'

export class StateModel {
  users: UserModel[] | null = null
}

export const initialState = new StateModel()

export function UserReducer(state = initialState, action: TUsersActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_USERS: {
      return { ...state, users: action.payload }
    }

    default:
      return state
  }
}
