import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ButtonShared from '@/shared/button/ButtonShared'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import css from './css.module.scss'

interface InjectedProps {
  image: string
  open: boolean
  onClose: () => void
  confirmAction: () => void
}

const CancelDialog: React.FC<InjectedProps> = ({ image, onClose, open, confirmAction }) => {
  return (
    <DefaultDialog onClose={onClose} open={open}>
      <Box className={css['cancel-dialog']}>
        <img src={image} alt="" />
        <Box className={css['remove-dialog__info']}>
          <Typography variant={'h1'}> Are you sure you want <br/> to cancel?</Typography>
          <Typography variant={'body2'} className={css['remove-dialog__info__text']}>
            All Completed data will be deleted
          </Typography>
        </Box>
        <Box className={css['remove-dialog__btn']}>
          <Box className={css['remove-dialog__btn__cancel']}>
            <CancelLinkShared onClick={onClose} text={'No, Back'}/>
          </Box>
          <ButtonShared text={'Yes, Cancel'} color={'primary'} onClick={confirmAction} />
        </Box>
      </Box>
    </DefaultDialog>
  )
}

export default CancelDialog
