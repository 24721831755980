import { Invitation as IInvitation } from '@/models/responces/invitation.model'
import { UserModel } from '@/models/responces/user.model'
import InvitationTransactions from '@/store/invitation/invitation.transactions'
import { RootStateModel } from '@/store/root-reducer'
import { WorkspaceTransactions } from '@/store/workspace/workspace.transactions'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import css from './css.module.scss'
import classNames from 'classnames'
import { AvatarShared } from '../avatar/AvatarShared'
import deleteIcon from '@/assets/icons/delete-icon-green.svg'
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'

interface InjectedProps {
  invitation: IInvitation
  viewOnly?: boolean
}

export const Invitation: React.FC<InjectedProps> = ({ invitation, viewOnly }) => {
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const [deleteInvitationDialogOpen, setDeleteInvitationDialogOpenState] = useState(false)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const toggleDeleteInvitationDialogState = useCallback(() => {
    setDeleteInvitationDialogOpenState(val => !val)
  }, [setDeleteInvitationDialogOpenState])

  const acceptInvitation = useCallback(
    async (workspaceId: string, invitationId: string) => {
      if (!workspaceId || !invitationId || !user) {
        return
      }

      await WorkspaceTransactions.addMemberToWorkspace(workspaceId, user.id, user.email!)
      await InvitationTransactions.deleteInvitation(invitationId)
    },
    [user]
  )

  const declineInvitation = useCallback(
    async (invitationId: string) => {
      if (!invitationId) {
        return
      }

      await InvitationTransactions.deleteInvitation(invitationId)
      toggleDeleteInvitationDialogState()
    },
    [toggleDeleteInvitationDialogState]
  )

  const renderInvitation = useCallback(() => {
    if (viewOnly) {
      return (
        <div className={classNames(css.invitation, css['view-only'])}>
          <AvatarShared>{invitation.userEmail[0]}</AvatarShared>
          <span className={css['name']}>{invitation.userEmail}</span>
          {isRoleAllowed && (
            <img
              alt={'Delete icon'}
              onClick={toggleDeleteInvitationDialogState}
              src={deleteIcon}
              className={css['delete-icon']}
            />
          )}
        </div>
      )
    }

    return (
      <div className={classNames(css.invitation, css.general)}>
        <h3 className={css.title}>{invitation.workspaceName}</h3>
        <div style={{ marginTop: '15px' }} className={css.row}>
          <span className={css.subtitle}>Invited By:</span>
          <div style={{ marginLeft: '15px' }} className={css.row}>
            <AvatarShared>{invitation.invitedByName[0]}</AvatarShared>
            <span className={css['user-name']}>{invitation.invitedByName}</span>
          </div>
        </div>
        <div style={{ marginTop: '25px' }} className={css['buttons-container']}>
          <button onClick={toggleDeleteInvitationDialogState} className={classNames(css.button, css.reject)}>
            Reject
          </button>
          <button
            onClick={() => acceptInvitation(invitation.workspaceId, invitation.id!)}
            className={classNames(css.button, css.accept)}
          >
            Accept
          </button>
        </div>
      </div>
    )
  }, [viewOnly, invitation, acceptInvitation, toggleDeleteInvitationDialogState, isRoleAllowed])

  return (
    <>
      {renderInvitation()}
      <ConfirmationDialog
        open={deleteInvitationDialogOpen}
        onClose={toggleDeleteInvitationDialogState}
        title={
          viewOnly
            ? 'Are you sure you want to delete the invitation?'
            : 'Are you sure you want to reject the invitation?'
        }
        confirmAction={() => declineInvitation(invitation.id!)}
      />
    </>
  )
}
