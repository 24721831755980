import React from 'react'
import { Box, Typography } from '@material-ui/core'
import welcomeIcon from '@/assets/icons/welcome-icon.svg'
import css from './css.module.scss'
import dotIcon from '@/assets/icons/dot-icon.svg'
import dottedLineIcon from '@/assets/icons/dotted-line-icon.svg'

const steps = [
  { id: 0, text: 'Open Settings Cog in Bottom' },
  { id: 1, text: 'Define settings' },
  { id: 2, text: 'Save and Come back here to start new Project' },
]

const AddProcessesWelcome = () => {
  return (
    <Box className={css['wrapper']}>
      <img src={welcomeIcon} alt="" />
      <Typography variant={'button'} className={css['wrapper__heading']}>
        Welcome <br />
        to WorkLoad Pro!
      </Typography>
      <Box className={css['wrapper__instructions']}>
        {steps.map((step, index) => (
          <Box className={css['wrapper__instructions__item']} key={index}>
            <Box className={css['wrapper__instructions__item__dot-wrapper']}>
              <img src={dotIcon} alt="" className={css['wrapper__instructions__item__dot']} />
              {index !== steps.length - 1 && <img src={dottedLineIcon} className={css['wrapper__instructions__item__dotted-line']} alt="" />}
            </Box>
            <Typography variant={'body2'} className={css['wrapper__instructions__item__text']}>
              {step.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default AddProcessesWelcome
