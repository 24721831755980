import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import CreateProjectDialog from '@/pages/project/CreateProjectDialog'
import ButtonShared from '@/shared/button/ButtonShared'
import addIcon from '@/assets/icons/add-btn-icon.svg'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { ProcessModel } from '@/models/responces/process.model'
import classNames from 'classnames'

const ProjectManaging = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const processesList = useSelector<RootStateModel, ProcessModel[] | null>(state => state.process.processesList)
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)

  const toggleDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const closeDialog = useCallback(() => {
    setOpenDialog(false)
  }, [])

  const renderButton = useCallback(() => {
    if (isSidebarCollapsed) {
      return (
        <ButtonShared
          color={'primary'}
          text={''}
          onClick={toggleDialog}
          disabled={processesList ? !processesList.length : true}
          className={css['button-small']}
        >
          <img src={addIcon} alt="" className={css['button-wrapper__icon']} />
        </ButtonShared>
      )
    } else {
      return (
        <ButtonShared
          color="primary"
          text="Create New Project"
          onClick={toggleDialog}
          disabled={processesList ? !processesList.length : true}
          className={css['button-initial']}
        >
          <img src={addIcon} alt="" className={css['button-wrapper__icon']} />
        </ButtonShared>
      )
    }
  }, [isSidebarCollapsed, processesList, toggleDialog])

  return (
    <>
      <Box className={classNames(css['button-wrapper'], {
        [css['button-wrapper--hidden']]: isSidebarCollapsed
      })}>{renderButton()}</Box>
      {openDialog && <CreateProjectDialog onClose={closeDialog} open={openDialog} />}
    </>
  )
}

export default ProjectManaging
