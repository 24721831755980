import React, { useCallback, useMemo } from 'react'
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core'
import css from './css.module.scss'

interface InjectedProps {
  value: string
  onChange: (value: string) => void
  label: string
  placeholder?: string
  number?: boolean
  adornment?: string
  disabled?: boolean
  password?: boolean
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const InputShared: React.FC<InjectedProps> = ({ value, onChange, label, number, adornment, placeholder, disabled, password, onKeyUp }) => {
  const changeValue = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange(evt.target.value)
    },
    [onChange]
  )

  const type = useMemo(() => {
    if(number) {
      return 'number'
    }

    if(password) {
      return 'password'
    }

    return 'text'
  }, [number, password])

  return (
    <Box className={css['wrapper']}>
      <Typography variant={'body2'} className={css['wrapper__label']}>
        {label}
      </Typography>
      <TextField
        classes={{ root: css['wrapper__input'] }}
        value={value}
        onChange={changeValue}
        placeholder={placeholder ? placeholder : label}
        type={type}
        InputProps={{ startAdornment: adornment && <InputAdornment position="start">{adornment}</InputAdornment> }}
        variant="outlined"
        disabled={disabled}
        rowsMax={4}
        onKeyUp={onKeyUp}
      />
    </Box>
  )
}

export default InputShared
