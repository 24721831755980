import infoProcess from '../../assets/icons/info-process-icon.svg'
import { EEntityAction } from '@/constants/entity-action'

export const DetailsInfo = [
  {
    id: EEntityAction.ADD,
    heading: 'Create New Process and Stages',
    text: '',
    icon: infoProcess,
  },
  {
    id: EEntityAction.EDIT,
    heading: 'Define Process Details',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    icon: infoProcess,
  },
]
