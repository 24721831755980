import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '../constants'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'
import { ProcessModel } from '@/models/responces/process.model'

export class ProcessSubscription {
  static processesList(workspaceId: string, callback: DefaultSubscribeCallback<ProcessModel[]>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.PROCESSES)
      .where('workspaceId', '==', workspaceId)
      .onSnapshot(data => {
        callback(
          data.docs.map(
            doc =>
              ({
                ...doc.data(),
              } as ProcessModel)
          )
        )
      })
  }
}
