import { UserModel } from '@/models/responces/user.model'
import { ActionType, createAction } from 'typesafe-actions'

export enum ActionNames {
  SEND_SIGN_IN_LINK_TO_EMAIL = '[Authorization] Send Sign In Link To Email',
  SEND_SIGN_IN_LINK_TO_EMAIL_SUCCESS = '[Authorization] Send Sign In Link To Email Success',
  SEND_SIGN_IN_LINK_TO_EMAIL_FAIL = '[Authorization] Send Sign In Link To Email Fail',

  LOG_OUT = '[Authorization] Log Out',

  AUTH = '[Authorization] Auth',
  NOT_AUTH = '[Authorization] Not Auth',

  SIGN_IN_WITH_EMAIL_LINK = '[Authorization] Sign In With Email Link',

  ADD_USER = '[Authorization] Add User',
  LOGIN = '[Authorization] Login',
  LOGIN_SUCCESS = '[Authorization] Login Success',
  LOGIN_FAILURE = '[Authorization] Login Failure',
  SIGN_UP = '[Authorization] Sign Up',
  SIGN_UP_SUCCESS = '[Authorization] Sign Up Success',
  SIGN_UP_FAILURE = '[Authorization] Sign Up Failure',
  FORGOT_PASSWORD = '[Authorization] Forgot Password',
  FORGOT_PASSWORD_SUCCESS = '[Authorization] Forgot Password Success',
  FORGOT_PASSWORD_FAILURE = '[Authorization] Forgot Password Faiilure',
}

export const auth = createAction(ActionNames.AUTH)<UserModel>()
export const addUser = createAction(ActionNames.ADD_USER)<UserModel>()
export const notAuth = createAction(ActionNames.NOT_AUTH)()
export const logOut = createAction(ActionNames.LOG_OUT)()
export const login = createAction(ActionNames.LOGIN)()
export const loginSuccess = createAction(ActionNames.LOGIN_SUCCESS)()
export const loginFailure = createAction(ActionNames.LOGIN_FAILURE)()
export const signup = createAction(ActionNames.SIGN_UP)()
export const signupSuccess = createAction(ActionNames.SIGN_UP_SUCCESS)()
export const signupFailure = createAction(ActionNames.SIGN_UP_FAILURE)()
export const forgotPassword = createAction(ActionNames.FORGOT_PASSWORD)()
export const forgotPasswordSuccess = createAction(ActionNames.FORGOT_PASSWORD_SUCCESS)()
export const forgotPasswordFailure = createAction(ActionNames.FORGOT_PASSWORD_FAILURE)()

export type TUserActions = ActionType<
  | typeof auth
  | typeof notAuth
  | typeof logOut
  | typeof login
  | typeof loginSuccess
  | typeof loginFailure
  | typeof signup
  | typeof signupSuccess
  | typeof signupFailure
  | typeof forgotPassword
  | typeof forgotPasswordSuccess
  | typeof forgotPasswordFailure
>
