import React, { useCallback, useEffect, useState } from 'react'
import { Box, List, Typography, TabProps } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import css from './css.module.scss'
import Loading from '@/shared/circular-progress/Loading'
import AddLinkShared from '@/shared/link/AddLinkShared'
import RemoveDialog from '@/shared/dialog/RemoveDialog'
import { RequestState } from '@/constants/request-state'
import { setEditableProject } from '@/store/project/project.actions'
import { ProjectModel } from '@/models/responces/project.model'
import ProjectListItem from '@/pages/project/components/project-list/ProjectListItem'
import ProjectThunk from '@/store/project/project.thunk'
import { TaskModelMap } from '@/models/responces/task.model'
import Tabs from '@/shared/tabs/TabShared'
import ConfirmationDialog from '@/shared/dialog/ConfirmationDialog'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'

interface InjectedProps {
  getAddProjectForm: () => void
}

type TabValues = 'active' | 'archived'

const tabs: TabProps[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
]

const ProjectsList: React.FC<InjectedProps> = ({ getAddProjectForm }) => {
  const projectsList = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)
  const taskMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.tasksMap)
  const editableProject = useSelector<RootStateModel, ProjectModel | null>(state => state.project.editableProject)
  const loadingDeleteProject = useSelector<RootStateModel, RequestState>(state => state.project.loadingDeleteProject)
  const loadingEditProject = useSelector<RootStateModel, RequestState>(state => state.project.loadingEditProject)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const isEditable = useCallback(
    (id: string) => {
      return !!editableProject && editableProject.id === id
    },
    [editableProject]
  )

  const dispatch = useDispatch()
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [archiveDialog, setArchiveDialog] = useState(false)
  const [unarchiveDialog, setUnarchiveDialog] = useState(false)
  const [projectToDeleteId, setProjectToDeleteId] = useState<string | null>(null)
  const [projectToArchiveId, setProjectToArchiveId] = useState<string | null>(null)
  const [activeTabValue, setActiveTabValue] = useState<TabValues>('active')

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: TabValues) => {
      setActiveTabValue(newValue)
      dispatch(setEditableProject(null))
    },
    [setActiveTabValue, dispatch]
  )

  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialog(!deleteDialog)
    if (deleteDialog) {
      setProjectToDeleteId(null)
    }
  }, [deleteDialog])

  const toggleArchiveDialog = useCallback(() => {
    setArchiveDialog(val => !val)
  }, [setArchiveDialog])

  const toggleUnarchiveDialog = useCallback(() => {
    setUnarchiveDialog(val => !val)
  }, [setUnarchiveDialog])

  const projectTasks = useCallback(
    (projectId: string) => {
      return taskMap ? taskMap[projectId] : []
    },
    [taskMap]
  )

  const deleteProject = useCallback(() => {
    if (projectToDeleteId) {
      dispatch(ProjectThunk.deleteProject(projectToDeleteId, projectTasks(projectToDeleteId)))
    }
  }, [dispatch, projectToDeleteId, projectTasks])

  const toggleArchiveProject = useCallback(() => {
    if (projectToArchiveId && projectsList) {
      const project = projectsList.find(p => p.id === projectToArchiveId)

      if (project) {
        const toggledStatus = !project.isArchived

        dispatch(
          ProjectThunk.editProject({
            tasks: projectTasks(projectToArchiveId),
            project: { ...project, isArchived: toggledStatus },
            deletedTasksId: [],
          })
        )
      }
    }
  }, [dispatch, projectToArchiveId, projectsList, projectTasks])

  const deleteClick = useCallback(
    (id: string) => {
      setProjectToDeleteId(id)
      toggleDeleteDialog()
    },
    [toggleDeleteDialog]
  )

  const archiveClick = useCallback(
    (id: string) => {
      setProjectToArchiveId(id)
      toggleArchiveDialog()
    },
    [toggleArchiveDialog]
  )

  const unarchiveClick = useCallback(
    (id: string) => {
      setProjectToArchiveId(id)
      toggleUnarchiveDialog()
    },
    [toggleUnarchiveDialog]
  )

  useEffect(() => {
    if (loadingDeleteProject === RequestState.SENT_SUCCESS || loadingDeleteProject === RequestState.SENT_FAILED) {
      setDeleteDialog(false)
    }
  }, [loadingDeleteProject])

  return (
    <Box className={css['wrapper']}>
      {projectsList ? (
        <>
          <div>
            <Typography variant={'h1'} className={css['wrapper__count']}>
              {editableProject ? 'Edit Project' : `Create New Project`}
            </Typography>

            <Tabs onChange={handleTabChange} value={activeTabValue} tabs={tabs} />

            <List className={css['wrapper__list']}>
              {!!projectsList &&
                projectsList
                  .filter(p => {
                    return activeTabValue === 'active' ? !p.isArchived : p.isArchived
                  })
                  .map((project, index) => (
                    <ProjectListItem
                      key={index}
                      project={project}
                      isEditable={isEditable(project.id) && isRoleAllowed}
                      deleteClick={project.isArchived ? deleteClick : archiveClick}
                      unarchiveClick={project.isArchived ? unarchiveClick : undefined}
                    />
                  ))}
            </List>
          </div>

          <Box className={css['wrapper__add-new-wrapper']}>
            <AddLinkShared text={'Add One More Project'} onClick={getAddProjectForm} />
          </Box>
        </>
      ) : (
        <Loading isLoading={true} />
      )}

      <RemoveDialog
        heading={'Are you sure you want to Archive the Project?'}
        open={archiveDialog}
        onClose={toggleArchiveDialog}
        confirmAction={toggleArchiveProject}
        isLoading={loadingEditProject === RequestState.SENDING}
        actionButtonText={'Yes, Archive'}
      />

      <RemoveDialog
        heading={'Are you sure you want to Delete the Project?'}
        open={deleteDialog}
        onClose={toggleDeleteDialog}
        confirmAction={deleteProject}
        isLoading={loadingDeleteProject === RequestState.SENDING}
        actionButtonText={'Yes, Delete'}
      />

      <ConfirmationDialog
        title={'Are you sure you want to Unarchive the Project?'}
        open={unarchiveDialog}
        onClose={toggleUnarchiveDialog}
        confirmAction={toggleArchiveProject}
      />
    </Box>
  )
}

export default ProjectsList
