import React, { useCallback, useState } from 'react'
import AddLinkShared from '@/shared/link/AddLinkShared'
import CreateEmployeeDialog from '@/pages/employee/add-employee/CreateEmployeeDialog'

const AddEmployee = () => {
  const [openDialog, setOpenDialog] = useState(false)

  const toggleDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const closeDialog = useCallback(() => {
    setOpenDialog(false)
  }, [])

  return (
    <>
      <AddLinkShared text={'Create Employee'} onClick={toggleDialog} />
      {openDialog && <CreateEmployeeDialog open={openDialog} onClose={closeDialog} />}
    </>
  )
}

export default AddEmployee
