import React from 'react'
import initPlugins from '@/plugins'
import store from '@/store/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AppRouting from '@/AppRouting'
import { MuiThemeProvider } from '@material-ui/core'
import theme from '@/theme/theme'
import SnackbarsContainer from '@/shared/snackbars/snackbarsContainer'
// import { copyWorkspaceData } from '@/firebase/copy-workspace-data'

initPlugins()
// copyWorkspaceData()

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <div className="app">
        <MuiThemeProvider theme={theme}>

        <BrowserRouter>
          <Switch>
            <Route component={AppRouting} />
          </Switch>
        </BrowserRouter>
          <SnackbarsContainer />
        </MuiThemeProvider>
      </div>
    </Provider>
  )
}

export default App
