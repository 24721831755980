import { UserModel } from '@/models/responces/user.model'
import { RootStateModel } from '@/store/root-reducer'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import style from './css.module.scss'
import { Avatar } from '@material-ui/core'
import classNames from 'classnames'

export const AvatarShared: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...other }) => {
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const initials = useMemo(() => {
    if (user && user.name && user.surname) {
      const uppercasedName = user.name.toUpperCase()
      const uppercasedSurname = user.surname.toUpperCase()
      return `${uppercasedName[0]}${uppercasedSurname[0]}`
    }

    return ''
  }, [user])

  return (
    <Avatar {...other} className={classNames(style.avatar, className)}>
      {children ? children : initials}
    </Avatar>
  )
}
