const requiredEnvs = {
  NODE_ENV: '',
  REACT_APP_API_KEY : '',
  REACT_APP_AUTH_DOMAIN : '',
  REACT_APP_DATABASE_URL : '',
  REACT_APP_PROJECT_ID : '',
  REACT_APP_STORAGE_BUCKET : '',
  REACT_APP_MESSAGING_SENDER_ID : '',
  REACT_APP_APP_ID : '',
} as const;

type TypedEnv = typeof requiredEnvs;

const validateAndReturnTypedEnv = () => {
  const env: { [key: string]: string } = {};
  Object.keys(requiredEnvs).forEach(key => {
    if (!(key in process.env)) {
      throw new Error(`Required env "${key}" not defined`);
    }
    env[key] = process.env[key]!;
  });
  return env as TypedEnv;
};

export const TYPED_ENV = validateAndReturnTypedEnv();
