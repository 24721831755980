import React, { useCallback } from 'react'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import TaskThunk from '@/store/task/task.thunk'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { TaskToRemoveStageFrom } from './Calendar'
import css from './css.module.scss'
import ButtonShared from '@/shared/button/ButtonShared'
import { recoordinateStage } from '@/utils/helpers/get-valid-task-time'
import { StageModel } from '@/models/responces/stage.model'

interface InjectedProps {
  taskToRemoveData: TaskToRemoveStageFrom
  open: boolean
  onClose: () => void
}

export const RemoveStageDialog: React.FC<InjectedProps> = ({ taskToRemoveData, open, onClose }) => {
  const dispatch = useDispatch()

  const removeStage = useCallback(() => {
    const task = _.cloneDeep(taskToRemoveData.taskCopy)
    const stageIndex = task.process.stages.findIndex(s => s.id === taskToRemoveData.stageIdToRemove)

    if (stageIndex === -1) {
      onClose()
      return
    }

    const previousStage = task.process.stages[stageIndex - 1]
    const nextStage = task.process.stages[stageIndex + 1]
    let newStages: StageModel[] = []

    if (previousStage && !previousStage.isActive) {
      task.process.stages.splice(stageIndex - 1, 2)
      newStages = task.process.stages
    }

    if (!previousStage && nextStage && !nextStage.isActive) {
      task.process.stages.splice(stageIndex, 2)
      newStages = task.process.stages
    }

    if (!newStages.length) {
      dispatch(TaskThunk.deleteTask(task))
      onClose()
      return
    }

    const recoordinatedStages = recoordinateStage(newStages[0], [], newStages, newStages[0].date[0].startDate)
    const taskToEdit = { ...task, process: { ...task.process, stages: recoordinatedStages } }

    dispatch(TaskThunk.editTask(taskToEdit))

    onClose()
  }, [dispatch, onClose, taskToRemoveData])

  return (
    <DefaultDialog open={open} onClose={onClose}>
      <div className={css['remove-stage-dialog-wrapper']}>
        <ButtonShared onClick={onClose} color={'secondary'} text={'Cancel'} />
        <ButtonShared onClick={removeStage} color={'primary'} text={'Remove Stage'} />
      </div>
    </DefaultDialog>
  )
}
