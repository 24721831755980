import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { AuthorizationSelectors } from '@/store/authorization/authorization.reducer'
import { useEffect } from 'react'
import Loading from '@/components/wrappers/loading'
import RouteNameConstants from '@/constants/route-name.constants'

type PropsType = RouteComponentProps

const authRequired = (Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) => {
  return (props: PropsType) => {
    const checkedAuth = useSelector<RootStateModel, boolean>(state => state.authorization.checkedAuth)
    const unAuthorized = useSelector<RootStateModel, boolean>(state =>
      AuthorizationSelectors.unAuthorized(state.authorization)
    )

    useEffect(() => {
      if (unAuthorized && checkedAuth) {
        props.history.push(`/${RouteNameConstants.LOGIN}`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unAuthorized, checkedAuth])

    return checkedAuth ? <Component {...props} /> : <Loading isLoading={true} />
  }
}

export default authRequired
