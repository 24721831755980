import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Container, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { ProjectModel } from '@/models/responces/project.model'
import { TaskModel, TaskModelMap } from '@/models/responces/task.model'
import Loading from '@/shared/circular-progress/Loading'
import ProjectListItem from '@/pages/project/components/project-list-sidebar/ProjectListItem'
import css from './css.module.scss'
import AddProcessesWelcome from '@/pages/project/components/welcome/AddProcessesWelcome'
import { ProcessModel } from '@/models/responces/process.model'
import AddProjectsWelcome from '@/pages/project/components/welcome/AddProjectsWelcome'
import Box from '@material-ui/core/Box'
import SelectFilterShared from '@/shared/select/SelectFilterShared'
import { RequestState } from '@/constants/request-state'
import { ProjectSelectors } from '@/store/project/project.reducer'
import { getTasksArray } from '@/utils/helpers/get-tasks-array'
import TaskListItem from '@/pages/project/components/project-list-sidebar/TaskListItem'
import { CoordinatesModel } from '@/models/coordinates.model'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'
import classNames from 'classnames'

export enum FilterOption {
  ALL = 'All',
  ACTIVE = 'Active',
  BACKLOG = 'Backlog',
  ACHIVED = 'Archived',
}

const options = [FilterOption.ALL, FilterOption.ACTIVE, FilterOption.BACKLOG, FilterOption.ACHIVED]

const ProjectList = () => {
  const [filter, setFilter] = useState<FilterOption>(FilterOption.ALL)

  const changeFilter = useCallback((value: FilterOption) => {
    setFilter(value)
  }, [])

  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const projectsList = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)
  const tasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.tasksMap)
  const processesList = useSelector<RootStateModel, ProcessModel[] | null>(state => state.process.processesList)
  const loadingDeleteProject = useSelector<RootStateModel, RequestState>(state => state.project.loadingDeleteProject)
  const tasksLoaded = useSelector<RootStateModel, boolean>(state => ProjectSelectors.tasksListLoaded(state.project))
  const draggableItem = useSelector<RootStateModel, TaskModel | null>(state => state.calendar.draggableItem)
  const draggableCoordinates = useSelector<RootStateModel, CoordinatesModel | null>(
    state => state.calendar.draggableCoordinates
  )
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)
  const projectListContainerRef = useRef(null)

  const tasksList: TaskModel[] | null = useMemo(() => {
    return tasksLoaded && tasksMap ? getTasksArray(tasksMap) : null
  }, [tasksMap, tasksLoaded])

  const getProjectsFromIdArray = useCallback((projectsList: ProjectModel[], idArray: string[]) => {
    return projectsList.filter(project => idArray.includes(project.id))
  }, [])

  const filteredProjectsList = useMemo(() => {
    if (tasksList && projectsList) {
      switch (filter) {
        case FilterOption.ACTIVE: {
          const projectsIdsUnassignedTasks = tasksList
            .filter(task => task.hasOwnProperty('employeeId') && !!task.employeeId)
            .map(task => task.projectId)
          return getProjectsFromIdArray(projectsList, projectsIdsUnassignedTasks)
        }
        case FilterOption.BACKLOG: {
          const projectsIdsAssignedTasks = tasksList
            .filter(task => !task.hasOwnProperty('employeeId'))
            .map(task => task.projectId)
          return getProjectsFromIdArray(projectsList, projectsIdsAssignedTasks)
        }
        case FilterOption.ACHIVED: {
          const archivedProjects = projectsList.filter(p => p.isArchived)
          return archivedProjects
        }
        default: {
          return projectsList
        }
      }
    }
    return projectsList
  }, [filter, projectsList, tasksList, getProjectsFromIdArray])

  const isLoading = useMemo(() => {
    return (
      !projectsList || !tasksList || !processesList || !tasksLoaded || loadingDeleteProject === RequestState.SENDING
    )
  }, [projectsList, tasksList, loadingDeleteProject, processesList, tasksLoaded])

  const projectTasks = useCallback(
    (projectId: string) => {
      return tasksList ? tasksList.filter(task => task.projectId === projectId) : []
    },
    [tasksList]
  )

  const getComponent = useCallback(() => {
    if (filteredProjectsList && processesList && projectsList) {
      if (!processesList.length) {
        return <AddProcessesWelcome />
      }
      if (!projectsList.length) {
        return <AddProjectsWelcome />
      } else {
        return (
          <div ref={projectListContainerRef}>
            {!filteredProjectsList.length && (
              <Typography variant={'body2'} className={css['not-found-text']}>
                No Projects were found.
              </Typography>
            )}
            {filteredProjectsList
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map(project => (
                <ProjectListItem
                  containerRef={projectListContainerRef}
                  key={project.id}
                  project={project}
                  tasksList={projectTasks(project.id)}
                  isDraggable={filter === FilterOption.ALL && isRoleAllowed}
                />
              ))}
          </div>
        )
      }
    }
    return null
  }, [processesList, projectTasks, projectsList, filteredProjectsList, filter, isRoleAllowed])

  const coordinates = useMemo(() => {
    if (draggableCoordinates) {
      return { top: draggableCoordinates.y, left: draggableCoordinates.x }
    }

    return { top: 0, left: 0 }
  }, [draggableCoordinates])

  return (
    <Box
      className={classNames(css['wrapper'], {
        [css['wrapper--hidden']]: isSidebarCollapsed,
      })}
    >
      <Container className={css['project-container']}>
        {projectsList && tasksList && (
          <Box className={css['project-container__filter-wrapper']}>
            <Typography variant="h1" className={css['project-container__project-count']}>
              Your Projects
            </Typography>
            <SelectFilterShared options={options} value={filter} onChange={changeFilter} />
          </Box>
        )}
        {getComponent()}
        <Loading isLoading={isLoading} />
      </Container>

      {draggableItem && (
        <div
          style={{ top: `${coordinates.top}px`, left: `${coordinates.left}px` }}
          className={css['wrapper__draggable-task-wrapper']}
        >
          <TaskListItem task={draggableItem} />
        </div>
      )}
    </Box>
  )
}

export default ProjectList
