import { ActionType, createAction } from 'typesafe-actions'
import { MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'

export enum ActionNames {
  SET_WORKSPACE = '[Workspace] Set Workspace',
  SET_OWNED_WORKSPACES = '[Workspace] Set Owned Workspaces',
  SET_MEMBER_WORKSPACES = '[Workspace] Set Member Workspaces',
}

interface SetWorkspacePayload {
  workspace: WorkspaceModel
  role: MemberRoles
}

export const setWorkspace = createAction(ActionNames.SET_WORKSPACE)<SetWorkspacePayload>()
export const setOwnedWorkspaces = createAction(ActionNames.SET_OWNED_WORKSPACES)<WorkspaceModel[]>()
export const setMemberWorkspaces = createAction(ActionNames.SET_MEMBER_WORKSPACES)<WorkspaceModel[]>()

export type TWorkspaceActions = ActionType<typeof setWorkspace | typeof setOwnedWorkspaces | typeof setMemberWorkspaces>
