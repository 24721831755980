import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { AddProcessModel } from '@/models/request-body-models/add-process.model'
import { ProcessModel } from '@/models/responces/process.model'

export enum ActionNames {
  ADD_PROCESS = '[Process] Add Process',
  ADD_PROCESS_SUCCESS = '[Process] Add Process Success',
  ADD_PROCESS_FAILED = '[Process] Add Process Failed',

  DELETE_PROCESS = '[Process] Delete Process',
  DELETE_PROCESS_SUCCESS = '[Process] Delete Process Success',
  DELETE_PROCESS_FAILED = '[Process] Delete Process Failed',

  SET_EDITABLE_PROCESS = '[Process] Set Editable Process',

  EDIT_PROCESS = '[Process] Edit Process',
  EDIT_PROCESS_SUCCESS = '[Process] Edit Process Success',
  EDIT_PROCESS_FAILED = '[Process] Edit Process Failed',

  SET_PROCESSES = '[Process] Set Processes',

  RESET_LOADING = '[Process] Reset Loading',
}

export const addProcessAsync = createAsyncAction(
  ActionNames.ADD_PROCESS,
  ActionNames.ADD_PROCESS_SUCCESS,
  ActionNames.ADD_PROCESS_FAILED
)<AddProcessModel, undefined, Error>()

export const deleteProcessAsync = createAsyncAction(
  ActionNames.DELETE_PROCESS,
  ActionNames.DELETE_PROCESS_SUCCESS,
  ActionNames.DELETE_PROCESS_FAILED
)<undefined, undefined, Error>()

export const editProcessAsync = createAsyncAction(
  ActionNames.EDIT_PROCESS,
  ActionNames.EDIT_PROCESS_SUCCESS,
  ActionNames.EDIT_PROCESS_FAILED
)<undefined, undefined, Error>()

export const setProcesses = createAction(ActionNames.SET_PROCESSES)<ProcessModel[]>()
export const resetLoading = createAction(ActionNames.RESET_LOADING)()

export const setEditableProcess = createAction(ActionNames.SET_EDITABLE_PROCESS)<ProcessModel | null>()

export type TProcessActions = ActionType<
  | typeof addProcessAsync
  | typeof setProcesses
  | typeof resetLoading
  | typeof deleteProcessAsync
  | typeof setEditableProcess
  | typeof editProcessAsync
>
