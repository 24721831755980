import { Invitation } from '@/models/responces/invitation.model'
import { ActionNames, TInvitationActions } from './invitation.actions'

export class StateModel {
  userInvitations: Invitation[] = []
  workspaceInvitations: Invitation[] | null = null
}

export const initialState = new StateModel()

export function InvitationReducer(state = initialState, action: TInvitationActions) {
  switch (action.type) {
    case ActionNames.SET_USER_INVITATIONS: {
      return { ...state, userInvitations: action.payload }
    }

    case ActionNames.SET_WORKSPACE_INVITATION: {
      return { ...state, workspaceInvitations: action.payload }
    }

    default: {
      return state
    }
  }
}
