import {
  ActionNames,
  addProcessAsync,
  deleteProcessAsync,
  editProcessAsync,
  TProcessActions,
} from './process.actions'
import { ProcessModel } from '@/models/responces/process.model'
import { getType } from 'typesafe-actions'
import { RequestState } from '@/constants/request-state'

export class StateModel {
  processesList: ProcessModel[] | null = null
  editableProcess: ProcessModel | null = null
  loadingAddProcess: RequestState = RequestState.UNSENT
  loadingDeleteProcess: RequestState = RequestState.UNSENT
  loadingEditProcess: RequestState = RequestState.UNSENT
}

export const initialState = new StateModel()

export function ProcessReducer(state = initialState, action: TProcessActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_PROCESSES: {
      return {
        ...state,
        processesList: action.payload,
      }
    }
    case getType(addProcessAsync.request): {
      return { ...state, loadingAddProcess: RequestState.SENDING }
    }
    case getType(addProcessAsync.success): {
      return { ...state, loadingAddProcess: RequestState.SENT_SUCCESS }
    }
    case getType(addProcessAsync.failure): {
      return { ...state, loadingAddProcess: RequestState.SENT_FAILED }
    }
    case getType(deleteProcessAsync.request): {
      return { ...state, loadingDeleteProcess: RequestState.SENDING }
    }
    case getType(deleteProcessAsync.success): {
      return { ...state, loadingDeleteProcess: RequestState.SENT_SUCCESS }
    }
    case getType(deleteProcessAsync.failure): {
      return { ...state, loadingDeleteProcess: RequestState.SENT_FAILED }
    }
    case ActionNames.RESET_LOADING: {
      return {
        ...state,
        loadingAddProcess: RequestState.UNSENT,
        loadingDeleteProcess: RequestState.UNSENT,
        loadingEditProcess: RequestState.UNSENT,
      }
    }
    case ActionNames.SET_EDITABLE_PROCESS: {
      return { ...state, editableProcess: action.payload }
    }
    case getType(editProcessAsync.request): {
      return { ...state, loadingEditProcess: RequestState.SENDING }
    }
    case getType(editProcessAsync.success): {
      return { ...state, loadingEditProcess: RequestState.SENT_SUCCESS, editableProcess: null }
    }
    case getType(editProcessAsync.failure): {
      return { ...state, loadingEditProcess: RequestState.SENT_FAILED, editableProcess: null }
    }
    default:
      return state
  }
}
