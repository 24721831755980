import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import InputShared from '@/shared/text-input/InputShared'
import css from './css.module.scss'
import StageTemplate, { FormFields } from '@/pages/process/components/stage/StageTemplate'
import { getRandomColor } from '@/utils/random-color'
import { useDispatch, useSelector } from 'react-redux'
import ProcessThunk from '@/store/process/process.thunk'
import { RootStateModel } from '@/store/root-reducer'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { RequestState } from '@/constants/request-state'
import { resetLoading, setEditableProcess } from '@/store/process/process.actions'
import { DefaultStage, StageTemplateModel } from '@/models/responces/stage-template.model'
import ProcessesList from '@/pages/process/components/processes-list/ProcessesList'
import * as uuid from 'uuid'
import { ProcessModel } from '@/models/responces/process.model'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import AddLinkShared from '@/shared/link/AddLinkShared'
import InfoBlock, { InjectedPropsInfo } from '@/shared/info-block/InfoBlock'
import { EEntityAction } from '@/constants/entity-action'
import { DetailsInfo } from '@/constants/content/process'
import ButtonShared from '@/shared/button/ButtonShared'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import CancelDialog from '@/shared/dialog/CancelDialog'
import cancelProcessImg from '../../assets/images/cancel-process.svg'
import Loading from '@/components/wrappers/loading'
import ColorPickerShared from '@/shared/color-box/ColorPickerShared'
import { UserModel } from '@/models/responces/user.model'
import { hoursToMilliseconds } from '@/utils/transform-time'

interface InjectedProps {
  onClose: () => void
  open: boolean
  shouldCloseAfterSave?: boolean
}

const DefineProcessesDialog: React.FC<InjectedProps> = props => {
  const { open, onClose } = props
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [stages, setStages] = useState<StageTemplateModel[]>([DefaultStage])
  const [cancelDialog, setCancelDialog] = useState<boolean>(false)
  const [editAction, setEditAction] = useState(false)

  const dispatch = useDispatch()

  const workspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const loadingAddProcess = useSelector<RootStateModel, RequestState>(state => state.process.loadingAddProcess)
  const editableProcess = useSelector<RootStateModel, ProcessModel | null>(state => state.process.editableProcess)
  const loadingEditProcess = useSelector<RootStateModel, RequestState>(state => state.process.loadingEditProcess)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)

  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null)

  const resetForm = useCallback(() => {
    setName('')
    setColor(getRandomColor())
    setStages([DefaultStage])
  }, [])

  const changeColor = useCallback((newColor: string) => {
    setColor(newColor)
    setEditAction(true)
  }, [])

  const resetProcess = useCallback(() => {
    dispatch(setEditableProcess(null))
    resetForm()
  }, [dispatch, resetForm])

  const toggleCancelDialog = useCallback(() => {
    if (!editAction) {
      resetProcess()
      onClose()
      return
    }
    setCancelDialog(!cancelDialog)
  }, [cancelDialog, onClose, editAction, resetProcess])

  const confirmCancel = useCallback(() => {
    toggleCancelDialog()
    onClose()
    resetProcess()
  }, [onClose, toggleCancelDialog, resetProcess])

  const isFormValid = useMemo(() => {
    let valid = true
    if (!name.length) {
      valid = false
    }
    if(stages.length % 2 === 0) {
      valid = false
    }
    stages.forEach(stage => {
      if (stage.isActive && (!stage.name.length || !Number(stage.workHours))) {
        valid = false
      }
    })
    return valid
  }, [name, stages])

  useEffect(() => {
    if (editableProcess) {
      setName(editableProcess.name)
      setColor(editableProcess.color)
      setStages(editableProcess.stages.map(stage => ({ ...stage, workHours: stage.workHours.toString() })))
    } else {
      resetForm()
    }
  }, [editableProcess, resetForm])

  useEffect(() => {
    setColor(getRandomColor())
  }, [])

  const changeName = useCallback((value: string) => {
    setName(value)
    setEditAction(true)
  }, [])

  const changeStage = useCallback((id: string, field: FormFields, value: string | boolean) => {
    setStages(stages =>
      stages.map(stage => {
        return stage.id === id
          ? {
              ...stage,
              [field]: value,
            }
          : stage
      })
    )
    setEditAction(true)
  }, [])

  const addStage = useCallback(() => {
    const defaultNonActiveStage = {
      ...DefaultStage,
      isActive: false,
      id: uuid.v4(),
      workHours: `${hoursToMilliseconds(0)}`,
    }
    setStages(stages => {
      if (stages[stages.length - 1].isActive) {
        return [...stages, defaultNonActiveStage, { ...DefaultStage, id: uuid.v4() }]
      }
      return [...stages, { ...DefaultStage, id: uuid.v4() }, defaultNonActiveStage]
    })
  }, [])

  const removeStage = useCallback((id: string) => {
    setStages(stages => {
      const stageToDeleteIndex = stages.findIndex(s => s.id === id)
      const stage = stages[stageToDeleteIndex]
      const previousStage = stages[stageToDeleteIndex - 1]

      if(stage.isActive && previousStage && !previousStage.isActive) {
        return stages.filter((s, i) => i !== stageToDeleteIndex && i !== stageToDeleteIndex - 1)
      } else {
        return stages.filter(s => s.id !== id)
      }
    })
  }, [])

  const stagesToSave = useCallback(
    (user: UserModel) => {
      return stages.map(s => {
        return { ...s, workHours: Number(s.workHours), authorUid: user.id, weekendHours: 0 }
      })
    },
    [stages]
  )

  const addProcess = useCallback(() => {
    if (workspace && isFormValid && user) {
      dispatch(
        ProcessThunk.addProcess({
          name,
          color,
          stages: stagesToSave(user),
          workspaceId: workspace.id,
          authorUid: user.id,
        })
      )

      props.shouldCloseAfterSave && onClose()
    }
  }, [name, color, workspace, dispatch, stagesToSave, isFormValid, user, onClose, props.shouldCloseAfterSave])

  useEffect(() => {
    if (
      loadingAddProcess === RequestState.SENT_FAILED ||
      loadingAddProcess === RequestState.SENT_SUCCESS ||
      loadingEditProcess === RequestState.SENT_FAILED ||
      loadingEditProcess === RequestState.SENT_SUCCESS
    ) {
      resetForm()
      timerRef.current = setTimeout(() => {
        dispatch(resetLoading())
        onClose()
      }, 3000)
    }
  }, [loadingAddProcess, loadingEditProcess, dispatch, resetForm, onClose])

  const editProcess = useCallback(() => {
    if (workspace && editableProcess && isFormValid && user) {
      dispatch(
        ProcessThunk.editProcess({
          id: editableProcess.id,
          name,
          color,
          stages: stagesToSave(user),
          workspaceId: workspace.id,
        })
      )
    }
  }, [name, color, editableProcess, workspace, stagesToSave, dispatch, isFormValid, user])

  const isLoading = useMemo(() => {
    return loadingAddProcess === RequestState.SENDING || loadingEditProcess === RequestState.SENDING
  }, [loadingEditProcess, loadingAddProcess])

  const getInfoText = useCallback((): InjectedPropsInfo => {
    const id = editableProcess ? EEntityAction.EDIT : EEntityAction.ADD
    const info = DetailsInfo.find(info => info.id === id)
    return { text: info ? info.text : '', heading: info ? info.heading : '', icon: info ? info.icon : '' }
  }, [editableProcess])

  const saveChanges = useCallback(() => {
    if (editableProcess) {
      editProcess()
    } else {
      addProcess()
    }
  }, [editableProcess, editProcess, addProcess])

  const getAddProcessForm = useCallback(() => {
    if (editableProcess) {
      dispatch(setEditableProcess(null))
    }
  }, [editableProcess, dispatch])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <DefaultDialog onClose={toggleCancelDialog} open={open}>
      <Loading isLoading={isLoading}>
        <Box className={css['wrapper']}>
          <ProcessesList getAddProcessForm={getAddProcessForm} />

          <Box className={css['wrapper__define-block']}>
            <Box className={css['wrapper__define-block__form']}>
              <InfoBlock {...getInfoText()} />

              <Box className={css['wrapper__define-block__form__name']}>
                <InputShared value={name} onChange={changeName} label={'Process Name'} />
                <Box className={css['color-box']}>
                  <ColorPickerShared color={color} changeColor={changeColor} />
                </Box>
              </Box>

              <Box className={css['stages']}>
                {stages.map((stage, index) => (
                  <StageTemplate
                    changeStage={changeStage}
                    removeStage={removeStage}
                    stage={stage}
                    key={index}
                    index={index}
                    stagesNumber={stages.length}
                  />
                ))}
              </Box>
            </Box>

            <Box className={css['manage-stages']}>
              <AddLinkShared text={'Add Active Stage'} onClick={addStage} />

              <Box className={css['btn-group']}>
                <CancelLinkShared onClick={toggleCancelDialog} />
                <ButtonShared onClick={saveChanges} disabled={!isFormValid} text={'Save Changes'} color={'primary'} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Loading>
      <CancelDialog
        open={cancelDialog}
        onClose={toggleCancelDialog}
        image={cancelProcessImg}
        confirmAction={confirmCancel}
      />
    </DefaultDialog>
  )
}

export default DefineProcessesDialog
