import {
  ActionNames,
  addEmployeeAsync,
  deleteEmployeeAsync,
  editEmployeeAsync,
  TEmployeeActions,
} from './employee.actions'
import { getType } from 'typesafe-actions'
import { EmployeeModel } from '@/models/responces/employee.model'
import { RequestState } from '@/constants/request-state'

export class StateModel {
  employeesList: EmployeeModel[] | null = null
  editableEmployee: EmployeeModel | null = null
  loadingAddEmployee: RequestState = RequestState.UNSENT
  loadingEditEmployee: RequestState = RequestState.UNSENT
  loadingDeleteEmployee: RequestState = RequestState.UNSENT
}

export const initialState = new StateModel()

export function EmployeeReducer(state = initialState, action: TEmployeeActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_EMPLOYEES: {
      return {
        ...state,
        employeesList: action.payload.sort((a, b) => b.index - a.index),
      }
    }
    case getType(addEmployeeAsync.request): {
      return {
        ...state,
        loadingAddEmployee: RequestState.SENDING,
      }
    }
    case getType(addEmployeeAsync.success): {
      return {
        ...state,
        loadingAddEmployee: RequestState.SENT_SUCCESS,
      }
    }
    case getType(addEmployeeAsync.failure): {

      return {
        ...state,
        loadingAddEmployee: RequestState.SENT_FAILED,
      }
    }
    case ActionNames.SET_EDITABLE_EMPLOYEE: {
      return {
        ...state,
        editableEmployee: action.payload,
      }
    }
    case getType(editEmployeeAsync.request): {
      return {
        ...state,
        loadingEditEmployee: RequestState.SENDING,
      }
    }
    case getType(editEmployeeAsync.success): {
      console.log('edit success')
      return {
        ...state,
        loadingEditEmployee: RequestState.SENT_SUCCESS,
      }
    }
    case getType(editEmployeeAsync.failure): {
      console.log('edit fail')
      return {
        ...state,
        loadingEditEmployee: RequestState.SENT_FAILED,
      }
    }
    case getType(deleteEmployeeAsync.request): {
      return {
        ...state,
        loadingDeleteEmployee: RequestState.SENDING,
      }
    }
    case getType(deleteEmployeeAsync.success): {
      return {
        ...state,
        loadingDeleteEmployee: RequestState.SENT_SUCCESS,
      }
    }
    case getType(deleteEmployeeAsync.failure): {
      return {
        ...state,
        loadingDeleteEmployee: RequestState.SENT_FAILED,
      }
    }
    case ActionNames.RESET_LOADING: {
      return {
        ...state,
        loadingAddEmployee: RequestState.UNSENT,
        loadingDeleteEmployee: RequestState.UNSENT,
        loadingEditEmployee: RequestState.UNSENT,
      }
    }
    default:
      return state
  }
}
