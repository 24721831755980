import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { TimeoffModel } from '@/models/responces/timeoff.model'

export enum ActionNames {
  SET_TIMEOFFS = '[TIMEOFF] Set Timeoffs',
  ADD_TIMEOFF = '[TIMEOFF] Add Timeoff',
  ADD_TIMEOFF_SUCCESS = '[TIMEOFF] Add Timeoff Success',
  ADD_TIMEOFF_FAILURE = '[TIMEOFF] Add Timeoff Failure',
}

export const setTimeoffs = createAction(ActionNames.SET_TIMEOFFS)<TimeoffModel[]>()

export const addTimeoffAsync = createAsyncAction(
  ActionNames.ADD_TIMEOFF,
  ActionNames.ADD_TIMEOFF_SUCCESS,
  ActionNames.ADD_TIMEOFF_FAILURE
)<TimeoffModel, undefined, Error>()

export type TTimeoffActions = ActionType<typeof setTimeoffs | typeof addTimeoffAsync>
