import FirebaseUsage from '../firebase/firebase.usage'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'

export default class AuthorizationApi {
  @HandleAsyncErrors()
  public static async signInWithEmailAndPassword(email: string, password: string) {
    return FirebaseUsage.auth().signInWithEmailAndPassword(email, password)
  }

  @HandleAsyncErrors()
  public static async createUserWithEmailAndPassword(email: string, password: string) {
    return FirebaseUsage.auth().createUserWithEmailAndPassword(email, password)
  }

  @HandleAsyncErrors()
  public static async restorePassword(email: string) {
    return FirebaseUsage.auth().sendPasswordResetEmail(email)
  }

  @HandleAsyncErrors()
  public static async logOut() {
    return await FirebaseUsage.auth().signOut()
  }
}
