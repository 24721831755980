import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { runTransaction } from '@/firebase/helpers'
import { Invitation } from '@/models/responces/invitation.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'

export default abstract class InvitationTransactions {
  @HandleAsyncErrors()
  public static async createInvitation(invitation: Invitation) {
    await runTransaction(async transaction => {
      const newInvitationRef = FirebaseUsage.database().collection(COLLECTIONS.INVITATIONS).doc()

      transaction.set<Invitation>(newInvitationRef, invitation)
    })
  }

  @HandleAsyncErrors()
  public static async deleteInvitation(invitationId: string) {
    await FirebaseUsage.database().collection(COLLECTIONS.INVITATIONS).doc(invitationId).delete()
  }
}
