import React, { useCallback, useMemo, useState } from 'react'
import { Box, Container, IconButton } from '@material-ui/core'
import { AddTaskModel, NO_EMPLOYEE } from '@/models/responces/task.model'
import css from './css.module.scss'
import InputShared from '@/shared/text-input/InputShared'
import AutocompleteShared from '@/shared/autocomplete/AutocompleteShared'
import { ProcessModel } from '@/models/responces/process.model'
import deleteIcon from '@/assets/icons/delete-icon.svg'
import ColorPickerShared from '@/shared/color-box/ColorPickerShared'
import DefineProcessesDialog from '@/pages/process/DefineProcessesDialog'
import { DropdownActionModel } from '@/models/dropdown-action.model'
import { ProjectModel } from '@/models/responces/project.model'

export enum FormFields {
  name = 'name',
  process = 'process',
  color = 'color',
}

interface InjectedProps {
  processesList: ProcessModel[]
  task: AddTaskModel
  changeTask: (id: string, field: FormFields, value: string | ProcessModel | null) => void
  removeTask: ((id: string) => void) | false
  index: number
  taskNumber: number
}

const TaskTemplate: React.FC<InjectedProps> = ({ index, changeTask, removeTask, taskNumber, task, processesList }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const toggleProcessDialog = useCallback(() => {
    setOpenDialog(val => !val)
  }, [])

  const remove = useCallback(() => {
    if (removeTask) removeTask(task.id)
  }, [task, removeTask])

  const options = useMemo(() => {
    const actions: DropdownActionModel[] = [
      {
        name: 'Add New Process',
        action: toggleProcessDialog,
      },
    ]

    return [...processesList, ...actions]
  }, [processesList, toggleProcessDialog])

  return (
    <Container className={css['form']}>
      <InputShared
        label={'Name'}
        value={task.name}
        onChange={(value: string) => changeTask(task.id, FormFields.name, value)}
        disabled={!!task.employeeId && task.employeeId !== NO_EMPLOYEE}
      />
      <AutocompleteShared
        value={task.process}
        onChange={(value: ProcessModel | DropdownActionModel | ProjectModel | null) => {
          if (value && 'action' in value) {
            value.action()
          } else {
            changeTask(task.id, FormFields.process, value as ProcessModel)
          }
        }}
        options={options}
        label={'Task Process'}
        disabled={!!task.employeeId && task.employeeId !== NO_EMPLOYEE}
      />
      <Box className={css['form__color-box']}>
        <ColorPickerShared
          color={task.color}
          changeColor={(value: string) => changeTask(task.id, FormFields.color, value)}
        />
      </Box>
      {removeTask && (
        <IconButton onClick={remove} disabled={taskNumber <= 1} className={css['form__delete-icon']}>
          <img src={deleteIcon} alt="" />
        </IconButton>
      )}

      <DefineProcessesDialog open={openDialog} onClose={toggleProcessDialog} />
    </Container>
  )
}

export default TaskTemplate
