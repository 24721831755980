import React from 'react'
import { MenuItem, Select, Typography } from '@material-ui/core'
import { FilterOption } from '@/pages/project/components/project-list-sidebar/ProjectList'
import css from './css.module.scss'
import selectIcon from '@/assets/icons/select-icon.svg'

interface InjectedProps {
  options: FilterOption[]
  value: FilterOption
  onChange: (value: FilterOption) => void
}

const SelectFilterShared: React.FC<InjectedProps> = ({ options, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as FilterOption)
  }

  return (
    <Select
      value={value}
      onChange={handleChange}
      className={css['select']}
      IconComponent={() => <img src={selectIcon} alt="" />}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option} className={css['select__menu-item']}>
          <Typography variant={'body2'} className={css['select__menu-item__text']}>
            {option}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  )
}
export default SelectFilterShared
