import React from 'react'
import { Box, Typography } from '@material-ui/core'
import deleteImage from '@/assets/images/delete-process.svg'
import ButtonShared from '@/shared/button/ButtonShared'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import css from './css.module.scss'
import Loading from '@/components/wrappers/loading'

interface InjectedProps {
  heading: string
  text?: string
  open: boolean
  onClose: () => void
  confirmAction: () => void
  isLoading?: boolean
  actionButtonText?: string
}

const RemoveDialog: React.FC<InjectedProps> = ({ heading, text, onClose, open, confirmAction, isLoading, actionButtonText }) => {
  return (
    <DefaultDialog onClose={onClose} open={open}>
      <Loading isLoading={!!isLoading}>
      <Box className={css['remove-dialog']}>
        <img src={deleteImage} alt="" />
        <Box className={css['remove-dialog__info']}>
          <Typography variant={'h1'}>{heading}</Typography>
          {text && <Typography variant={'body2'} className={css['remove-dialog__info__text']}>
            {text}
          </Typography>}
        </Box>
        <Box className={css['remove-dialog__btn']}>
          <Box className={css['remove-dialog__btn__cancel']}>
            <CancelLinkShared onClick={onClose} />
          </Box>
          <ButtonShared text={actionButtonText || 'Yes, Delete'} color={'primary'} onClick={confirmAction} />
        </Box>
      </Box>
      </Loading>
    </DefaultDialog>
  )
}

export default RemoveDialog
