import React, { useState, useCallback, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import css from './css.module.scss'
import AuthorizationThunk from '@/store/authorization/authorization.thunks'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@/shared/circular-progress/Loading'
import { RootStateModel } from '@/store/root-reducer'
import { RequestState } from '@/constants/request-state'
import logo from '../../assets/icons/logo.svg'
import Input from '@/shared/text-input/InputShared'
import Button, { BtnSize } from '@/shared/button/ButtonShared'

enum LoginFormState {
  SIGN_IN = 'sign_in',
  SIGN_UP = 'sign_up',
  FORGOT_PASSWORD = 'forgot_password',
}

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [formState, setFormState] = useState<LoginFormState>(LoginFormState.SIGN_IN)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }, [formState])

  useEffect(() => {
    setErrors([])
  }, [email, password, confirmPassword])

  const forgotPasswordState = useSelector<RootStateModel, RequestState>(
    state => state.authorization.forgotPasswordPending
  )
  const signInState = useSelector<RootStateModel, RequestState>(state => state.authorization.loginPending)
  const signUpState = useSelector<RootStateModel, RequestState>(state => state.authorization.signupPending)

  const changeEmail = useCallback((value: string) => {
    setEmail(value)
  }, [])

  const changePassword = useCallback((value: string) => {
    setPassword(value)
  }, [])

  const changeConfirmPassword = useCallback((value: string) => {
    setConfirmPassword(value)
  }, [])

  const signIn = useCallback(() => {
    if (!email.length || !password.length) {
      setErrors(e => [...e, 'Email and Password are required fields'])
      return
    }

    dispatch(AuthorizationThunk.signInWithEmailAndPassword(email, password))
  }, [email, password, dispatch])

  const signUp = useCallback(() => {
    if (password !== confirmPassword) {
      setErrors(e => [...e, 'Passwords do not match'])
      return
    }

    if (password.length < 6) {
      setErrors(e => [...e, 'Password cannot be less than 6 letters'])
    }

    dispatch(AuthorizationThunk.createUserWithEmailAndPassword(email, password))
  }, [email, password, confirmPassword, dispatch])

  const restorePassword = useCallback(() => {
    if (!email.length) {
      setErrors(e => [...e, 'Email is required'])
      return
    }

    dispatch(AuthorizationThunk.restorePassword(email))
  }, [email, dispatch])

  const changeFormState = useCallback((state: LoginFormState) => {
    setFormState(state)
  }, [])

  const renderForm = useCallback(() => {
    if (formState === LoginFormState.SIGN_IN) {
      return (
        <>
          <Input value={email} label={''} placeholder={'Email'} onChange={changeEmail} />
          <Input value={password} label={''} placeholder={'Password'} onChange={changePassword} password />
          <Button className={css['btn']} size={BtnSize.MEDIUM} onClick={signIn} color={'primary'} text={'Sign In'} />
          <Typography
            onClick={() => changeFormState(LoginFormState.SIGN_UP)}
            className={css['link']}
            variant={'button'}
          >
            Don't have account yet? Create a new one
          </Typography>
          <Typography
            onClick={() => changeFormState(LoginFormState.FORGOT_PASSWORD)}
            className={css['link']}
            variant={'button'}
          >
            Forgot password
          </Typography>
        </>
      )
    }

    if (formState === LoginFormState.SIGN_UP) {
      return (
        <>
          <Input value={email} label={''} placeholder={'Email'} onChange={changeEmail} />
          <Input value={password} label={''} placeholder={'Password'} onChange={changePassword} password />
          <Input
            value={confirmPassword}
            label={''}
            placeholder={'Confirmation Password'}
            onChange={changeConfirmPassword}
            password
          />
          <Button className={css['btn']} size={BtnSize.MEDIUM} onClick={signUp} color={'primary'} text={'Sign Up'} />
          <Typography
            onClick={() => changeFormState(LoginFormState.SIGN_IN)}
            className={css['link']}
            variant={'button'}
          >
            I have an account
          </Typography>
        </>
      )
    }

    if (formState === LoginFormState.FORGOT_PASSWORD) {
      return (
        <>
          <Input value={email} label={''} placeholder={'Email'} onChange={changeEmail} />
          <Button
            size={BtnSize.MEDIUM}
            color={'primary'}
            onClick={restorePassword}
            className={css['btn']}
            text={'Send a reset password mail'}
          />
          <Typography
            onClick={() => changeFormState(LoginFormState.SIGN_IN)}
            className={css['link']}
            variant={'button'}
          >
            I remember my password
          </Typography>
        </>
      )
    }

    return null
  }, [
    formState,
    changePassword,
    changeEmail,
    email,
    password,
    signIn,
    changeConfirmPassword,
    changeFormState,
    confirmPassword,
    restorePassword,
    signUp,
  ])

  const renderInfo = useCallback(() => {
    if (forgotPasswordState === RequestState.SENDING) {
      return (
        <Typography variant={'body2'} className={css['info']}>
          We sent a restore mail to your email.
        </Typography>
      )
    }

    return null
  }, [forgotPasswordState])

  return (
    <Box className={css['root']}>
      <Box className={css['container']}>
        <img src={logo} alt="" className={css['logo']} />
        <Box className={css['input-wrapper']}>{renderForm()}</Box>
        {renderInfo()}
        <Loading
          isLoading={
            forgotPasswordState === RequestState.SENDING ||
            signInState === RequestState.SENDING ||
            signUpState === RequestState.SENDING
          }
        />
        {errors.map((e, i) => {
          return (
            <Typography key={i} variant={'body2'} className={css['error']}>
              {e}
            </Typography>
          )
        })}
      </Box>
    </Box>
  )
}

export default Login
