import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Calendar from '@/pages/calendar/Calendar'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { EmployeeModel } from '@/models/responces/employee.model'
import { Group, ItemModel } from '@/models/calendar.model'
import EmployeeListItem from '@/pages/employee/employees-list/EmployeeListItem'
import Loading from '@/shared/circular-progress/Loading'
import { TaskModel, TaskModelMap } from '@/models/responces/task.model'
import { setCalendarItems, setGroups } from '@/store/calendar/calendar.actions'
import moment from 'moment'
import { getTaskEndDate, getTaskStartDate } from '@/utils/calendar-time'
import { DatePeriodModel } from '@/models/responces/date-period.model'
import { BORDER_ACTIVE } from '@/constants/calendar'
import { ProjectModel } from '@/models/responces/project.model'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'

const CalendarWrapper = () => {
  const [displayNonActiveStages, setDisplayNonActiveStages] = useState(true)

  const employeesList = useSelector<RootStateModel, EmployeeModel[] | null>(state => state.employee.employeesList)
  const tasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.assignedTasksMap)
  const projectsList = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)
  const groups = useSelector<RootStateModel, Group[] | null>(state => state.calendar.groups)
  const timeoffsList = useSelector<RootStateModel, TimeoffModel[]>(state => state.timeoff.timeoffsList)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const dispatch = useDispatch()

  const toggleDisplayNonActiveStages = useCallback(() => {
    setDisplayNonActiveStages(value => !value)
  }, [])

  useEffect(() => {
    if (employeesList) {
      dispatch(
        setGroups(
          employeesList.map((employee, index) => ({
            id: index,
            title: <EmployeeListItem employee={employee} index={index} />,
            employeeId: employee.id,
            key: employee.id,
          }))
        )
      )
    }
  }, [employeesList, dispatch])

  const getProjectName = useCallback(
    (projectId: string): string | null => {
      if (projectsList) {
        const project = projectsList.find(project => project.id === projectId)
        return project ? project.name : null
      }
      return null
    },
    [projectsList]
  )

  const getGroupId = useCallback(
    (employeeId: string) => {
      if (employeesList) {
        return employeesList.findIndex(e => e.id === employeeId)
      }
      return -1
    },
    [employeesList]
  )

  const getBorderRadius = useCallback((taskStartTime: number, taskEndTime: number, stage: DatePeriodModel) => {
    if (stage.startDate === taskStartTime) {
      if (stage.endDate === taskEndTime) {
        return '10px 10px 10px 10px'
      }
      return '10px 0 0 10px'
    } else if (stage.endDate === taskEndTime) {
      return '0 10px 10px 0'
    }
    return '0'
  }, [])

  useEffect(() => {
    if (tasksMap && employeesList) {
      const newCalendarItems: ItemModel[] = []

      timeoffsList.forEach(timeoff => {
        const stage = timeoff.process.stages[0]
        const date = stage.date

        date.forEach(d => {
          newCalendarItems.push({
            taskId: timeoff.id,
            id: `${newCalendarItems.length.toString()};${timeoff.id}`,
            style: {},
            color: timeoff.color,
            title: stage.name,
            stageName: stage.name,
            taskName: timeoff.name,
            projectName: 'Timeoff',
            start_time: moment(d.startDate),
            end_time: moment(d.endDate),
            group: getGroupId(timeoff.employeeId),
            stageId: stage.id,
            isActive: true,
            canResize: isRoleAllowed ? BORDER_ACTIVE.BOTH : false,
            canMove: isRoleAllowed,
            workHours: stage.workHours,
            weekendHours: stage.weekendHours,
            type: 'timeoff',
            employeeId: timeoff.employeeId!,
          })
        })
      })

      Object.keys(tasksMap).forEach(key => {
        const tasks: TaskModel[] = tasksMap[key]
        tasks.forEach(task => {
          if (task.employeeId) {
            const taskStartTime = getTaskStartDate(task.process.stages)
            const taskEndTime = getTaskEndDate(task.process.stages)
            task.process.stages.forEach(stage => {
              stage.date.forEach(date => {
                const projectName = getProjectName(task.projectId)
                const startDate = date.startDate
                const endDate = date.endDate

                if (projectName) {
                  let canResize: boolean | BORDER_ACTIVE = false

                  if (isRoleAllowed) {
                    if(stage.isActive) {
                      canResize = BORDER_ACTIVE.BOTH
                    }

                    if(displayNonActiveStages) {
                      canResize = BORDER_ACTIVE.RIGHT
                    }
                  }

                  let canMove = false

                  if(isRoleAllowed && stage.isActive) {
                    canMove = true
                  }

                  newCalendarItems.push({
                    taskId: task.id,
                    id: `${newCalendarItems.length.toString()};${task.id}`,
                    style: { borderRadius: getBorderRadius(taskStartTime, taskEndTime, date) },
                    color: task.color,
                    title: stage.isActive ? stage.name : '',
                    stageName: stage.name,
                    taskName: task.name,
                    projectName,
                    start_time: moment(startDate),
                    end_time: moment(endDate),
                    group: getGroupId(task.employeeId!),
                    stageId: stage.id,
                    isActive: stage.isActive,
                    canResize,
                    canMove,
                    workHours: stage.workHours,
                    weekendHours: stage.weekendHours,
                    type: 'project',
                    employeeId: task.employeeId!,
                    originalTaskId: task.originalTaskId,
                  })
                }
              })
            })
          }
        })
      })

      dispatch(setCalendarItems(newCalendarItems))
    }
  }, [
    tasksMap,
    employeesList,
    getGroupId,
    dispatch,
    getBorderRadius,
    getProjectName,
    displayNonActiveStages,
    timeoffsList,
    isRoleAllowed
  ])

  return (
    <Box>
      {groups && (
        <Calendar
          groups={groups}
          displayNonActiveStages={displayNonActiveStages}
          toggleDisplayNonActiveStages={toggleDisplayNonActiveStages}
        />
      )}

      <Loading isLoading={!groups} />
    </Box>
  )
}

export default CalendarWrapper
