import * as uuid from 'uuid'
import { DatePeriodModel } from '@/models/responces/date-period.model'
import { AuthorModel } from '@/models/author.model'
import { hoursToMilliseconds } from '@/utils/transform-time'

export interface StageTemplateModel extends AuthorModel{
  readonly id: string
  name: string
  isActive: boolean
  workHours: string
  date: DatePeriodModel[]
}

export const DefaultStage: StageTemplateModel = {
  id: uuid.v4(),
  name: '',
  workHours: `${hoursToMilliseconds(8)}`,
  isActive: true,
  date: [],
  authorUid: '',
}
