import { ThunkResult } from '@/store/store.types'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { addTimeoffAsync } from '@/store/timeoff/timeoff.actions'
import TimeoffTransactions from '@/store/timeoff/timeoff.transactions'

export default class TimeoffThunk {
  static addTimeoff(timeoff: TimeoffModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(addTimeoffAsync.request(timeoff))
        await TimeoffTransactions.AddTimeoff(timeoff)
        dispatch(addTimeoffAsync.success())
      } catch (e) {
        console.log('Timeoff addition error:', e)
        dispatch(addTimeoffAsync.failure(e))
      }
    }
  }
}
