import moment, { Moment } from 'moment'

export const getValidHours = (hr: number) => {
  const roundedHr = Math.floor(hr)
  return roundedHr > 99 ? 99 : roundedHr < 0 ? 0 : roundedHr
}

export const SNAP_TIME = 3 * 60 * 60e3

export const roundCalendarTime = (value: number): Moment => {
  const initialRoundValue = Math.round(value / SNAP_TIME) * SNAP_TIME
  const hours = new Date(initialRoundValue).getHours()
  const roundedToClosestThreeHours = Math.round(hours / 3) * 3
  const validHoursTime = new Date(initialRoundValue).setHours(roundedToClosestThreeHours)
  return moment(validHoursTime)
}

export const roundDurationTime = (value: number) => {
  return Math.round(value / SNAP_TIME) * SNAP_TIME
}

export const minutesToMilliseconds = (minutes: number): number => {
  return minutes * 60000
}

export const hoursToMilliseconds = (hours: number): number => {
  return hours * 3600000
}

export const millisecondsToHours = (milliseconds: number): number => {
  return milliseconds / 3600000
}

export const getHours = (milliseconds: number) => {
  return milliseconds / (1000 * 60 * 60)
}

// export const getHoursAndMinutes = (milliseconds: number) => {
//   const hours = milliseconds / (1000 * 60 * 60)
//   const absoluteHours = Math.floor(hours)
//
//   const minutes = (hours - absoluteHours) * 60;
//
//   return {hours: absoluteHours, minutes}
// }
