import React from 'react'
import { Box, Typography } from '@material-ui/core'
import css from './css.module.scss'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import CancelLinkShared from '@/shared/link/CancelLinkShared'
import ButtonShared from '@/shared/button/ButtonShared'

interface InjectedProps {
  open: boolean
  onClose: () => void
  title: string
  confirmAction: () => void
}

const ConfirmationDialog: React.FC<InjectedProps> = ({ onClose, open, title, confirmAction }) => {
  return (
    <DefaultDialog onClose={onClose} open={open}>
      <Box className={css['wrapper']}>
        <Typography className={css['wrapper__title']} variant={'h1'}>
          {title}
        </Typography>
        <Box className={css['wrapper__controls']}>
          <CancelLinkShared onClick={onClose} />
          <ButtonShared color={'primary'} onClick={confirmAction} text={'Yes'} />
        </Box>
      </Box>
    </DefaultDialog>
  )
}

export default ConfirmationDialog
