import React, { useCallback } from 'react'
import classNames from 'classnames'
import css from './css.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import arrowCalendar from '../../assets/icons/arrow-calendar.svg'
import { toggleSidebarCollapsedState } from '@/store/sidebar/sidebar.actions'

export const CollapseIcon: React.FC = () => {
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)

  const dispatch = useDispatch()

  const collapseSidebar = useCallback(() => {
    dispatch(toggleSidebarCollapsedState())
  }, [dispatch])

  return (
    <img
      className={classNames(css['collapse-icon'], {
        [css['collapse-icon--collapsed']]: isSidebarCollapsed,
      })}
      src={arrowCalendar}
      onClick={collapseSidebar}
      alt={'Collapse button'}
    />
  )
}
