import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { AddEmployeeModel } from '@/models/request-body-models/add-employee'
import { EmployeeModel } from '@/models/responces/employee.model'

export enum ActionNames {
  ADD_EMPLOYEE = '[Employee] Add Employee',
  ADD_EMPLOYEE_SUCCESS = '[Employee] Add Employee Success',
  ADD_EMPLOYEE_FAILED = '[Employee] Add Employee Failed',

  EDIT_EMPLOYEE = '[Employee] Edit Employee',
  EDIT_EMPLOYEE_SUCCESS = '[Employee] Edit Employee Success',
  EDIT_EMPLOYEE_FAILED = '[Employee] Edit Employee Failed',

  DELETE_EMPLOYEE = '[Employee] Delete Employee',
  DELETE_EMPLOYEE_SUCCESS = '[Employee] Delete Employee Success',
  DELETE_EMPLOYEE_FAILED = '[Employee] Delete Employee Failed',

  SET_EMPLOYEES = '[Employee] Set Employees',

  SET_EDITABLE_EMPLOYEE = '[Employee] Set Editable Employee',

  RESET_LOADING = '[Employee] Reset Loading',
}

export const addEmployeeAsync = createAsyncAction(
  ActionNames.ADD_EMPLOYEE,
  ActionNames.ADD_EMPLOYEE_SUCCESS,
  ActionNames.ADD_EMPLOYEE_FAILED
)<AddEmployeeModel, undefined, Error>()

export const editEmployeeAsync = createAsyncAction(
  ActionNames.EDIT_EMPLOYEE,
  ActionNames.EDIT_EMPLOYEE_SUCCESS,
  ActionNames.EDIT_EMPLOYEE_FAILED
)<AddEmployeeModel, undefined, Error>()

export const deleteEmployeeAsync = createAsyncAction(
  ActionNames.DELETE_EMPLOYEE,
  ActionNames.DELETE_EMPLOYEE_SUCCESS,
  ActionNames.DELETE_EMPLOYEE_FAILED
)<undefined, undefined, Error>()

export const setEmployees = createAction(ActionNames.SET_EMPLOYEES)<EmployeeModel[]>()
export const setEditableEmployee = createAction(ActionNames.SET_EDITABLE_EMPLOYEE)<EmployeeModel | null>()

export const resetLoading = createAction(ActionNames.RESET_LOADING)()

export type TEmployeeActions = ActionType<
  | typeof addEmployeeAsync
  | typeof setEmployees
  | typeof setEditableEmployee
  | typeof editEmployeeAsync
  | typeof deleteEmployeeAsync
  | typeof resetLoading
>
