import { Invitation } from '@/models/responces/invitation.model'
import { ActionType, createAction } from 'typesafe-actions'

export enum ActionNames {
  SET_USER_INVITATIONS = '[Invitation] Set User Invitations',
  SET_WORKSPACE_INVITATION = '[Invitation] Set Workspace Invitations',
}

export const setUserInvitations = createAction(ActionNames.SET_USER_INVITATIONS)<Invitation[]>()
export const setWorkspaceInvitations = createAction(ActionNames.SET_WORKSPACE_INVITATION)<Invitation[]>()

export type TInvitationActions = ActionType<typeof setUserInvitations | typeof setWorkspaceInvitations>
