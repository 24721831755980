import moment, { Moment } from 'moment'
import { countWeekends, isSaturdayMidnight, isWeekendDay, isWeekendIncluded } from '@/utils/time-helper'
import { DatePeriodModel } from '@/models/responces/date-period.model'
import * as uuid from 'uuid'
import { TaskModel } from '@/models/responces/task.model'
import { ItemModel } from '@/models/calendar.model'
import { roundCalendarTime, roundDurationTime } from '@/utils/transform-time'
import { StageModel } from '@/models/responces/stage.model'

const business = require('moment-business-time')

export const GetValidNewTime = (start: Moment): number => {
  if (isWeekend(start)) {
    return moment(business(moment(start)).nextWorkingDay())
      .startOf('day')
      .valueOf()
  }
  return roundCalendarTime(start.valueOf()).valueOf()
}

export const getStageHoursToDisplay = (start: number, end: number) => {
  const weekendDays = countWeekends(start, end)
  const initialDuration = (end.valueOf() - start.valueOf()) / 3
  if (isSaturdayMidnight(end, moment(end).day())) {
    return initialDuration - (weekendDays + 1) * 8 * MILLISECONDS_IN_HOUR
  }
  return initialDuration - weekendDays * 8 * MILLISECONDS_IN_HOUR
}

export const getTaskCenterDate = (stages: StageModel[]) => {
  const taskStartDate = stages[0].date[0].startDate
  const taskEndDate = stages[stages.length - 1].date[0].endDate

  return taskStartDate + (taskEndDate - taskStartDate) / 2
}

export const isWeekend = (date: Moment) => {
  return !business(date).isWorkingDay() && !isSaturdayMidnight
}

export const nextWorkingDay = (date: Moment) => {
  return business(date).nextWorkingDay().startOf('day')
}

export const MILLISECONDS_IN_HOUR = 3600000
const MILLISECONDS_IN_TWO_DAYS = MILLISECONDS_IN_HOUR * 48

export const GetValidTime = (
  start: number,
  end: number,
  durationDefault?: number,
  shouldAddWeekends?: boolean
): DatePeriodModel[] => {
  const validStartTime = roundCalendarTime(start).valueOf()
  const validEndTime = roundCalendarTime(end).valueOf()

  const isStartOnWeekend = isWeekendDay(moment(validStartTime).day())
  const duration = roundDurationTime(
    durationDefault
      ? durationDefault
      : isWeekendIncluded(validStartTime, validEndTime)
      ? validEndTime - validStartTime - MILLISECONDS_IN_TWO_DAYS
      : validEndTime - validStartTime
  )

  if (isStartOnWeekend) {
    const startOfMonday = moment(validStartTime).startOf('isoWeek').add(1, 'week').startOf('day').valueOf()
    let endDate = startOfMonday + duration

    if (shouldAddWeekends) {
      endDate = addWeekends(startOfMonday, endDate)
    }

    return [{ id: uuid.v4(), startDate: startOfMonday, endDate }]
  }

  if (shouldAddWeekends) {
    const endTimeWithWeekends = addWeekends(validStartTime, validEndTime)
    return [{ id: uuid.v4(), startDate: validStartTime, endDate: endTimeWithWeekends }]
  }

  return [{ id: uuid.v4(), startDate: validStartTime, endDate: validEndTime }]
}

const addWeekends = (start: number, end: number): number => {
  const initialWeekends = countWeekends(start, end)
  let result = end + initialWeekends * MILLISECONDS_IN_HOUR * 24

  if (isWeekendDay(moment(result).day())) {
    result += MILLISECONDS_IN_TWO_DAYS
  }

  return countWeekends(start, result)
}

//TODO
// use REDUCE
export const getDuration = (date: DatePeriodModel[]): number => {
  let duration = 0
  date.forEach(date => {
    duration += date.endDate - date.startDate
  })
  return duration
}

export const getDifferenceBetweenDates = (start: number, end: number) => {
  business.locale('en', {
    workinghours: {
      0: null,
      1: ['00:00:00', '23:59:59'],
      2: ['00:00:00', '23:59:59'],
      3: ['00:00:00', '23:59:59'],
      4: ['00:00:00', '23:59:59'],
      5: ['00:00:00', '23:59:59'],
      6: null,
    },
  })
  return business(start).workingDiff(business(end))
}

export const canMoveStage = (taskToUpdate: TaskModel, stageIndex: number, difference: number) => {
  const newStartTime = taskToUpdate.process.stages[stageIndex].date[0].startDate + difference
  const lastStage = taskToUpdate.process.stages[stageIndex - 1]
  const lastStageDates = lastStage.date
  const lastStageEndTime = lastStageDates[lastStageDates.length - 1].endDate
  const lastStageStartTime = lastStageDates[lastStageDates.length - 1].startDate

  if (!lastStage.isActive) {
    return newStartTime >= lastStageStartTime
  }

  return newStartTime >= lastStageEndTime
}

export const isTaskLongerThanHour = (item: ItemModel, edge: string, newTime: number) => {
  const newTimePeriod = {
    start: edge === 'left' ? newTime : item.start_time,
    end: edge === 'left' ? item.end_time : newTime,
  }
  const newDuration = newTimePeriod.end.valueOf() - newTimePeriod.start.valueOf()
  return newDuration >= MILLISECONDS_IN_HOUR
}

export const recoordinateStage = (
  stage: StageModel,
  stagesToSave: StageModel[],
  allStages: StageModel[],
  startTime: number,
  actionStageIndex?: number,
  actionType?: 'move' | 'resize',
  difference?: number,
  resizeEdge?: 'left' | 'right'
): StageModel[] => {
  switch (actionType) {
    case 'resize': {
      const roundedStartTime = roundCalendarTime(startTime).valueOf()
      const validStageStart = calculateRenderStart(roundedStartTime, stage.isActive)
      let initialStageEnd = stage.date[0].endDate

      if (resizeEdge === 'right' && difference) {
        initialStageEnd += difference
      }

      const weekendHours = calculateWeekendHours(validStageStart, initialStageEnd)
      stage.workHours = calculateWorkHours(validStageStart, initialStageEnd)
      const validDate = [{ id: uuid.v4(), startDate: validStageStart, endDate: initialStageEnd }]
      const currentStageIndex = allStages.findIndex(s => s.id === stage.id)
      const elementToPush = { ...stage, weekendHours, date: validDate }

      stagesToSave.push(elementToPush)

      const nextElement = allStages[currentStageIndex + 1]
      const previousElement = allStages[currentStageIndex - 1]

      if (actionStageIndex && currentStageIndex === actionStageIndex && previousElement && !previousElement.isActive) {
        const stageToRestretch = restretchStage(previousElement, elementToPush)
        stagesToSave[currentStageIndex - 1] = stageToRestretch
      }

      if (nextElement) {
        return recoordinateStage(nextElement, stagesToSave, allStages, initialStageEnd, actionStageIndex)
      }

      return stagesToSave
    }

    default: {
      const roundedStartTime = roundCalendarTime(startTime).valueOf()
      const validStageStart = calculateRenderStart(roundedStartTime, stage.isActive)
      const initialStageEnd = validStageStart + stage.workHours * 3
      const weekendHours = calculateWeekendHours(validStageStart, initialStageEnd)
      const currentStageIndex = allStages.findIndex(s => s.id === stage.id)
      const validStageEnd = calculateRenderEnd(validStageStart, stage.workHours, weekendHours)
      const validDate = [{ id: uuid.v4(), startDate: validStageStart, endDate: validStageEnd }]
      const elementToPush = { ...stage, weekendHours, date: validDate }

      stagesToSave.push(elementToPush)

      const nextElement = allStages[currentStageIndex + 1]
      const previousElement = allStages[currentStageIndex - 1]

      if (actionStageIndex && currentStageIndex === actionStageIndex && previousElement && !previousElement.isActive) {
        const stageToRestretch = restretchStage(previousElement, elementToPush)
        stagesToSave[currentStageIndex - 1] = stageToRestretch
      }

      if (nextElement) {
        return recoordinateStage(nextElement, stagesToSave, allStages, validStageEnd)
      }

      return stagesToSave
    }
  }
}

export const restretchStage = (stage: StageModel, nextStage: StageModel): StageModel => {
  const newDate = [{ startDate: stage.date[0].startDate, endDate: nextStage.date[0].startDate, id: stage.date[0].id }]
  const workHours = calculateWorkHours(newDate[0].startDate, newDate[0].endDate)

  return { ...stage, date: newDate, workHours }
}

export const calculateRenderStart = (stageStart: number, isActive?: boolean) => {
  const isOnWeekend = isWeekendDay(moment(stageStart).day())

  if (isOnWeekend && isActive) {
    const startOfMonday = moment(stageStart).startOf('isoWeek').add(1, 'week').startOf('day').valueOf()

    return startOfMonday
  }

  return stageStart
}

export const calculateRenderEnd = (stageStart: number, workHours: number, weekendHours: number) => {
  const roundedWorkHours = workHours * 3

  if (weekendHours > 0) {
    return stageStart + roundedWorkHours + weekendHours
  }

  return stageStart + roundedWorkHours + weekendHours
}

export const calculateWeekendHours = (start: number, end: number) => {
  const HOURS_IN_DAY = 24
  return addWeekends(start, end) * MILLISECONDS_IN_HOUR * HOURS_IN_DAY
}

export const calculateWorkHours = (start: number, end: number) => {
  var weekendDayCount = 0
  let fromDate = new Date(start)
  let toDate = new Date(end)
  const HOURS_IN_DAY = 24

  while (fromDate < toDate) {
    if (fromDate.getDay() === 0 || fromDate.getDay() === 6) {
      ++weekendDayCount
    }
    fromDate.setDate(fromDate.getDate() + 1)
  }

  const weekends = weekendDayCount * MILLISECONDS_IN_HOUR * HOURS_IN_DAY

  return (end - start - weekends) / 3
}
