import { AddTaskModel, TaskModel } from '@/models/responces/task.model'
import { StageModel } from '@/models/responces/stage.model'

export const getTaskToSave = (taskId: string, task: AddTaskModel, projectId: string): TaskModel | null => {
  if (task.process) {
    const stagesToSave: StageModel[] = task.process.stages.map(s => {
      return {
        ...s,
        workHours: Number(s.workHours),
      }
    })
    return {
      ...task,
      id: taskId,
      name: task.name,
      projectId,
      process: {...task.process, stages: stagesToSave},
      color: task.color,
    }
  } else return null
}