import React, { useCallback, useMemo, useState } from 'react'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import TaskThunk from '@/store/task/task.thunk'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { TaskToAddStageTo } from './Calendar'
import InputShared from '@/shared/text-input/InputShared'
import InputTimeShared from '@/shared/text-input/InputTimeShared'
import css from './css.module.scss'
import ButtonShared from '@/shared/button/ButtonShared'
import { millisecondsToHours } from '@/utils/transform-time'
import { StageModel } from '@/models/responces/stage.model'
import * as uuid from 'uuid'
import { RootStateModel } from '@/store/root-reducer'
import { UserModel } from '@/models/responces/user.model'
import { calculateWeekendHours, calculateWorkHours, recoordinateStage } from '@/utils/helpers/get-valid-task-time'

interface InjectedProps {
  taskToAddData: TaskToAddStageTo
  open: boolean
  onClose: () => void
}

export const AddStageDialog: React.FC<InjectedProps> = ({ taskToAddData, open, onClose }) => {
  const [stageTitle, setStageTitle] = useState('')
  const [stageDuration, setStageDuration] = useState<number | string>(0)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const dispatch = useDispatch()

  const addStage = useCallback(() => {
    const task = _.cloneDeep(taskToAddData.taskCopy)
    const stageIndex = task.process.stages.findIndex(s => s.id === taskToAddData.stageIdToAddNextTo)

    if (stageIndex === -1 || !user) {
      onClose()
      return
    }

    const stage = task.process.stages[stageIndex]
    const stageToAddStartDate = stage.date[0].endDate
    const stageToAddEndDate = stageToAddStartDate + Number(stageDuration)

    const stageToAdd: StageModel = {
      id: uuid.v4(),
      name: stageTitle,
      isActive: true,
      workHours: calculateWorkHours(stageToAddStartDate, stageToAddEndDate) * 3,
      weekendHours: calculateWeekendHours(stageToAddStartDate, stageToAddEndDate),
      date: [{ id: uuid.v4(), startDate: stageToAddStartDate, endDate: stageToAddEndDate }],
      authorUid: user.id,
    }

    const inactiveStageToAdd: StageModel = {
      id: uuid.v4(),
      name: 'Inactive Stage',
      isActive: false,
      workHours: 0,
      weekendHours: 0,
      date: [{ id: uuid.v4(), startDate: stageToAddStartDate, endDate: stageToAddStartDate }],
      authorUid: user.id,
    }

    const newStages = [inactiveStageToAdd, stageToAdd]

    const stagesToAdd = [...task.process.stages.slice(0, stageIndex + 1), ...newStages, ...task.process.stages.slice(stageIndex + 1)]

    const recoordinatedStages = recoordinateStage(stagesToAdd[0], [], stagesToAdd, stagesToAdd[0].date[0].startDate)

    dispatch(TaskThunk.editTask({ ...task, process: { ...task.process, stages: recoordinatedStages } }))

    onClose()
  }, [stageTitle, dispatch, onClose, stageDuration, taskToAddData, user])

  const isValid = useMemo(() => {
    let valid = true

    if (!stageTitle || !Number(stageDuration)) {
      valid = false
    }

    return valid
  }, [stageTitle, stageDuration])

  const changeStageTitle = useCallback((value: string) => {
    setStageTitle(value)
  }, [])

  const changeStageDuration = useCallback((value: string) => {
    setStageDuration(value)
  }, [])

  return (
    <DefaultDialog open={open} onClose={onClose}>
      <div className={css['add-stage-dialog-wrapper']}>
        <InputShared onChange={changeStageTitle} value={stageTitle} label={'Stage Title'} />
        <InputTimeShared
          onChange={changeStageDuration}
          hours={millisecondsToHours(Number(stageDuration))}
        ></InputTimeShared>
        <ButtonShared onClick={addStage} disabled={!isValid} color={'primary'} text={'Add Stage'} />
      </div>
    </DefaultDialog>
  )
}
