import React from 'react'
import { Box, Typography } from '@material-ui/core'
import welcomeIcon from '@/assets/icons/welcome-icon.svg'
import css from './css.module.scss'
const AddProjectsWelcome = () => {
  return (
    <Box className={css['wrapper']}>
      <img src={welcomeIcon} alt="" />
      <Typography variant={'button'} className={css['wrapper__heading']}>
        Welcome <br />
        to WorkLoad Pro!
      </Typography>
      <Box className={css['wrapper__instructions-project']}>
        <Typography variant={'body2'} className={css['wrapper__instructions-project__text']}>
          You Don’t have any projects Yet.
        </Typography>
        <Box className={css['wrapper__instructions-project__text']}>
          <Typography variant={'body2'} className={css['wrapper__instructions-project__text']}>
            Please Click on
          </Typography>
          <Typography variant={'button'} className={css['wrapper__instructions-project__text__bold']}>
            “Create New Project”
          </Typography>
        </Box>
        <Typography variant={'body2'} className={css['wrapper__instructions-project__text']}>
          Button to Add Project
        </Typography>
      </Box>
    </Box>
  )
}

export default AddProjectsWelcome
