import { Invitation as IInvitation } from '@/models/responces/invitation.model'
import { RootStateModel } from '@/store/root-reducer'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import css from './css.module.scss'
import logo from '../../assets/icons/logo.svg'
import { Invitation } from '@/shared/invitation/Invitation'
import ButtonShared from '@/shared/button/ButtonShared'
import { ManageWorkspaceDialog, WorkspaceDialogModes } from '@/shared/header/ManageWorkspaceDialog'
import { UserModel } from '@/models/responces/user.model'
import InputShared from '@/shared/text-input/InputShared'
import UserTransactions from '@/store/user/user.transactions'
import { addSnackbar } from '@/store/snackbar/snackbar.actions'
import { SnackbarTypes } from '@/shared/snackbars/snackbar'

export const NoWorkspaces: React.FC = () => {
  const userInvitations = useSelector<RootStateModel, IInvitation[]>(state => state.invitation.userInvitations)
  const [createWorkspaceDialogOpen, setCreateWorkspaceDialogState] = useState(false)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const [userName, setUserName] = useState(user ? user.name : '')
  const [userSurname, setUserSurname] = useState(user ? user.surname : '')
  const dispatch = useDispatch()

  const changeUserName = useCallback((val: string) => {
    setUserName(val)
  }, [])

  const changeUserSurname = useCallback((val: string) => {
    setUserSurname(val)
  }, [])

  const onSave = useCallback(async () => {
    if (user) {
      try {
        const newUser: UserModel = { ...user, name: userName, surname: userSurname }
        await UserTransactions.editUser(newUser)
        dispatch(
          addSnackbar({
            snackbar: {
              text: '',
              type: SnackbarTypes.SUCCESS,
              title: 'Credentials have been updated',
            },
          })
        )
      } catch (err) {}
    }
  }, [userName, userSurname, user, dispatch])

  const renderUserCredentialsForm = useCallback(() => {
    if (user) {
      return (
        <div className={css['user-credentials']}>
          <p className={css['title']}>Please, enter your name</p>
          <InputShared label={'Name'} value={userName} onChange={changeUserName} />
          <InputShared label={'Surname'} value={userSurname} onChange={changeUserSurname} />
          <ButtonShared className={css['button']} color={'primary'} onClick={onSave} text={'Save'} />
        </div>
      )
    }

    return null
  }, [user, changeUserSurname, changeUserName, userName, userSurname, onSave])

  const toggleWorkspaceDialogState = useCallback(() => {
    setCreateWorkspaceDialogState(val => !val)
  }, [])

  return (
    <div className={css['no-workspaces']}>
      <img className={css['logo']} alt={'Logo'} src={logo} />
      {renderUserCredentialsForm()}
      <p className={css['title']}>Create a workspace to get started</p>
      <ButtonShared
        className={css['main-button']}
        onClick={toggleWorkspaceDialogState}
        color={'primary'}
        text={'Create Workspace'}
      />
      {userInvitations.length > 0 && <div className={css['title']}>You have invitations:</div>}
      <div className={css['invitations-container']}>
        {userInvitations.map(i => {
          return <Invitation key={i.id!} invitation={i} />
        })}
      </div>
      <ManageWorkspaceDialog
        open={createWorkspaceDialogOpen}
        onClose={toggleWorkspaceDialogState}
        mode={WorkspaceDialogModes.CREATE}
      />
    </div>
  )
}
