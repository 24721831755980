import infoProcess from '../../assets/icons/info-process-icon.svg'
import { EEntityAction } from '@/constants/entity-action'

export const DetailsInfo = [
  {
    id: EEntityAction.ADD,
    heading: 'Add Project Details',
    text: '',
    icon: infoProcess,
  },
  {
    id: EEntityAction.EDIT,
    heading: 'Edit Project Details',
    text: '',
    icon: infoProcess,
  },
]
