import { runTransaction } from '@/firebase/helpers'
import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { AddEmployeeModel } from '@/models/request-body-models/add-employee'
import { EmployeeModel } from '@/models/responces/employee.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'
import store from '@/store/store'

export default abstract class EmployeeTransactions {
  @HandleAsyncErrors()
  public static async AddEmployee(employee: AddEmployeeModel) {
    await runTransaction(async transaction => {
      const newEmployeeDocRef = FirebaseUsage.database().collection(COLLECTIONS.EMPLOYEES).doc()

      console.log('newEmployeeDocRef', newEmployeeDocRef)

      transaction.set<EmployeeModel>(newEmployeeDocRef, {
        ...employee,
        id: newEmployeeDocRef.id,
      })
    })
  }

  @HandleAsyncErrors()
  public static async CopyEmployee(employee: EmployeeModel) {
    await runTransaction(async transaction => {
      const newEmployeeDocRef = FirebaseUsage.database().collection(COLLECTIONS.EMPLOYEES).doc()
      transaction.set<EmployeeModel>(newEmployeeDocRef, employee)
    })
  }

  @HandleAsyncErrors()
  public static async EditEmployee(employee: EmployeeModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.EMPLOYEES).doc(employee.id).update(employee)
  }

  @HandleAsyncErrors()
  public static async SwitchEmployees(employeeOne: EmployeeModel, employeeTwo: EmployeeModel) {
    await FirebaseUsage.database()
      .collection(COLLECTIONS.EMPLOYEES)
      .doc(employeeOne.id)
      .update({ ...employeeOne, index: employeeTwo.index })
    await FirebaseUsage.database()
      .collection(COLLECTIONS.EMPLOYEES)
      .doc(employeeTwo.id)
      .update({ ...employeeTwo, index: employeeOne.index })
  }

  @HandleAsyncErrors()
  public static async ReorderEmployees(employee: EmployeeModel, newIndex: number) {
    const employeesList: EmployeeModel[] | null = store.getState().employee.employeesList

    const fromIndex = Math.min(newIndex, employee.index)
    const toIndex = Math.max(newIndex, employee.index)

    if (employeesList) {
      const employeesToUpdate = employeesList.filter(
        e => e.index >= fromIndex && e.index <= toIndex && e.id !== employee.id
      )

      const increment = newIndex > employee.index ? -1 : 1

      employeesToUpdate.forEach(async employee => {
        await FirebaseUsage.database()
          .collection(COLLECTIONS.EMPLOYEES)
          .doc(employee.id)
          .update({ ...employee, index: employee.index + increment })
      })

      await FirebaseUsage.database()
        .collection(COLLECTIONS.EMPLOYEES)
        .doc(employee.id)
        .update({ ...employee, index: newIndex })
    }
  }

  @HandleAsyncErrors()
  public static async DeleteEmployee(employeeId: string) {
    await FirebaseUsage.database().collection(COLLECTIONS.EMPLOYEES).doc(employeeId).delete()
  }
}
