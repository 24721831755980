import { ThunkResult } from '@/store/store.types'
import { addProjectAsync, deleteProjectAsync, editProjectAsync } from '@/store/project/project.actions'
import { AddProjectModel } from '@/models/request-body-models/add-project.model'
import ProjectTransactions from '@/store/project/project.transactions'
import { TaskModel } from '@/models/responces/task.model'
import { EditProjectModel } from '@/models/request-body-models/edit-projects.model'
import { Subscriptions, subsManager } from '@/store/middleware/subs-manager/subs-manager'
import store from '../store'

export default class ProjectThunk {
  static addProject(project: AddProjectModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(addProjectAsync.request())
        await ProjectTransactions.AddProject(project)
        dispatch(addProjectAsync.success())
      } catch (e) {
        dispatch(addProjectAsync.failure(e))
        console.error('Add Project Error: ', e)
      }
    }
  }

  static editProject(project: EditProjectModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(editProjectAsync.request())
        await ProjectTransactions.EditProject(project)
        dispatch(editProjectAsync.success())
      } catch (e) {
        dispatch(editProjectAsync.failure(e))
        console.error('Add Project Error: ', e)
      }
    }
  }

  static reorderProjects(currentIndex: number, newIndex: number): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await ProjectTransactions.ReorderProjects(currentIndex, newIndex)
      } catch (e) {
        dispatch(editProjectAsync.failure(e))
        console.error('Reorder Projects Error: ', e)
      }
    }
  }

  static deleteProject(id: string, tasks: TaskModel[]): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(deleteProjectAsync.request())
        const projectsListBeforeDeletion = store.getState().project.projectsList
        let deletedProjectIndex = 0
        if (projectsListBeforeDeletion && projectsListBeforeDeletion.length) {
          const projectToDelete = projectsListBeforeDeletion.find(p => p.id === id)
          if (projectToDelete) {
            deletedProjectIndex = projectToDelete.orderIndex
          }
        }
        await ProjectTransactions.DeleteProject(id, tasks)
        const projectsListAfterDeletion = store.getState().project.projectsList
        if (projectsListAfterDeletion && projectsListAfterDeletion.length) {
          await ProjectTransactions.ReorderProjects(deletedProjectIndex, projectsListAfterDeletion.length - 1, true)
        }
        dispatch(deleteProjectAsync.success(id))
        subsManager.removeSubscription(`${Subscriptions.TASKS}_${id}` as any)
      } catch (e) {
        dispatch(deleteProjectAsync.failure(e))
        console.error('Delete Project Error: ', e)
      }
    }
  }
}
