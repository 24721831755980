import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#17A67D',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#17A67D',
    },
  },
  spanRoot: {
    fontSize: '14px !important',
    fontFamily: "'ProximaNova Semibold', serif !important",
    marginLeft: '5px',
    marginTop: '1px',
  },
  formControlLabelRoot: {
    marginBottom: '15px !important',
  },
})

export const RadioButtonShared: React.FC<Partial<FormControlLabelProps>> = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      {...rest}
      label={children}
      classes={{
        label: classes.spanRoot,
        root: classes.formControlLabelRoot,
      }}
      control={
        <Radio
          className={classes.root}
          checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          disableRipple={false}
        />
      }
    />
  )
}
