import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { runTransaction } from '@/firebase/helpers'
import { TaskModel } from '@/models/responces/task.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'

export default abstract class TaskTransactions {
  @HandleAsyncErrors()
  public static async EditTask(task: TaskModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc(task.id).update(task)
  }

  @HandleAsyncErrors()
  public static async UnassignTask(task: TaskModel) {
    const newStages = task.process.stages.map(stage => ({
      ...stage,
      date: []
    }))

    await FirebaseUsage.database()
      .collection(COLLECTIONS.TASKS)
      .doc(task.id)
      .update({ ...task, stages: newStages, employeeId: null, centerDate: null })
  }

  @HandleAsyncErrors()
  public static async CreateTask(task: TaskModel) {
    await runTransaction(async transaction => {
      const newTaskRef = FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc()
      transaction.set(newTaskRef, {...task, id: newTaskRef.id})
    })
  }

  @HandleAsyncErrors()
  public static async DeleteTask(task: TaskModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc(task.id).delete()
  }
}
