import React, { useCallback } from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import { getValidHours, hoursToMilliseconds } from '@/utils/transform-time'
import InputShared from '@/shared/text-input/InputShared'

interface InjectedProps {
  hours: number
  onChange: (value: string) => void
}

const InputTimeShared: React.FC<InjectedProps> = props => {
  const { onChange, hours } = props
  const changeValueHours = useCallback(
    (value: string) => {
      onChange(hoursToMilliseconds(getValidHours(Number(value.replace(',', '')))).toString())
    },
    [onChange]
  )

  return (
    <Box className={css['time-form']}>
      <InputShared number value={hours.toString()} onChange={changeValueHours} label={'Hours'} />
    </Box>
  )
}

export default InputTimeShared
