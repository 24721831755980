import { applyMiddleware, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { rootReducer, RootStateModel, TRootState } from './root-reducer'
import { TAppActions } from '@/store/root-reducer.models'
import { combineMiddleware } from './middleware'
import { composeEnhancers } from '@/store/utils'

const configureStore = (initialState?: RootStateModel) => {
  const devMiddleware = [thunk as ThunkMiddleware<TRootState, TAppActions>]
  const enhancer = composeEnhancers(
    applyMiddleware(
      ...devMiddleware,
      ...combineMiddleware,
    )
  );

  return createStore(rootReducer, initialState, enhancer);
}
const store = configureStore()

export default store