import React from "react";
import { useEffect, useState } from "react";
type ImgProps = React.ComponentProps<"img">;
type imgSrc = string;
type imgBase64 = string;
type dataUrl = string;
const CACHE: Record<imgSrc, imgBase64> = {};

const toDataURL = (url: string): Promise<dataUrl> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              resolve(reader.result);
            }
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const CachedImg: React.FC<ImgProps> = ({ src, ...otherProps }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (!src) {
      return;
    }
    if (src in CACHE) {
      setUrl(CACHE[src]);
      return;
    }
    let isMounted = true;
    toDataURL(src).then((url) => {
      CACHE[src] = url;
      if (!isMounted) {
        return;
      }
      setUrl(url);
    });
    return () => {
      isMounted = false;
    };
  }, [src]);
  if (!url) {
    return null;
  }
  return <img src={url} alt={otherProps.alt || "Alt for image"} {...otherProps} />;
};