import { Dispatch, MiddlewareAPI } from 'redux'
import * as projectActions from './project.actions'
import { Subscriptions, subsManager } from '@/store/middleware/subs-manager/subs-manager'
import { RootStateModel } from '@/store/root-reducer'
import FirebaseUsage from '@/firebase/firebase.usage'
import { COLLECTIONS } from '@/firebase/constants'

export function projectMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
  return (next: Dispatch) => (action: projectActions.TProjectActions) => {
    const returnValue = next(action)
    switch (action.type) {
      case projectActions.ActionNames.SET_PROJECTS: {
        const projectsList = api.getState().project.projectsList
        subsManager.removeSubscription(Subscriptions.TASKS)
        if (projectsList) {
          projectsList.forEach(async (project, index, arr) => {
            if (!project.hasOwnProperty('orderIndex')) {
              await FirebaseUsage.database()
                .collection(COLLECTIONS.PROJECTS)
                .doc(project.id)
                .update({ ...project, orderIndex: index })
            }

            subsManager.subscribeProjectTasks(project)
          })

          
        }
        break
      }

      default: {
        break
      }
    }

    return returnValue
  }
}
