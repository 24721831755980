import AuthorizationApi from '../../api/authorization.api'
import { ThunkResult } from '../store.types'
import {
  login,
  loginFailure,
  loginSuccess,
  logOut,
  signup,
  signupFailure,
  signupSuccess,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from '@/store/authorization/authorization.actions'
import { UserModel } from '@/models/responces/user.model'
import AuthorizationTransactions from '@/store/authorization/authorization.transactions'
import { subsManager } from '../middleware/subs-manager/subs-manager'

export default class AuthorizationThunk {
  static auth(user: Pick<UserModel, 'email' | 'id'>): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        subsManager.subscribeUser(user.id)
        await AuthorizationTransactions.AddUser(user)
      } catch (e) {
        console.error('Add User And Workspace Error: ', e)
      }
    }
  }

  static signInWithEmailAndPassword(email: string, password: string): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(login())
        await AuthorizationApi.signInWithEmailAndPassword(email, password)
        dispatch(loginSuccess())
      } catch (err) {
        dispatch(loginFailure())
        console.log('sign in with email and password error')
      }
    }
  }

  static createUserWithEmailAndPassword(email: string, password: string): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(signup())
        await AuthorizationApi.createUserWithEmailAndPassword(email, password)
        dispatch(signupSuccess())
      } catch (err) {
        dispatch(signupFailure())
        console.log('create user with email and password error')
      }
    }
  }

  static restorePassword(email: string): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(forgotPassword())
        await AuthorizationApi.restorePassword(email)
        dispatch(forgotPasswordSuccess())
      } catch (err) {
        dispatch(forgotPasswordFailure())
        console.log('restore password error')
      }
    }
  }

  static logOut(): ThunkResult<Promise<void>> {
    return async dispatch => {
      dispatch(logOut())
      await AuthorizationApi.logOut()
      localStorage.clear()
    }
  }
}
