import { ActionNames, addProjectAsync, deleteProjectAsync, editProjectAsync, TProjectActions } from './project.actions'
import { getType } from 'typesafe-actions'
import { RequestState } from '@/constants/request-state'
import { ProjectModel } from '@/models/responces/project.model'
import { TaskModelMap } from '@/models/responces/task.model'

export class StateModel {
  projectsList: ProjectModel[] | null = null
  tasksMap: TaskModelMap | null = {}
  assignedTasksMap: TaskModelMap | null = {}
  editableProject: ProjectModel | null = null
  loadingDeleteProject: RequestState = RequestState.UNSENT
  loadingAddProject: RequestState = RequestState.UNSENT
  loadingEditProject: RequestState = RequestState.UNSENT
  isLoadingProjects: boolean = true
}

export const initialState = new StateModel()

export function ProjectReducer(state = initialState, action: TProjectActions): StateModel {
  switch (action.type) {
    case ActionNames.RESET_LOADING: {
      return {
        ...state,
        loadingAddProject: RequestState.UNSENT,
        loadingEditProject: RequestState.UNSENT,
        loadingDeleteProject: RequestState.UNSENT,
      }
    }
    case getType(addProjectAsync.request): {
      return { ...state, loadingAddProject: RequestState.SENDING }
    }
    case getType(addProjectAsync.success): {
      return { ...state, loadingAddProject: RequestState.SENT_SUCCESS }
    }
    case getType(addProjectAsync.failure): {
      return { ...state, loadingAddProject: RequestState.SENT_FAILED }
    }
    case ActionNames.SET_PROJECTS: {
      return { ...state, projectsList: action.payload, isLoadingProjects: false }
    }
    case ActionNames.SET_UNASSIGNED_TASKS: {
      const tasksMap = {
        ...state.tasksMap,
        [action.payload.projectId]: action.payload.taskList,
      }
      return { ...state, tasksMap }
    }
    case ActionNames.SET_ASSIGNED_TASKS: {
      const tasksMap = {
        ...state.assignedTasksMap,
        [action.payload.projectId]: action.payload.taskList,
      }

      return { ...state, assignedTasksMap: tasksMap }
    }
    case getType(deleteProjectAsync.request): {
      return { ...state, loadingDeleteProject: RequestState.SENDING }
    }
    case getType(deleteProjectAsync.success): {
      const newTasksMap: TaskModelMap = { ...state.tasksMap }
      if (newTasksMap.hasOwnProperty(action.payload)) {
        delete newTasksMap[action.payload]
      }
      return { ...state, loadingDeleteProject: RequestState.SENT_SUCCESS, tasksMap: newTasksMap }
    }
    case getType(deleteProjectAsync.failure): {
      return { ...state, loadingDeleteProject: RequestState.SENT_FAILED }
    }
    case ActionNames.SET_EDITABLE_PROJECT: {
      return { ...state, editableProject: action.payload }
    }
    case getType(editProjectAsync.request): {
      return { ...state, loadingEditProject: RequestState.SENDING }
    }
    case getType(editProjectAsync.success): {
      return { ...state, loadingEditProject: RequestState.SENT_SUCCESS, editableProject: null }
    }
    case getType(editProjectAsync.failure): {
      return { ...state, loadingEditProject: RequestState.SENT_FAILED, editableProject: null }
    }
    default:
      return state
  }
}

export const ProjectSelectors = {
  tasksListLoaded: (state: StateModel) =>
    !state.isLoadingProjects
}
