import { RouteProps } from 'react-router'
import unauthRequired from './utils/unauthRequired'
import Login from '@/pages/login/login'
import NotFound from '@/pages/not-found'
import RouteNameConstants from '@/constants/route-name.constants'
import Workspace from '@/pages/workspace/workspace'
import authRequired from '@/utils/authRequired'
import Home from '@/pages/home'
import { Profile } from './pages/profile/Profile'

const routingConstants: RouteProps[] = [
  {
    path: `/`,
    component: unauthRequired(Home),
    exact: true,
  },
  {
    path: `/${RouteNameConstants.LOGIN}`,
    component: unauthRequired(Login),
    exact: true,
  },
  {
    path: `/${RouteNameConstants.WORKSPACES}`,
    component: authRequired(Workspace),
    exact: true,
  },
  {
    path: `/${RouteNameConstants.WORKSPACES}/:workspaceId`,
    component: authRequired(Workspace),
    exact: true
  },
  {
    path: `/${RouteNameConstants.PROFILE}`,
    component: authRequired(Profile),
    exact: true,
  },
  {
    component: NotFound,
  },
]

export default routingConstants
