export enum MemberRoles {
  OWNER = 'owner',
  VIEWER = 'viewer',
}

export interface Member {
  userId: string
  role: MemberRoles
  userEmail: string
}

export interface WorkspaceModel {
  readonly id: string
  ownerId: string
  members: Member[]
  name: string
}
