import { ActionNames, TTimeoffActions, addTimeoffAsync } from './timeoff.actions'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { RequestState } from '@/constants/request-state'
import { getType } from 'typesafe-actions'

export class StateModel {
  timeoffsList: TimeoffModel[] = []
  loadingAddTimeoff: RequestState = RequestState.UNSENT
}

export const initialState = new StateModel()

export function TimeoffReducer(state = initialState, action: TTimeoffActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_TIMEOFFS: {
      return { ...state, timeoffsList: action.payload }
    }
    case getType(addTimeoffAsync.request): {
      return {
        ...state,
        loadingAddTimeoff: RequestState.SENDING,
      }
    }
    case getType(addTimeoffAsync.success): {
      return {
        ...state,
        loadingAddTimeoff: RequestState.SENT_SUCCESS,
      }
    }
    case getType(addTimeoffAsync.failure): {
      return {
        ...state,
        loadingAddTimeoff: RequestState.SENT_FAILED,
      }
    }
    default:
      return state
  }
}
