import React, { useCallback, useMemo } from 'react'
import { Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { ProcessModel } from '@/models/responces/process.model'
import css from './css.module.scss'
import ColorBoxShared from '@/shared/color-box/ColorBoxShared'
import { useDispatch } from 'react-redux'
import { setEditableProcess } from '@/store/process/process.actions'
import deleteIcon from '@/assets/icons/delete-icon.svg'

interface InjectedProps {
  process: ProcessModel
  isEditable: boolean
  deleteClick: (id: string) => void
}

const ProcessesListItem: React.FC<InjectedProps> = ({ process, isEditable, deleteClick }) => {

  const dispatch = useDispatch()

  const edit = useCallback(() => {
    dispatch(setEditableProcess(process))
  }, [process, dispatch])

  const getClassName = useMemo(() => {
    return isEditable ? 'editable' : 'default'
  }, [isEditable])

  const onDeleteClick = useCallback(()=>{
    deleteClick(process.id)
  },[process, deleteClick])

  return (
    <>
      <ListItem className={css[getClassName]} button onClick={edit}>
        <Box className={css['color-box']}>
          <ColorBoxShared color={process.color} />
        </Box>

        <ListItemText primary={<Typography variant={'button'}>{process.name}</Typography>} />
        <ListItemSecondaryAction>
          <IconButton onClick={onDeleteClick} className={css['delete-icon']}>
            <img src={deleteIcon} alt="" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}

export default ProcessesListItem
