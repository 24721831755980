import React from 'react'
import { Typography } from '@material-ui/core'
import css from './css.module.scss'
import classNames from 'classnames'

interface InjectedProps {
  onClick?: () => void
  className?: string
}

const NavLink: React.FC<InjectedProps> = ({ children, onClick, className }) => {
  return (
    <Typography variant={'body2'} className={classNames(css['text'], className)} onClick={onClick}>
      {children}
    </Typography>
  )
}

export default NavLink
