import React, { useCallback, useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useSelector } from 'react-redux'
import { getSumOffset, getSumScroll } from '../../calendar/lib/dom-helpers'
import css from './css.module.scss'
import { RootStateModel } from '@/store/root-reducer'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'

interface InjectedProps {
  index: number
  handleDrop: (groupKey: number) => void
  onClick: () => void
  position: any
}

const BORDER_DEFAULT = '1px solid #f0f1f2'
const BORDER_ACTIVE = '2px solid #98CA55'

const DraggableSharedEmployee: React.FC<InjectedProps> = props => {
  const { children, handleDrop, onClick } = props

  const [isDragging, setIsDragging] = useState(false)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)

  const scrollRef = useSelector<RootStateModel, any>(state => state.calendar.scrollRef)
  const timelineRef = useSelector<RootStateModel, any>(state => state.calendar.timelineRef)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const onDragStartStyle = useCallback(() => {
    if (sidebarRef.current) {
      sidebarRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.08)'
    }
  }, [sidebarRef])

  const onDragEndStyle = useCallback(() => {
    if (sidebarRef.current) {
      sidebarRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0)'
    }
  }, [sidebarRef])

  useEffect(() => {
    const sidebar = document.getElementsByClassName('rct-sidebar')[0]
    if (sidebar) {
      // @ts-ignore
      sidebarRef.current = sidebar
    }
  }, [])

  const getGroupKey = useCallback(
    (pageY: number) => {
      if (timelineRef && scrollRef) {
        const { groupTops } = timelineRef.current.state

        const { offsetTop } = getSumOffset(scrollRef.current)
        const { scrollTop } = getSumScroll(scrollRef.current)

        const y = pageY - offsetTop + scrollTop

        let groupKey = ''
        for (const key of Object.keys(groupTops)) {
          const groupTop = groupTops[key]
          if (y > groupTop) {
            groupKey = key
          } else {
            break
          }
        }
        return groupKey
      }
      return null
    },
    [timelineRef, scrollRef]
  )

  const removeBorderStyle = useCallback(
    (groupKey: number) => {
      if (sidebarRef.current) {
        const employeesCollection = sidebarRef.current.children[0].children
        if (employeesCollection && employeesCollection[groupKey]) {
          // @ts-ignore
          employeesCollection[groupKey].style.borderBottom = BORDER_DEFAULT
        }
      }
    },
    [sidebarRef]
  )

  const handleStop = useCallback(
    (e: any) => {
      if (!isDragging) {
        onClick()
        return
      }
      setIsDragging(false)
      onDragEndStyle()
      const { pageY } = e
      const groupKey = getGroupKey(pageY)
      if (groupKey) {
        handleDrop(Number(groupKey))
      }
      removeBorderStyle(Number(groupKey))
    },
    [handleDrop, isDragging, onClick, onDragEndStyle, getGroupKey, removeBorderStyle]
  )

  // TODO add bottom position calculation to define bottom bound
  // const offset = useMemo(() => {
  //   return scrollRef && scrollRef.current && getSumOffset(scrollRef.current)
  // }, [scrollRef])

  const setDraggedOverBlocksStyle = useCallback(
    e => {
      const { pageY } = e
      const groupKey = Number(getGroupKey(pageY))
      if (sidebarRef.current) {
        const employeesCollection = sidebarRef.current.children[0].children
        if (employeesCollection) {
          for (let i = 0; i < employeesCollection.length; i++) {
            // @ts-ignore
            employeesCollection[i].style.borderBottom = i === groupKey ? BORDER_ACTIVE : BORDER_DEFAULT
          }
        }
      }
    },
    [getGroupKey, sidebarRef]
  )
  const handleStart = useCallback(
    e => {
      onDragStartStyle()
      setIsDragging(true)
      setDraggedOverBlocksStyle(e)
    },
    [onDragStartStyle, setDraggedOverBlocksStyle]
  )

  return (
    <Draggable
      axis="y"
      handle=".handle"
      bounds={'div'}
      position={{ x: 0, y: 0 }}
      scale={1}
      onDrag={handleStart}
      onStop={handleStop}
      defaultClassNameDragging={css['dragging']}
      defaultClassName={css[isDragging ? '' : 'root']}
      defaultClassNameDragged={css['dragged']}
      disabled={!isRoleAllowed}
    >
      <div className="handle" style={{ zIndex: 100, height: '100%' }}>
        {children}
      </div>
    </Draggable>
  )
}

export default DraggableSharedEmployee
