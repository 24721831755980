import React, { useCallback, useState, useMemo } from 'react'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import css from './css.module.scss'
import { Box, FormControl, RadioGroup } from '@material-ui/core'
import InputShared from '@/shared/text-input/InputShared'
import InputTimeShared from '@/shared/text-input/InputTimeShared'
import { getHours } from '@/utils/transform-time'
import ButtonShared from '@/shared/button/ButtonShared'
import Autocomplete from '@/shared/autocomplete/AutocompleteShared'
import { DropdownActionModel } from '@/models/dropdown-action.model'
import { ProcessModel } from '@/models/responces/process.model'
import { RootStateModel } from '@/store/root-reducer'
import { useSelector } from 'react-redux'
import TaskTemplate, { FormFields } from '../project/components/task/TaskTemplate'
import { AddTaskModel, DefaultTask, TaskModel } from '@/models/responces/task.model'
import { ProjectModel } from '@/models/responces/project.model'
import { RadioButtonShared } from '@/shared/radio-button/RadioButtonShared'
import infoProcess from '../../assets/icons/info-process-icon.svg'
import CreateProjectDialog from '../project/CreateProjectDialog'

const TIMEOFF_COLOR = '#EF7070'

interface InjectedProps {
  open: boolean
  onClose: () => void
  onSave: (
    duration: number,
    description: string,
    title: TimeoffTypes,
    color: string,
    task: TaskModel | null,
    project: ProjectModel | null,
    mode: Modes
  ) => void
}

export enum Modes {
  TASK = 'task',
  TIMEOFF = 'timeoff',
}

export enum TimeoffTypes {
  SICK_LEAVE = 'Sick leave',
  HOLIDAY = 'Holiday',
  PAID_TIMEOFF = 'Paid time off',
}

export const AddItemDialog: React.FC<InjectedProps> = ({ open, onClose, onSave }) => {
  const [durationHours, setDurationHours] = useState(0)
  const [title, setTitle] = useState<TimeoffTypes | null>(null)
  const [description, setDescription] = useState('')
  const [mode, setMode] = useState<Modes>(Modes.TIMEOFF)
  const [task, setTask] = useState<AddTaskModel>(DefaultTask)
  const [project, setProject] = useState<ProjectModel | null>(null)
  const processesList = useSelector<RootStateModel, ProcessModel[] | null>(state => state.process.processesList)
  const projects = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)
  const [isAddProjectDialogShown, setAddProjectDialogState] = useState(false)

  const toggleAddProjectDialogState = useCallback(() => {
    setAddProjectDialogState(val => !val)
  }, [])

  const options = useMemo(() => {
    const actions: DropdownActionModel[] = [
      {
        name: 'Add New Project',
        action: toggleAddProjectDialogState,
      },
    ]

    if (!projects) return [...actions]

    return [...projects, ...actions]
  }, [projects, toggleAddProjectDialogState])

  console.log('isAddProjectDialogShown')
  console.log(options)

  const changeMode = useCallback((value: DropdownActionModel | ProcessModel | ProjectModel | null) => {
    value && setMode((value as DropdownActionModel).name.toLowerCase() as Modes)
  }, [])

  const isFormValid = useMemo(() => {
    let valid = true

    if (mode === Modes.TIMEOFF) {
      if (!title || !description || !Number.isInteger(durationHours) || durationHours === 0) {
        valid = false
      }
    }

    if (mode === Modes.TASK) {
      if (!task || !project || !task.name || !task.process) {
        valid = false
      }
    }

    return valid
  }, [title, description, durationHours, mode, project, task])

  const modeOptions = useMemo(() => {
    return [
      {
        name: 'Task',
        action: () => setMode(Modes.TASK),
      },
      {
        name: 'Timeoff',
        action: () => setMode(Modes.TIMEOFF),
      },
    ]
  }, [])

  const chosenModeOption = useMemo(() => {
    return modeOptions.find(o => o.name.toLowerCase() === mode) || null
  }, [mode, modeOptions])

  const changeTitle = useCallback(
    (_e: any, title: string) => {
      setTitle(title as TimeoffTypes)
    },
    [setTitle]
  )

  const changeTask = useCallback((id: string, field: FormFields, value: string | ProcessModel | null) => {
    setTask(task => {
      return { ...task, [field]: value }
    })
  }, [])

  const changeDurationHours = useCallback(
    (hours: string) => {
      const hoursNumber = parseInt(hours, 10)
      setDurationHours(hoursNumber)
    },
    [setDurationHours]
  )

  const changeDescription = useCallback(
    (description: string) => {
      setDescription(description)
    },
    [setDescription]
  )

  const resetForm = useCallback(() => {
    setDurationHours(0)
    setTitle(null)
    setDescription('')
    setTask(DefaultTask)
    setProject(null)
  }, [])

  const handleClose = useCallback(() => {
    onClose()
    resetForm()
  }, [resetForm, onClose])

  const renderControls = useCallback(() => {
    if (mode === Modes.TIMEOFF) {
      return (
        <>
          <Box className={css['add-item-dialog__form-inner']}>
            <FormControl>
              <RadioGroup value={title} onChange={changeTitle}>
                <RadioButtonShared className={css['radio']} value={TimeoffTypes.SICK_LEAVE}>
                  Sick Leave
                </RadioButtonShared>
                <RadioButtonShared className={css['radio']} value={TimeoffTypes.PAID_TIMEOFF}>
                  Paid Time Off
                </RadioButtonShared>
                <RadioButtonShared className={css['radio']} value={TimeoffTypes.HOLIDAY}>
                  Holiday
                </RadioButtonShared>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className={css['add-item-dialog__form-inner']}>
            <InputShared value={description} onChange={changeDescription} label={'Description'} />
            <InputTimeShared hours={getHours(durationHours)} onChange={changeDurationHours} />
          </Box>
        </>
      )
    }

    if (mode === Modes.TASK) {
      return (
        <>
          <Box className={css['add-item-dialog__form-inner']}>
            {projects && (
              <Autocomplete
                options={options}
                label={'Projects'}
                value={project}
                onChange={p => {
                  if (p && 'action' in p) {
                    p.action()
                  } else {
                    setProject(p as ProjectModel)
                  }
                }}
              />
            )}
          </Box>
          <Box style={{ gridTemplateColumns: '1fr' }} className={css['add-item-dialog__form-inner']}>
            {processesList && (
              <TaskTemplate
                processesList={processesList}
                task={task}
                changeTask={changeTask}
                removeTask={false}
                taskNumber={1}
                index={0}
              />
            )}
          </Box>
        </>
      )
    }

    return null
  }, [
    mode,
    changeTitle,
    title,
    description,
    changeDescription,
    changeDurationHours,
    durationHours,
    processesList,
    task,
    changeTask,
    projects,
    project,
    options,
  ])

  return (
    <DefaultDialog open={open} onClose={handleClose}>
      <Box className={css['add-item-dialog__wrapper']}>
        <div className={css['add-item-dialog__upper']}>
          <img src={infoProcess} alt={'Info Process'} />
          <h3 className={css['add-item-dialog__title']}>Add New Item</h3>
        </div>

        <Box className={css['add-item-dialog__form-container']}>
          <Box className={css['add-item-dialog__form-inner']}>
            <Autocomplete options={modeOptions} label={'Type'} value={chosenModeOption} onChange={changeMode} />
          </Box>

          {renderControls()}
        </Box>
      </Box>
      <Box className={css['footer']}>
        <Box className={css['add-item-dialog__cancel-button']}>
          <ButtonShared color={'secondary'} onClick={handleClose} text={'Cancel'} />
        </Box>
        <ButtonShared
          color={'primary'}
          disabled={!isFormValid}
          onClick={() => {
            resetForm()
            if (task && project) {
              return onSave(
                durationHours,
                description,
                title!,
                TIMEOFF_COLOR,
                { ...task, projectId: project!.id, process: task.process! },
                project,
                mode
              )
            } else {
              return onSave(durationHours, description, title!, TIMEOFF_COLOR, null, null, mode)
            }
          }}
          text={'Save Item'}
        />
      </Box>
      <CreateProjectDialog open={isAddProjectDialogShown} onClose={toggleAddProjectDialogState} />
    </DefaultDialog>
  )
}
