import React, { useCallback, useEffect, useState } from 'react'
import { Box, List, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'
import { ProcessModel } from '@/models/responces/process.model'
import ProcessesListItem from '@/pages/process/components/processes-list/ProcessesListItem'
import css from './css.module.scss'
import Loading from '@/shared/circular-progress/Loading'
import AddLinkShared from '@/shared/link/AddLinkShared'
import RemoveDialog from '@/shared/dialog/RemoveDialog'
import ProcessThunk from '@/store/process/process.thunk'
import { RequestState } from '@/constants/request-state'

interface InjectedProps {
  getAddProcessForm: () => void
}

const ProcessesList: React.FC<InjectedProps> = ({ getAddProcessForm }) => {
  const processesList = useSelector<RootStateModel, ProcessModel[] | null>(state => state.process.processesList)
  const editableProcess = useSelector<RootStateModel, ProcessModel | null>(state => state.process.editableProcess)
  const loadingDeleteProcess = useSelector<RootStateModel, RequestState>(state => state.process.loadingDeleteProcess)

  const isEditable = useCallback(
    (id: string) => {
      return !!editableProcess && editableProcess.id === id
    },
    [editableProcess]
  )

  const dispatch = useDispatch()
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [processToDeleteId, setProcessToDeleteId] = useState<string | null>(null)

  const toggleDialog = useCallback(() => {
    setDeleteDialog(!deleteDialog)
    if (deleteDialog) {
      setProcessToDeleteId(null)
    }
  }, [deleteDialog])

  const deleteProcess = useCallback(() => {
    if (processToDeleteId) {
      dispatch(ProcessThunk.deleteProcess(processToDeleteId))
    }
  }, [dispatch, processToDeleteId])

  const deleteClick = useCallback(
    (id: string) => {
      setProcessToDeleteId(id)
      toggleDialog()
    },
    [toggleDialog]
  )

  useEffect(() => {
    if (loadingDeleteProcess === RequestState.SENT_SUCCESS || loadingDeleteProcess === RequestState.SENT_FAILED) {
      setDeleteDialog(false)
    }
  }, [loadingDeleteProcess])

  return (
    <Box className={css['wrapper']}>
      {processesList ? (
        <>
          <div>
            <Typography variant={'h1'} className={css['wrapper__count']}>
              Process List ({processesList.length})
            </Typography>

            <List className={css['wrapper__list']}>
              {!!processesList &&
                processesList.map((process, index) => (
                  <ProcessesListItem
                    key={index}
                    process={process}
                    isEditable={isEditable(process.id)}
                    deleteClick={deleteClick}
                  />
                ))}
            </List>
          </div>

          <Box className={css['wrapper__add-new-wrapper']}>
            <AddLinkShared text={'Add Process'} onClick={getAddProcessForm} />
          </Box>
        </>
      ) : (
        <Loading isLoading={true} />
      )}

      <RemoveDialog
        heading={'Are you sure you want to Delete the Process?'}
        text={'All Completed data will be deleted'}
        open={deleteDialog}
        onClose={toggleDialog}
        confirmAction={deleteProcess}
        isLoading={loadingDeleteProcess === RequestState.SENDING}
      />
    </Box>
  )
}

export default ProcessesList
