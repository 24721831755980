import React from 'react'
import { Box, Typography } from '@material-ui/core'
import css from './css.module.scss'

export interface InjectedPropsInfo {
  icon: string
  heading: string
  text: string
}

const InfoBlock: React.FC<InjectedPropsInfo> = ({ text, heading, icon }) => {
  return (
    <Box className={css['wrapper']}>
      <img src={icon} alt="" />
      <Box className={css['wrapper__text-block']}>
        <Typography variant={'h1'}>{heading}</Typography>
        <Typography variant={'body2'} className={css['wrapper__text-block__text']}>{text}</Typography>
      </Box>
    </Box>
  )
}

export default InfoBlock
