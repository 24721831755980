import { TSidebarActions, ActionNames } from './sidebar.actions'

export class StateModel {
  isCollapsed: boolean = false
}

export const initialState = new StateModel()

export function SidebarReducer(state = initialState, action: TSidebarActions) {
  switch (action.type) {
    case ActionNames.TOGGLE_SIDEBAR_COLLAPSED_STATE: {
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      }
    }
    default: {
      return state
    }
  }
}
