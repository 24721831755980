import React, { useCallback, useMemo, useRef, useState } from 'react'
import { EmployeeModel } from '@/models/responces/employee.model'
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import css from './css.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setEditableEmployee } from '@/store/employee/employee.actions'
import CreateEmployeeDialog from '@/pages/employee/add-employee/CreateEmployeeDialog'
import DraggableSharedEmployee from '@/pages/employee/draggable-employee/DraggableSharedEmployee'
import { RootStateModel } from '@/store/root-reducer'
import { Group } from '@/models/calendar.model'
import EmployeeThunk from '@/store/employee/employee.thunk'

interface InjectedProps {
  employee: EmployeeModel
  index: number
}
const EmployeeListItem: React.FC<InjectedProps> = ({ employee, index }) => {
  const groups = useSelector<RootStateModel, Group[] | null>(state => state.calendar.groups)
  const employeesList = useSelector<RootStateModel, EmployeeModel[] | null>(state => state.employee.employeesList)
  const wrapperRef = useRef(null)

  const [openDialog, setOpenDialog] = useState(false)
  const dispatch = useDispatch()

  const toggleDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const edit = useCallback(() => {
    dispatch(setEditableEmployee(employee))
    toggleDialog()
  }, [dispatch, employee, toggleDialog])

  const closeDialog = useCallback(() => {
    setOpenDialog(false)
  }, [])

  const initials = useMemo(() => {
    const nameParts = employee.name.split(' ')
    return nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0]
  }, [employee])

  const handleReorderEmployees = useCallback(
    (groupKey: number) => {
      if (groups && employeesList) {
        const newIndex = groups.length - groupKey - 1
        if (employee.index === newIndex) {
          return
        }
        dispatch(EmployeeThunk.reorderEmployees(employee, newIndex))
      }
    },
    [employee, dispatch, groups, employeesList]
  )

  // const position = useMemo(() => {
  //   if (wrapperRef && wrapperRef.current) {
  //     const top = (wrapperRef.current! as HTMLElement).getBoundingClientRect().top;
  //     return top
  //   }
  //   return null
  // }, [wrapperRef])

  return (
    <>
      <div className={css['wrapper']} ref={wrapperRef}>
        <DraggableSharedEmployee handleDrop={handleReorderEmployees} index={index} position={wrapperRef} onClick={edit}>
          <ListItem className={css['wrapper__list-item']}>
            <ListItemAvatar>
              <Avatar className={css['wrapper__list-item__avatar']}>{initials} </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'button'} className={css['wrapper__list-item__name']}>
                  {employee.name}
                </Typography>
              }
            />
          </ListItem>
        </DraggableSharedEmployee>
      </div>
      {openDialog && <CreateEmployeeDialog onClose={closeDialog} open={openDialog} />}
    </>
  )
}

export default EmployeeListItem
