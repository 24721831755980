import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Timeline, { DateHeader, TimelineHeaders } from '../../copied_node_modules/react-calendar-timeline'
import './lib/Timeline.scss'
import moment from 'moment'
import css from './css.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setCalendarItems, setScrollRef, setTimelineRef } from '@/store/calendar/calendar.actions'
import { RootStateModel } from '@/store/root-reducer'
import { Group, ItemModel } from '@/models/calendar.model'
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core'
import ConfirmationDialog from '@/shared/dialog/ConfirmationDialog'
import TaskThunk from '@/store/task/task.thunk'
import { TaskModel, TaskModelMap } from '@/models/responces/task.model'
import { millisecondsToHours, roundCalendarTime, SNAP_TIME } from '@/utils/transform-time'
import {
  getStageHoursToDisplay,
  GetValidNewTime,
  isTaskLongerThanHour,
  recoordinateStage,
  GetValidTime,
  getTaskCenterDate,
} from '@/utils/helpers/get-valid-task-time'
import ButtonShared, { BtnSize } from '@/shared/button/ButtonShared'
import arrowIcon from '../../assets/icons/arrow-calendar.svg'
import deleteIcon from '../../assets/icons/delete-icon.svg'
import {
  getDaysBetweenDates,
  getMidPoint,
  getSeasonStartEndDate,
  isMondayMidnight,
  isSaturdayMidnight,
  isWeekendDay,
} from '@/utils/time-helper'
import { CalendarSelectors } from '@/store/calendar/calendar.reducer'
import TaskDetailsDrawer from './TaskDetailsDrawer'
import { AddItemDialog, Modes } from './AddItemDialog'
import { EmployeeModel } from '@/models/responces/employee.model'
import TimeoffThunk from '@/store/timeoff/timeoff.thunk'
import { MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import { UserModel } from '@/models/responces/user.model'
import * as uuid from 'uuid'
import TimeoffTransactions from '@/store/timeoff/timeoff.transactions'
import { StageModel } from '@/models/responces/stage.model'
import ProjectThunk from '@/store/project/project.thunk'
import { timelineBacklash } from '@/utils/backlash'
import { ProjectModel } from '@/models/responces/project.model'
import { subsManager } from '@/store/middleware/subs-manager/subs-manager'
import { MoveTaskDialog } from './MoveTaskDialog'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { AddStageDialog } from './AddStageDialog'
import { RemoveStageDialog } from './RemoveStageDialog'
import { useRequireRole } from '@/utils/useRequireRole'
import { CachedImg } from '@/utils/cachedImg'

interface InjectedProps {
  groups: Group[]
  displayNonActiveStages: boolean
  toggleDisplayNonActiveStages: () => void
}

interface Intersection {
  stageId: string
  startDate: number
  endDate: number
  duration: number
  stageStartDate: number
  employeeId: string
}

interface WithTaskCopy {
  taskCopy: TaskModel
}

export interface MovedTask extends WithTaskCopy {
  movedStageId: string
  newEmployeeId: string
}

export interface TaskToAddStageTo extends WithTaskCopy {
  stageIdToAddNextTo: string
}

export interface TaskToRemoveStageFrom extends WithTaskCopy {
  stageIdToRemove: string
}

const Calendar: React.FC<InjectedProps> = ({ groups, displayNonActiveStages, toggleDisplayNonActiveStages }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ItemModel | null>(null)
  const [timeStart, setTimeStart] = useState(moment().startOf('day').subtract(15, 'days'))
  const [timeEnd, setTimeEnd] = useState(moment().startOf('day').add(16, 'days'))
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null)
  const [selectedArray, setSelectedArray] = useState<Array<number>>([])
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false)
  const [newTaskTime, setNewTaskTime] = useState<number | null>(null)
  const [newTaskEmployeeId, setNewTaskEmployeeId] = useState<string | null>(null)
  const [resizingItemTime, setResizingItemTime] = useState<number | null>(null)
  const [resizingItemId, setResizingItemId] = useState<string | null>(null)
  const [resizingItemEdge, setResizingItemEdge] = useState<string | null>(null)
  const [isItemDragging, setIsItemDragging] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [intersections, setIntersections] = useState<Intersection[]>([])
  const [backlashCurrentTime, setBacklashCurrentTime] = useState(timelineBacklash.getCurrent())
  const [movedTaskData, setMovedTaskData] = useState<MovedTask | null>(null)
  const [taskToAddStageTo, setTaskToAddStageTo] = useState<TaskToAddStageTo | null>(null)
  const [taskToRemoveStageFrom, setTaskToRemoveStageFrom] = useState<TaskToRemoveStageFrom | null>(null)
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)

  const setStageToRemoveData = useCallback((task: TaskModel, stageId: string) => {
    setTaskToRemoveStageFrom({ taskCopy: task, stageIdToRemove: stageId })
  }, [])

  const cancelStageRemoval = useCallback(() => {
    setTaskToRemoveStageFrom(null)
  }, [])

  const setStageToAddData = useCallback((task: TaskModel, stageId: string) => {
    setTaskToAddStageTo({ taskCopy: task, stageIdToAddNextTo: stageId })
  }, [])

  const cancelStageAddition = useCallback(() => {
    setTaskToAddStageTo(null)
  }, [])

  const timelineRef = useRef(null)
  const dispatch = useDispatch()
  const items = useSelector<RootStateModel, ItemModel[]>(state => state.calendar.items)
  const activeItems = useSelector<RootStateModel, ItemModel[]>(state => CalendarSelectors.activeItems(state.calendar))
  const tasksMap = useSelector<RootStateModel, TaskModelMap | null>(state => state.project.assignedTasksMap)
  const employees = useSelector<RootStateModel, EmployeeModel[] | null>(state => state.employee.employeesList)
  const workspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const timeoffsList = useSelector<RootStateModel, TimeoffModel[]>(state => state.timeoff.timeoffsList)
  const draggableItem = useSelector<RootStateModel, TaskModel | null>(state => state.calendar.draggableItem)
  const projectsList = useSelector<RootStateModel, ProjectModel[] | null>(state => state.project.projectsList)

  const tasksList: TaskModel[] = useMemo(() => {
    if (tasksMap) {
      return Object.keys(tasksMap).reduce((res: TaskModel[], v: string) => {
        return res.concat(tasksMap[v])
      }, [])
    }
    return []
  }, [tasksMap])

  useEffect(() => {
    if (projectsList && projectsList.length) {
      const backlashCurrent = timelineBacklash.getCurrent()
      const backlashThreshold = timelineBacklash.getThreshold()
      const startDate = backlashCurrent - backlashThreshold
      const endDate = backlashCurrent + backlashThreshold
      projectsList.forEach(p => {
        subsManager.subscribeAssignedProjectTasks(p, startDate, endDate)
      })
    }
  }, [backlashCurrentTime, projectsList])

  const checkEmployeeIntersections = useCallback((stages: StageModel[], employeeId: string) => {
    let res: Intersection[] = []
    const stagesToCheck = stages.map(s => ({ ...s, uuid: uuid.v4() }))

    stagesToCheck.forEach(currStage => {
      stagesToCheck.forEach(subStage => {
        if (currStage.uuid === subStage.uuid) {
        } else {
          const intersections = detectIntersection(currStage, subStage, employeeId)
          res = res.concat(intersections)
        }
      })
    })

    return res
  }, [])

  useEffect(() => {
    const assignedTasks = tasksList.filter(t => t.employeeId)

    const stagesByEmployeeId: {
      // employeeId: stages[]
      [key: string]: StageModel[]
    } = {}

    assignedTasks.forEach(t => {
      if (!stagesByEmployeeId[t.employeeId!]) {
        stagesByEmployeeId[t.employeeId!] = []
      }
      stagesByEmployeeId[t.employeeId!].push(...t.process.stages.filter(s => s.isActive))
    })
    timeoffsList.forEach(timeoff => {
      if (!stagesByEmployeeId[timeoff.employeeId]) {
        stagesByEmployeeId[timeoff.employeeId] = []
      }

      stagesByEmployeeId[timeoff.employeeId].push(...timeoff.process.stages)
    })
    let intersections: Intersection[] = []

    Object.entries(stagesByEmployeeId).forEach(([employeeId, s]) => {
      const employeeIntersections = checkEmployeeIntersections(s, employeeId)
      intersections = intersections.concat(employeeIntersections)
    })

    setIntersections(intersections)
  }, [tasksList, checkEmployeeIntersections, timeoffsList])

  const cancelMovedTask = useCallback(() => {
    setMovedTaskData(null)
  }, [])

  const goToToday = useCallback(() => {
    const days = getDaysBetweenDates(timeStart, timeEnd)
    const daysBeforeMidPoint = Math.floor(days / 2)
    const daysAfterMidPoint = days - daysBeforeMidPoint
    setTimeStart(moment().startOf('day').subtract(daysBeforeMidPoint, 'days'))
    setTimeEnd(moment().startOf('day').add(daysAfterMidPoint, 'days'))
  }, [timeStart, timeEnd])

  const midPoint = useMemo(() => {
    return getMidPoint(timeStart, timeEnd)
  }, [timeStart, timeEnd])

  const goToWeekView = useCallback(() => {
    setTimeStart(moment(midPoint).startOf('day').subtract(4, 'days'))
    setTimeEnd(moment(midPoint).startOf('day').add(5, 'days'))
  }, [midPoint])

  const goToMonthView = useCallback(() => {
    setTimeStart(moment(midPoint).startOf('day').subtract(15, 'days'))
    setTimeEnd(moment(midPoint).startOf('day').add(16, 'days'))
  }, [midPoint])

  const goToSeasonView = useCallback(() => {
    const month = moment(midPoint).month()
    const [startDate, endDate] = getSeasonStartEndDate(month)
    setTimeStart(startDate)
    setTimeEnd(endDate)
  }, [midPoint])

  const goToPrevMonth = useCallback(() => {
    setTimeStart(moment(midPoint).endOf('month').subtract(2, 'month'))
    setTimeEnd(moment(midPoint).endOf('month').subtract(1, 'month'))
  }, [midPoint])

  const goToNextMonth = useCallback(() => {
    setTimeStart(moment(midPoint).startOf('month').add(1, 'month'))
    setTimeEnd(moment(midPoint).startOf('month').add(2, 'month'))
  }, [midPoint])

  const handleChange = useCallback(() => {
    toggleDisplayNonActiveStages()
    dispatch(setCalendarItems([...items]))
  }, [dispatch, items, toggleDisplayNonActiveStages])

  const toggleDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const onDrawerClose = useCallback(() => {
    setOpenDrawer(false)
  }, [setOpenDrawer])

  useEffect(() => {
    dispatch(setTimelineRef(timelineRef))

    // const sidebar = document.getElementsByClassName('rct-sidebar')[0]
    // if (sidebar) {
    //   // @ts-ignore
    //   sidebarRef.current = sidebar
    // }
  }, [timelineRef, dispatch])

  const onItemDoubleClick = useCallback(
    task => {
      setOpenDrawer(true)
      const id = task.taskId
      const selectedTask = tasksList.find(t => t.id === id) || null
      setSelectedTask(selectedTask)
    },
    [setOpenDrawer, setSelectedTask, tasksList]
  )

  const onMouseOver = useCallback(
    task => {
      if (draggableItem) {
        setSelectedArray([])
      } else {
        setSelectedArray([task.id])
      }
    },
    [setSelectedArray, draggableItem]
  )

  const onCanvasClick = useCallback(() => {
    setSelectedArray([])
  }, [setSelectedArray])

  const onScrollRef = useCallback(
    (ref: any) => {
      dispatch(setScrollRef({ current: ref }))
    },
    [dispatch]
  )

  const checkIsWeekend = useCallback((time: number) => {
    return (
      (isWeekendDay(moment(time).day()) && !isSaturdayMidnight(time, moment(time).day())) ||
      isMondayMidnight(time, moment(time).day())
    )
  }, [])

  const stagesOverlap = useCallback((stages: StageModel[], stageToResizeIndex: number, newTime: number): boolean => {
    const previousStage = stages[stageToResizeIndex - 1]

    if (previousStage) {
      if (previousStage.isActive) {
        return newTime < previousStage.date[0].endDate
      }

      return newTime < previousStage.date[0].startDate
    }

    return false
  }, [])

  const handleMove = useCallback(
    (itemId, dragTime, newGroupOrder) => {
      const newStartTime = roundCalendarTime(dragTime).valueOf()

      if (groups) {
        const group = groups[newGroupOrder]
        const currentItem = items.find(item => item.id === itemId)
        const isTimeoff = currentItem && currentItem.type === 'timeoff'

        if (currentItem && tasksList) {
          const taskToUpdate = isTimeoff
            ? timeoffsList.find(timeoff => timeoff.id === currentItem.taskId)
            : tasksList.find(task => task.id === currentItem.taskId)
          if (taskToUpdate) {
            const currentStageIndex = taskToUpdate.process.stages.findIndex(s => s.id === currentItem.stageId)
            const currentStage = taskToUpdate.process.stages[currentStageIndex]
            const stagesToSave = taskToUpdate.process.stages.slice(0, currentStageIndex)
            const employeeId = taskToUpdate.employeeId

            if (stagesOverlap(taskToUpdate.process.stages, currentStageIndex, newStartTime) || isLoading) {
              return
            }

            const stages = recoordinateStage(
              currentStage,
              stagesToSave,
              taskToUpdate.process.stages,
              newStartTime,
              currentStageIndex
            )

            if (group.employeeId !== employeeId && currentItem.type === 'project') {
              setMovedTaskData({
                taskCopy: { ...taskToUpdate, process: { ...taskToUpdate.process, stages: stages } } as TaskModel,
                movedStageId: currentItem.stageId,
                newEmployeeId: group.employeeId,
              })
              return
            }

            if (isTimeoff) {
              const timeoffToUpdate = {
                ...taskToUpdate,
                process: { stages },
                employeeId: group.employeeId,
                centerDate: getTaskCenterDate(stages),
              } as TimeoffModel
              setIsLoading(true)
              const timeoffToUpdateIndex = timeoffsList.findIndex(t => t.id === currentItem.taskId)
              const oldTimeoffList = [...timeoffsList]
              timeoffsList[timeoffToUpdateIndex] = timeoffToUpdate
              const newTimeoffList = [...timeoffsList]

              TimeoffTransactions.EditTimeoff(timeoffToUpdate, oldTimeoffList, newTimeoffList)
            } else {
              const taskAssertion = taskToUpdate as TaskModel
              const projectId = taskAssertion.projectId
              const newTask = {
                ...taskAssertion,
                process: { ...taskAssertion.process, stages },
                employeeId: group.employeeId,
                centerDate: getTaskCenterDate(stages),
              }
              const newTasksList = tasksList.filter(t => t.projectId === projectId)
              const oldTasksList = [...newTasksList]
              const taskToUpdateIndex = newTasksList.findIndex(t => t.id === currentItem.taskId)
              newTasksList[taskToUpdateIndex] = newTask

              setIsLoading(true)
              dispatch(TaskThunk.editTask(newTask, projectId, newTasksList, oldTasksList))
            }
          }
        }
      }

      setIsLoading(false)
    },
    [groups, items, tasksList, dispatch, timeoffsList, stagesOverlap, isLoading]
  )

  const handleItemResize = useCallback(
    (itemId, time, edge) => {
      const newTime = roundCalendarTime(time).valueOf()
      const currentItem = items.find(item => item.id === itemId)
      const isTimeoff = currentItem && currentItem.type === 'timeoff'
      const isWeekend = checkIsWeekend(newTime)

      if (currentItem && tasksList) {
        if (!isTaskLongerThanHour(currentItem, edge, newTime) || isWeekend) {
          return
        }

        const difference =
          edge === 'left' ? currentItem.start_time.valueOf() - newTime : newTime - currentItem.end_time.valueOf()
        const taskToUpdate = isTimeoff
          ? timeoffsList.find(timeoff => timeoff.id === currentItem.taskId)
          : tasksList.find(task => task.id === currentItem.taskId)
        const startDate = edge === 'left' ? newTime : currentItem.start_time.valueOf()

        if (taskToUpdate) {
          const currentStageIndex = taskToUpdate.process.stages.findIndex(s => s.id === currentItem.stageId)
          const currentStage = taskToUpdate.process.stages[currentStageIndex]
          const stagesToSave = taskToUpdate.process.stages.slice(0, currentStageIndex)

          if (stagesOverlap(taskToUpdate.process.stages, currentStageIndex, newTime) || isLoading) {
            return
          }

          const stages = recoordinateStage(
            currentStage,
            stagesToSave,
            taskToUpdate.process.stages,
            startDate,
            currentStageIndex,
            'resize',
            difference,
            edge
          )

          if (isTimeoff) {
            const timeoffToUpdate = {
              ...taskToUpdate,
              process: { stages },
              centerDate: getTaskCenterDate(stages),
            } as TimeoffModel
            setIsLoading(true)
            const timeoffToUpdateIndex = timeoffsList.findIndex(t => t.id === currentItem.taskId)
            const oldTimeoffList = [...timeoffsList]
            timeoffsList[timeoffToUpdateIndex] = timeoffToUpdate
            const newTimeoffList = [...timeoffsList]

            TimeoffTransactions.EditTimeoff(timeoffToUpdate, oldTimeoffList, newTimeoffList)
          } else {
            const taskAssertion = taskToUpdate as TaskModel
            const projectId = taskAssertion.projectId
            const newTask = {
              ...taskAssertion,
              process: { ...taskAssertion.process, stages },
              centerDate: getTaskCenterDate(stages),
            }
            const newTasksList = tasksList.filter(t => t.projectId === projectId)
            const oldTasksList = [...newTasksList]
            const taskToUpdateIndex = newTasksList.findIndex(t => t.id === currentItem.taskId)
            newTasksList[taskToUpdateIndex] = newTask

            setIsLoading(true)

            dispatch(TaskThunk.editTask(newTask, projectId, newTasksList, oldTasksList))
          }
        }
      }

      setIsLoading(false)
    },
    [items, tasksList, dispatch, timeoffsList, stagesOverlap, isLoading, checkIsWeekend]
  )

  const selectItem = useCallback(
    (item: ItemModel) => {
      toggleDialog()
      setSelectedItem(item)
    },
    [toggleDialog]
  )

  const onItemResizing = useCallback((stageId: string, newTime: number, edge: string) => {
    setResizingItemTime(newTime)
    setResizingItemId(stageId)
    setResizingItemEdge(edge)
  }, [])

  const onItemResizingStop = useCallback(() => {
    setResizingItemTime(null)
    setResizingItemId(null)
    setResizingItemEdge(null)
    setSelectedArray([])
  }, [setResizingItemTime, setResizingItemId])

  const onItemDragging = useCallback(() => {
    setIsItemDragging(true)
  }, [])

  const onItemDraggingStop = useCallback(() => {
    setIsItemDragging(false)
  }, [])

  const toggleAddItemDialog = useCallback(() => {
    setOpenAddItemDialog(val => !val)
  }, [setOpenAddItemDialog])

  const detectIntersection = (a: StageModel, b: StageModel, employeeId: string): Intersection[] => {
    const res: Intersection[] = []
    const aDate = a.date[0].startDate <= b.date[0].startDate ? a.date : b.date
    const bDate = a.date[0].startDate <= b.date[0].startDate ? b.date : a.date

    if (aDate[0].startDate <= bDate[0].startDate && aDate[0].endDate >= bDate[0].startDate) {
      const intersectionEndDate = bDate[0].endDate >= aDate[0].endDate ? aDate[0].endDate : bDate[0].endDate
      const intersectionDuration = intersectionEndDate - bDate[0].startDate
      const aIntersection: Intersection = {
        stageId: a.id,
        startDate: bDate[0].startDate,
        duration: millisecondsToHours(intersectionDuration) / 3,
        endDate: intersectionEndDate,
        stageStartDate: aDate[0].startDate,
        employeeId,
      }

      const bIntersection: Intersection = {
        stageId: b.id,
        startDate: bDate[0].startDate,
        duration: millisecondsToHours(intersectionDuration) / 3,
        endDate: intersectionEndDate,
        stageStartDate: bDate[0].startDate,
        employeeId,
      }

      intersectionDuration > 0 && res.push(aIntersection)
      intersectionDuration > 0 && res.push(bIntersection)
    }

    return res
  }

  const itemRenderer = useCallback(
    ({ item, timelineContext, itemContext, getItemProps, getResizeProps, items }: any) => {
      const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
      const borderColor = displayNonActiveStages && !item.isActive ? '#758390' : item.color

      const itemStyleProps = {
        style: {
          padding: '6px',
        },
      }

      const itemWrapperProps = getItemProps({
        style: {
          background: item.isActive ? item.color : 'transparent',
          color: 'white',
          border: 'none',
          borderRight:
            itemContext.selected && (item.isActive || displayNonActiveStages)
              ? '3px solid black'
              : displayNonActiveStages && !item.isActive
              ? `1px solid ${item.color}`
              : `3px solid ${borderColor}`,
          borderLeft:
            itemContext.selected && item.isActive
              ? '3px solid black'
              : displayNonActiveStages && !item.isActive
              ? `1px solid ${item.color}`
              : `3px solid ${borderColor}`,
          borderTop: itemContext.selected && item.isActive ? '1px solid black' : `4px solid ${item.color}`,
          borderBottom: itemContext.selected && item.isActive ? '1px solid black' : `4px solid ${item.color}`,
          opacity: item.isActive ? 1 : 0.75,
          ...item.style,
        },
      })
      itemWrapperProps.style.height = item.type === 'timeoff' ? '80px' : '60px'
      itemWrapperProps.style.transform = item.type === 'timeoff' ? 'translateY(-10px)' : ''

      if (selectedArray.includes(item.id)) {
        itemWrapperProps.style.zIndex = 90
      } else if (isItemDragging || resizingItemId) {
        itemWrapperProps.style.zIndex = 29
      }

      const resizeStyleProps = { width: '12px', cursor: 'col-resize' }
      const resizeStylePropsLeft = { left: '-9px' }
      const resizeStylePropsRight = { right: '-9px' }
      let hoursToDisplay: number | string = Math.ceil(millisecondsToHours(item.workHours))

      if (item.id === resizingItemId && resizingItemTime && resizingItemEdge) {
        const from = resizingItemEdge === 'left' ? resizingItemTime : item.start_time.valueOf()
        const to = resizingItemEdge === 'left' ? item.end_time.valueOf() : resizingItemTime
        hoursToDisplay = Math.ceil(millisecondsToHours(getStageHoursToDisplay(from, to)))

        if (checkIsWeekend(resizingItemTime)) {
          hoursToDisplay = 'Invalid (weekend)'
        }
      }

      const workHours = millisecondsToHours(item.workHours)
      const weekendHours = millisecondsToHours(item.weekendHours) / 3
      const stageIntersections = intersections.filter(
        i =>
          i.stageId === item.stageId &&
          i.stageStartDate === item.start_time.valueOf() &&
          item.employeeId === i.employeeId
      )
      const width = itemWrapperProps.style.width
      const height = itemWrapperProps.style.height
      const top = itemWrapperProps.style.top
      const left = itemWrapperProps.style.left
      const shouldHighlightIntersections = !displayNonActiveStages

      const hourToPxRatio = parseFloat(width) / (workHours + weekendHours)
      let increasedTop: any = parseFloat(top) - (item.type === 'timeoff' ? 0 : 10)
      increasedTop += 'px'
      let increasedHeight: any = parseFloat(height) + (item.type === 'timeoff' ? 0 : 20)
      increasedHeight += 'px'
      const task = tasksList.find(t => t.id === item.taskId)

      return (
        <div>
          {shouldHighlightIntersections &&
            stageIntersections.map((i, index) => {
              const leftShift = millisecondsToHours(i.startDate - item.start_time.valueOf()) / 3
              const itemLeft = hourToPxRatio * leftShift + parseFloat(left)
              const width = hourToPxRatio * i.duration
              return (
                <div
                  style={{
                    position: 'absolute',
                    height: increasedHeight,
                    top: increasedTop,
                    left: `${itemLeft}px`,
                    width: `${width}px`,
                    backgroundColor: '#FF9393',
                    zIndex: 29,
                  }}
                  key={index}
                />
              )
            })}

          <div {...itemWrapperProps}>
            <div
              onMouseOver={() => (item.isActive ? onMouseOver(item) : null)}
              onDoubleClick={() => onItemDoubleClick(item)}
              {...itemStyleProps}
            >
              {itemContext.useResizeHandle && itemContext.selected && item.isActive ? (
                <div
                  {...{
                    ...leftResizeProps,
                    style: { ...leftResizeProps.style, ...resizeStyleProps, ...resizeStylePropsLeft },
                  }}
                />
              ) : null}
              {item.isActive && (
                <Box className={css['text-container']}>
                  <Box className={css['project-name']}>{item.projectName}</Box>
                  <Box className={css['task-name']}>{item.taskName}</Box>
                  <Box className={css['stage-name']}>
                    {item.stageName} <span className={css['task-duration']}>{hoursToDisplay} H</span>
                  </Box>
                </Box>
              )}
              {itemContext.selected && item.isActive && isRoleAllowed && (
                <>
                  <IconButton className={css['item-delete']} onClick={() => selectItem(item)}>
                    <CachedImg src={deleteIcon} alt="" />
                  </IconButton>
                  {task && (
                    <IconButton className={css['item-add']} onClick={() => setStageToAddData(task, item.stageId)}>
                      <AddIcon className={css['add-icon']} />
                    </IconButton>
                  )}
                  {task && (
                    <IconButton className={css['item-remove']} onClick={() => setStageToRemoveData(task, item.stageId)}>
                      <RemoveIcon className={css['remove-icon']} />
                    </IconButton>
                  )}
                </>
              )}
              {itemContext.useResizeHandle && itemContext.selected && (item.isActive || displayNonActiveStages) ? (
                <div
                  {...{
                    ...rightResizeProps,
                    style: { ...rightResizeProps.style, ...resizeStyleProps, ...resizeStylePropsRight },
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      )
    },
    [
      checkIsWeekend,
      displayNonActiveStages,
      onItemDoubleClick,
      onMouseOver,
      resizingItemEdge,
      resizingItemId,
      resizingItemTime,
      selectItem,
      selectedArray,
      isItemDragging,
      intersections,
      setStageToRemoveData,
      tasksList,
      setStageToAddData,
      isRoleAllowed,
    ]
  )

  const unassignTask = useCallback(() => {
    if (selectedItem && selectedItem.type === 'project' && tasksList) {
      const currentTask = tasksList.find(task => task.id === selectedItem.taskId)
      currentTask && dispatch(TaskThunk.unassignTask(currentTask))
      toggleDialog()
      setSelectedItem(null)
    }

    if (selectedItem && selectedItem.type === 'timeoff') {
      TimeoffTransactions.DeleteTimeoff(selectedItem.taskId)
      toggleDialog()
      setSelectedItem(null)
    }
  }, [dispatch, selectedItem, tasksList, toggleDialog])

  const onCalendarScroll = useCallback(
    (visibleTimeStart, visibleTimeEnd) => {
      timelineBacklash.moveTo(visibleTimeStart)
      const current = timelineBacklash.getCurrent()
      if (current !== backlashCurrentTime) {
        setBacklashCurrentTime(current)
      }
      setTimeStart(moment(visibleTimeStart))
      setTimeEnd(moment(visibleTimeEnd))
    },
    [backlashCurrentTime]
  )

  const classNamesForToday = useCallback(timeStart => {
    const today = moment().startOf('day')
    const start = moment(timeStart)
    return start.isSame(today, 'd') ? ['today'] : []
  }, [])

  const itemsToRender = useMemo(() => {
    return displayNonActiveStages ? items : activeItems
  }, [displayNonActiveStages, items, activeItems])

  // const onDragStart = useCallback(() => {
  //   if (sidebarRef.current) {
  //     sidebarRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.08)'
  //   }
  // }, [sidebarRef])

  const onCanvasDoubleClick = useCallback(
    (groupId, time, e) => {
      toggleAddItemDialog()
      setNewTaskTime(time)
      let employeeId = ''

      if (employees && employees.length) {
        employees.forEach((e, i) => {
          if (groupId === i) {
            employeeId = e.id
          }
        })
      }

      setNewTaskEmployeeId(employeeId)
    },
    [toggleAddItemDialog, employees]
  )

  const onSave = useCallback(
    (hours, description, title, color, task, project, mode) => {
      if (workspace && user && newTaskEmployeeId) {
        const validStartTime = GetValidNewTime(moment(newTaskTime))
        if (mode === Modes.TIMEOFF) {
          const timeoffStartValidTime = GetValidNewTime(moment(newTaskTime))
          const calendarValidEndTime = timeoffStartValidTime + hours * 3
          const stageToSave = {
            id: uuid.v4(),
            name: description,
            isActive: true,
            date: GetValidTime(validStartTime, calendarValidEndTime),
            workHours: hours,
            authorUid: user.id,
            weekendHours: 0,
          }

          const timeoffToAdd: TimeoffModel = {
            id: '',
            workspaceId: workspace.id,
            name: title,
            color,
            authorUid: user.id,
            employeeId: newTaskEmployeeId,
            process: {
              stages: recoordinateStage(stageToSave, [], [], stageToSave.date[0].startDate),
            },
          }

          dispatch(TimeoffThunk.addTimeoff(timeoffToAdd))
        }

        if (mode === Modes.TASK) {
          const taskStagesToSave = recoordinateStage(task.process.stages[0], [], task.process.stages, validStartTime)
          dispatch(
            ProjectThunk.editProject({
              project: project,
              tasks: [
                {
                  ...task,
                  process: { ...task.process, stages: taskStagesToSave },
                  employeeId: newTaskEmployeeId,
                  centerDate: getTaskCenterDate(taskStagesToSave),
                },
              ],
              deletedTasksId: [],
            })
          )
        }
        toggleAddItemDialog()
      }
    },
    [newTaskTime, user, workspace, newTaskEmployeeId, toggleAddItemDialog, dispatch]
  )

  return (
    <>
      <Box className={css['view-controls']}>
        <Box className={css['view-controls__right-part']}>
          <Box className={css['view-controls__right-part__months-switch']}>
            <IconButton className={css['view-controls__right-part__months-switch__r-arrow']} onClick={goToPrevMonth}>
              <img src={arrowIcon} alt="" />
            </IconButton>
            <Typography variant={'button'} className={css['view-controls__right-part__months-switch__date']}>
              {moment(midPoint).format('MMMM YYYY')}
            </Typography>
            <IconButton className={css['view-controls__right-part__months-switch__l-arrow']} onClick={goToNextMonth}>
              <img src={arrowIcon} alt="" />
            </IconButton>
          </Box>
          <FormControlLabel
            control={<Checkbox checked={displayNonActiveStages} onChange={handleChange} color="primary" />}
            label={
              <Typography variant={'button'} className={css['view-controls__checkbox-text']}>
                Display non-active stages
              </Typography>
            }
          />
        </Box>
        <Box className={css['view-controls__left-part']}>
          <ButtonShared color="primary" text={'Today'} size={BtnSize.SMALL} onClick={goToToday} />
          <ButtonShared color="secondary" text={'Season View'} size={BtnSize.SMALL} onClick={goToSeasonView} />
          <ButtonShared color="secondary" text={'Week View'} size={BtnSize.SMALL} onClick={goToMonthView} />
          <ButtonShared color="secondary" text={'Day View'} size={BtnSize.SMALL} onClick={goToWeekView} />
        </Box>
      </Box>

      <Timeline
        ref={timelineRef}
        scrollRef={onScrollRef}
        groups={groups}
        items={itemsToRender}
        itemRenderer={itemRenderer}
        onCanvasClick={onCanvasClick}
        defaultTimeStart={timeStart}
        defaultTimeEnd={timeEnd}
        visibleTimeStart={timeStart.valueOf()}
        visibleTimeEnd={timeEnd.valueOf()}
        onItemMove={handleMove}
        onItemResize={handleItemResize}
        selected={selectedArray}
        onCanvasDoubleClick={onCanvasDoubleClick}
        useResizeHandle
        timeSteps={{
          second: 0,
          minute: 0,
          hour: 3,
          day: 1,
          month: 1,
          year: 1,
        }}
        sidebarWidth={100}
        shouldLineUp={displayNonActiveStages}
        maxZoom={2629800000}
        stackItems
        canMove
        canResize={'both'}
        lineHeight={80}
        itemHeightRatio={0.75}
        minResizeWidth={1}
        onTimeChange={onCalendarScroll}
        verticalLineClassNamesForTime={classNamesForToday}
        dragSnap={SNAP_TIME}
        onItemResizing={onItemResizing}
        onItemResizingStop={onItemResizingStop}
        onItemDragging={onItemDragging}
        onItemDraggingStop={onItemDraggingStop}
        isSidebarCollapsed={isSidebarCollapsed}
      >
        <TimelineHeaders className={css['headers']}>
          <DateHeader unit="primaryHeader" />
          <DateHeader
            unit="day"
            labelFormat="D ddd"
            height={68}
            intervalRenderer={({ getIntervalProps, intervalContext }: any) => {
              const day = intervalContext.intervalText.split(' ')[1]
              const isWeekend = day === 'Sa' || day === 'Su'
              const isToday = moment(intervalContext.interval.startTime).isSame(moment().startOf('day'))
              const className = isWeekend ? 'weekend' : isToday ? 'today' : 'header-date'
              return (
                <div {...getIntervalProps()} className={css[className]}>
                  <div className={css[`${className}__day`]}>{intervalContext.intervalText.split(' ')[0]}</div>
                  <div className={css[`${className}__month`]}>{day}</div>
                </div>
              )
            }}
          />
        </TimelineHeaders>
      </Timeline>

      <ConfirmationDialog
        onClose={toggleDialog}
        open={openDialog}
        title={'Are you sure you want to unassign current task?'}
        confirmAction={unassignTask}
      />
      {selectedTask && projectsList && (
        <TaskDetailsDrawer projectsList={projectsList} task={selectedTask} open={openDrawer} onClose={onDrawerClose} />
      )}
      <AddItemDialog open={openAddItemDialog} onClose={toggleAddItemDialog} onSave={onSave} />
      {movedTaskData && <MoveTaskDialog movedTask={movedTaskData} open={!!movedTaskData} onClose={cancelMovedTask} />}
      {taskToAddStageTo && (
        <AddStageDialog taskToAddData={taskToAddStageTo} open={!!taskToAddStageTo} onClose={cancelStageAddition} />
      )}
      {taskToRemoveStageFrom && (
        <RemoveStageDialog
          taskToRemoveData={taskToRemoveStageFrom}
          open={!!taskToRemoveStageFrom}
          onClose={cancelStageRemoval}
        />
      )}
    </>
  )
}

export default React.memo(Calendar)
