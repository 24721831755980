import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { runTransaction } from '@/firebase/helpers'
import { TimeoffModel } from '@/models/responces/timeoff.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'
import store from '@/store/store'
import { setTimeoffs } from '@/store/timeoff/timeoff.actions'

export default class TimeoffTransactions {
  @HandleAsyncErrors()
  public static async AddTimeoff(timeoff: TimeoffModel) {
    await runTransaction(async transaction => {
      const newTimeoffRef = FirebaseUsage.database().collection(COLLECTIONS.TIMEOFFS).doc()
      transaction.set(newTimeoffRef, { ...timeoff, id: newTimeoffRef.id })
    })
  }

  @HandleAsyncErrors()
  public static async EditTimeoff(
    timeoff: TimeoffModel,
    oldTimeoffList?: TimeoffModel[],
    newTimeoffList?: TimeoffModel[]
  ) {
    if (oldTimeoffList && newTimeoffList) {
      try {
        store.dispatch(setTimeoffs(newTimeoffList))
        await FirebaseUsage.database()
          .collection(COLLECTIONS.TIMEOFFS)
          .doc(timeoff.id)
          .update({ ...timeoff })
      } catch (err) {
        store.dispatch(setTimeoffs(oldTimeoffList))
      }
    } else {
      await FirebaseUsage.database()
        .collection(COLLECTIONS.TIMEOFFS)
        .doc(timeoff.id)
        .update({ ...timeoff })
    }
  }

  @HandleAsyncErrors()
  public static async DeleteTimeoff(id: string) {
    await FirebaseUsage.database().collection(COLLECTIONS.TIMEOFFS).doc(id).delete()
  }
}
