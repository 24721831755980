import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import NavLink from '@/shared/nav-link/NavLink'
import AuthorizationThunk from '@/store/authorization/authorization.thunks'
import { useDispatch, useSelector } from 'react-redux'
import NoteDrawer from '@/pages/note/NoteDrawer'
import { AvatarShared } from '../avatar/AvatarShared'
import { useHistory } from 'react-router-dom'
import RouteNameConstants from '@/constants/route-name.constants'
import { WorkspacesDrawer } from './WorkspacesDrawer'
import { RootStateModel } from '@/store/root-reducer'
import { Invitation } from '@/models/responces/invitation.model'

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openWorkspacesDrawer, setOpenWorkspaceDrawerState] = useState(false)
  const history = useHistory()
  const invitations = useSelector<RootStateModel, Invitation[]>(state => state.invitation.userInvitations)

  const toggleNotesDrawer = useCallback(() => {
    setOpenDrawer(openDrawer => !openDrawer)
  }, [])

  const toggleWorkspacesDrawer = useCallback(() => {
    setOpenWorkspaceDrawerState(val => !val)
  }, [])

  const toggleMenu = useCallback(() => {
    setOpenMenu(val => !val)
  }, [])

  const redirectTo = useCallback(
    (to: string) => {
      history.push(to)
    },
    [history]
  )

  const dispatch = useDispatch()
  const logOut = useCallback(() => {
    dispatch(AuthorizationThunk.logOut())
  }, [dispatch])

  const menu = (
    <Box className={css['menu']}>
      <Box className={css['cover']} onClick={toggleMenu} />
      <NavLink onClick={() => redirectTo(`/${RouteNameConstants.PROFILE}`)}>Profile</NavLink>
      <NavLink onClick={logOut}>Log Out</NavLink>
    </Box>
  )

  return (
    <Box className={css['header']}>
      <NavLink className={css['workspaces-link']} onClick={toggleWorkspacesDrawer}>
        Workspaces{' '}
        {invitations.length > 0 && (
          <div
            className={css['notification']}
          />
        )}
      </NavLink>
      <NavLink onClick={toggleNotesDrawer}>Notes</NavLink>

      <Box className={css['avatar-container']}>
        <AvatarShared className={css['avatar']} onClick={toggleMenu} />
        {openMenu && menu}
      </Box>

      <NoteDrawer open={openDrawer} toggleDrawer={toggleNotesDrawer} />
      <WorkspacesDrawer open={openWorkspacesDrawer} toggleDrawer={toggleWorkspacesDrawer} />
    </Box>
  )
}

export default Header
