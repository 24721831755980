import React from 'react'
import { Typography } from '@material-ui/core'
import css from './css.module.scss'

interface InjectedProps {
  onClick: () => void
  text?: string
}
const CancelLinkShared: React.FC<InjectedProps> = ({ onClick, text }) => {
  return (
    <Typography variant={'button'} className={css['cancel-link']} onClick={onClick}>
      {!!text ? text : 'Cancel'}
    </Typography>
  )
}

export default CancelLinkShared
