import { ActionNames, TCalendarActions } from './calendar.actions'
import { Group, ItemModel } from '@/models/calendar.model'
import { TaskModel } from '@/models/responces/task.model'
import { CoordinatesModel } from '@/models/coordinates.model'

export class StateModel {
  scrollRef: any
  timelineRef: any
  items: ItemModel[] = []
  draggableItem: TaskModel | null = null
  draggableCoordinates: CoordinatesModel | null = null
  groups: Group[] | null = null
}

export const initialState = new StateModel()

export function CalendarReducer(state = initialState, action: TCalendarActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_SCROLL_REF: {
      return {
        ...state,
        scrollRef: action.payload,
      }
    }
    case ActionNames.SET_TIMELINE_REF: {
      return {
        ...state,
        timelineRef: action.payload,
      }
    }
    case ActionNames.SET_CALENDAR_ITEMS: {
      return {
        ...state,
        items: action.payload,
      }
    }
    case ActionNames.REMOVE_ITEM: {
      return {
        ...state,
        items: state.items.filter(item => item.taskId !== action.payload.id),
      }
    }
    case ActionNames.SET_CURRENTLY_DRAGGABLE: {
      return {
        ...state,
        draggableItem: action.payload,
      }
    }

    case ActionNames.SET_DRAGGABLE_COORDINATES: {
      return {
        ...state,
        draggableCoordinates: action.payload,
      }
    }

    case ActionNames.SET_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      }
    }
    default:
      return state
  }
}

export const CalendarSelectors = {
  activeItems: (state: StateModel) => !!state.items && state.items.filter(item => item.isActive),
}
