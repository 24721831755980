import React from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import AddEmployee from '@/pages/employee/add-employee/AddEmployee'
import CalendarWrapper from '@/pages/calendar/CalendarWrapper'
import { RequireRoleHOC } from '@/utils/RequireRoleHOC'
import { MemberRoles } from '@/models/responces/workspace.model'

const CalendarView = () => {
  return (
    <Box className={css['root']}>
      <div className={css['calendar']}>
        <CalendarWrapper />
      </div>
      <Box className={css['add-employee']}>
        <RequireRoleHOC role={MemberRoles.OWNER}>
          <AddEmployee />
        </RequireRoleHOC>
      </Box>
    </Box>
  )
}

export default CalendarView
