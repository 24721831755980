import { runTransaction } from '@/firebase/helpers'
import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { AddProcessModel } from '@/models/request-body-models/add-process.model'
import { ProcessModelToAdd } from '@/models/responces/process.model'
import { EditProcessModel } from '@/models/request-body-models/edit-stage.model'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'

export default abstract class ProcessTransactions {
  @HandleAsyncErrors()
  public static async AddProcess(process: AddProcessModel) {
    await runTransaction(async transaction => {
      const newProcessDocRef = FirebaseUsage.database().collection(COLLECTIONS.PROCESSES).doc()

      transaction.set<ProcessModelToAdd>(newProcessDocRef, {
        id: newProcessDocRef.id,
        ...process,
      })
    })
  }

  //TODO Research HandleAsyncErrors + runTransaction
  @HandleAsyncErrors()
  public static async DeleteProcess(processId: string) {
    await runTransaction(async () => {
      FirebaseUsage.database().collection(COLLECTIONS.PROCESSES).doc(processId).delete()
    })
  }
  @HandleAsyncErrors()
  public static async EditProcess(process: EditProcessModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.PROCESSES).doc(process.id).update(process)
  }
}
