import { TYPED_ENV } from '@/utils/typedEnv'

export const firebaseConfig = {
  apiKey: TYPED_ENV.REACT_APP_API_KEY,
  authDomain: TYPED_ENV.REACT_APP_AUTH_DOMAIN,
  databaseURL: TYPED_ENV.REACT_APP_DATABASE_URL,
  projectId: TYPED_ENV.REACT_APP_PROJECT_ID,
  storageBucket: TYPED_ENV.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: TYPED_ENV.REACT_APP_MESSAGING_SENDER_ID,
  appId: TYPED_ENV.REACT_APP_APP_ID,
}
