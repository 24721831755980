import { ActionNames, addNoteAsync, editNoteAsync, TNoteActions } from './note.actions'
import { getType } from 'typesafe-actions'
import { NoteModel } from '@/models/responces/note.model'
import { RequestState } from '@/constants/request-state'

export class StateModel {
  note: NoteModel | null = null
  loadingSaveNote: RequestState = RequestState.UNSENT
}

export const initialState = new StateModel()

export function NoteReducer(state = initialState, action: TNoteActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_NOTE: {
      return {
        ...state,
        note: action.payload,
      }
    }
    case getType(addNoteAsync.request): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENDING,
      }
    }
    case getType(addNoteAsync.success): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENT_SUCCESS,
      }
    }
    case getType(addNoteAsync.failure): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENT_FAILED,
      }
    }

    case getType(editNoteAsync.request): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENDING,
      }
    }
    case getType(editNoteAsync.success): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENT_SUCCESS,
      }
    }
    case getType(editNoteAsync.failure): {
      return {
        ...state,
        loadingSaveNote: RequestState.SENT_FAILED,
      }
    }

    case ActionNames.RESET_LOADING: {
      return {
        ...state,
        loadingSaveNote: RequestState.UNSENT,
      }
    }
    default:
      return state
  }
}