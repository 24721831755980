import { TaskModel } from '@/models/responces/task.model'
import DefaultDialog from '@/shared/dialog/DefaultDialog'
import TaskThunk from '@/store/task/task.thunk'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MovedTask } from './Calendar'
import _ from 'lodash'
import * as uuid from 'uuid'
import { StageModel } from '@/models/responces/stage.model'
import ButtonShared from '@/shared/button/ButtonShared'
import css from "./css.module.scss"

interface InjectedProps {
  movedTask: MovedTask
  open: boolean
  onClose: () => void
}

enum Actions {
  MOVE_ALL = 'move_all',
  MOVE_SELECTED_STAGES = 'move_selected_stages',
  CANCEL = 'cancel',
}

export const MoveTaskDialog: React.FC<InjectedProps> = ({ movedTask, open, onClose }) => {
  const dispatch = useDispatch()

  const trimInactiveStages = useCallback((stages: StageModel[]) => {
    if (stages.length && !stages[stages.length - 1].isActive) {
      return stages.slice(0, -1)
    }

    return stages
  }, [])

  const setAction = useCallback(
    (type: Actions) => () => {
      if (type === Actions.CANCEL) {
        return onClose()
      }

      if (type === Actions.MOVE_ALL) {
        const taskToEdit: TaskModel = { ...movedTask.taskCopy, employeeId: movedTask.newEmployeeId }

        dispatch(TaskThunk.editTask(taskToEdit))

        onClose()
      }

      if (type === Actions.MOVE_SELECTED_STAGES) {
        const taskToEdit = _.cloneDeep(movedTask.taskCopy)
        const movedStageIndex = taskToEdit.process.stages.findIndex(s => s.id === movedTask.movedStageId)

        if (movedStageIndex === -1) {
          return
        }

        const taskToEditStages = taskToEdit.process.stages.slice(0, movedStageIndex)
        if (taskToEditStages.length) {
          taskToEdit.process.stages = trimInactiveStages(taskToEditStages)
          dispatch(TaskThunk.editTask(taskToEdit))
        } else {
          dispatch(TaskThunk.deleteTask(taskToEdit))
        }

        const taskToSave: TaskModel = _.cloneDeep(movedTask.taskCopy)
        taskToSave.employeeId = movedTask.newEmployeeId
        taskToSave.process.id = uuid.v4()
        taskToSave.originalTaskId = taskToEdit.id
        const taskToSaveStages = taskToSave.process.stages.slice(movedStageIndex)
        taskToSave.process.stages = trimInactiveStages(taskToSaveStages)
        dispatch(TaskThunk.createTask(taskToSave))
        onClose()
      }
    },
    [dispatch, onClose, movedTask, trimInactiveStages]
  )

  return (
    <DefaultDialog open={open} onClose={onClose}>
      <div className={css['move-task-dialog-wrapper']}>
        <ButtonShared color={'primary'} text={'Move all'} onClick={setAction(Actions.MOVE_ALL)} />
        <ButtonShared
          color={'primary'}
          text={'Move selected stages'}
          onClick={setAction(Actions.MOVE_SELECTED_STAGES)}
        />
        <ButtonShared color={'primary'} text={'Cancel'} onClick={setAction(Actions.CANCEL)} />
      </div>
    </DefaultDialog>
  )
}
