import { Dispatch, MiddlewareAPI } from 'redux'
import * as workspaceActions from './workspace.actions'
import { Subscriptions, subsManager } from '@/store/middleware/subs-manager/subs-manager'
import { RootStateModel } from '@/store/root-reducer'

export function workspaceMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
  return (next: Dispatch) => (action: workspaceActions.TWorkspaceActions) => {
    const returnValue = next(action)
    switch (action.type) {
      case workspaceActions.ActionNames.SET_WORKSPACE: {
        const workspace = api.getState().workspace.workspace
        
        subsManager.removeSubscription(Subscriptions.PROCESSES)
        subsManager.removeSubscription(Subscriptions.EMPLOYEES)
        subsManager.removeSubscription(Subscriptions.PROJECTS)
        subsManager.removeSubscription(Subscriptions.WORKSPACE_INVITATIONS)
        subsManager.removeSubscription(Subscriptions.TIMEOFFS)
        if (workspace) {
          subsManager.subscribeProcesses(workspace.id)
          subsManager.subscribeEmployees(workspace.id)
          subsManager.subscribeProjects(workspace.id)
          subsManager.subscribeTimeoffs(workspace.id)
          subsManager.subscribeWorkspaceInvitations(workspace.id)
        }
        break
      }

      default: {
        break
      }
    }

    return returnValue
  }
}
