import { ThunkResult } from '@/store/store.types'
import { AddProcessModel } from '@/models/request-body-models/add-process.model'
import ProcessTransactions from '@/store/process/process.transactions'
import { addProcessAsync, deleteProcessAsync, editProcessAsync } from '@/store/process/process.actions'
import { EditProcessModel } from '@/models/request-body-models/edit-stage.model'

export default class ProcessThunk {
  static addProcess(process: AddProcessModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(addProcessAsync.request(process))
        await ProcessTransactions.AddProcess(process)
        dispatch(addProcessAsync.success())
      } catch (e) {
        dispatch(addProcessAsync.failure(e))
        console.error('Add Process Error: ', e)
      }
    }
  }

  static deleteProcess(id: string): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(deleteProcessAsync.request())
        await ProcessTransactions.DeleteProcess(id)
        dispatch(deleteProcessAsync.success())
      } catch (e) {
        dispatch(deleteProcessAsync.failure(e))
        console.error('Add Process Error: ', e)
      }
    }
  }

  static editProcess(process: EditProcessModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        dispatch(editProcessAsync.request())
        await ProcessTransactions.EditProcess(process)
        dispatch(editProcessAsync.success())
      } catch (e) {
        dispatch(editProcessAsync.failure(e))
        console.error('Add Process Error: ', e)
      }
    }
  }
}
