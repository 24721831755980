import { runTransaction } from '@/firebase/helpers'
import { COLLECTIONS } from '@/firebase/constants'
import FirebaseUsage from '@/firebase/firebase.usage'
import { AddProjectModel } from '@/models/request-body-models/add-project.model'
import { ProjectModel } from '@/models/responces/project.model'
import { TaskModel } from '@/models/responces/task.model'
import { EditProjectModel } from '@/models/request-body-models/edit-projects.model'
import { getTaskToSave } from '@/utils/helpers/get-task-to-save'
import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'
import store from '@/store/store'

export default abstract class ProjectTransactions {
  @HandleAsyncErrors()
  public static async AddProject(project: AddProjectModel) {
    await runTransaction(async transaction => {
      const newProjectDocRef = FirebaseUsage.database().collection(COLLECTIONS.PROJECTS).doc()
      transaction.set<ProjectModel>(newProjectDocRef, {
        ...project.project,
        id: newProjectDocRef.id,
      })
      project.tasks.forEach(task => {
        const newTaskDocRef = FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc()
        const taskToSave = getTaskToSave(newTaskDocRef.id, {...task, employeeId: null}, newProjectDocRef.id)
        if (taskToSave) {
          transaction.set<TaskModel>(newTaskDocRef, taskToSave)
        }
      })
    })
  }

  @HandleAsyncErrors()
  public static async EditProject(project: EditProjectModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.PROJECTS).doc(project.project.id).update(project.project)
    project.tasks.forEach(async task => {
      FirebaseUsage.database()
        .collection(COLLECTIONS.TASKS)
        .doc(task.id)
        .get()
        .then(async doc => {
          if (doc.exists) {
            // just update task
            await FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc(task.id).update(task)
          } else {
            // create new task
            const newTaskDocRef = FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc()
            const taskToSave = getTaskToSave(newTaskDocRef.id, task, project.project.id)
            if (taskToSave) {
              await runTransaction(async transaction => {
                transaction.set<TaskModel>(newTaskDocRef, taskToSave)
              })
            }
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
        })

      project.deletedTasksId.map(
        async task => await FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc(task).delete()
      )
    })
  }

  @HandleAsyncErrors()
  public static async ReorderProjects(currentIndex: number, newIndex: number, isAfterDeletion?: boolean) {
    const projectsList: ProjectModel[] | null = store.getState().project.projectsList
    const indexFrom = Math.min(currentIndex, newIndex)
    const indexTo = Math.max(currentIndex, newIndex)

    if (projectsList) {
      const projectsToUpdate = projectsList.filter(
        p => isAfterDeletion ? p.orderIndex >= indexFrom : p.orderIndex >= indexFrom && p.orderIndex <= indexTo && p.orderIndex !== currentIndex
      )
      const [currentProject] = projectsList.filter((p, i) => i === currentIndex)
      const increment = isAfterDeletion ? -1 : newIndex >= currentIndex ? -1 : 1

      projectsToUpdate.forEach(async project => {
        await FirebaseUsage.database()
          .collection(COLLECTIONS.PROJECTS)
          .doc(project.id)
          .update({ ...project, orderIndex: project.orderIndex + increment })
      })

      if (!isAfterDeletion) {
        await FirebaseUsage.database()
          .collection(COLLECTIONS.PROJECTS)
          .doc(currentProject.id)
          .update({ ...currentProject, orderIndex: newIndex })
      }
    }
  }

  @HandleAsyncErrors()
  public static async DeleteProject(projectId: string, tasks: TaskModel[]) {
    await FirebaseUsage.database().collection(COLLECTIONS.PROJECTS).doc(projectId).delete()
    tasks.map(async task => await FirebaseUsage.database().collection(COLLECTIONS.TASKS).doc(task.id).delete())
  }
}
