import { ActionType, createAction } from 'typesafe-actions'
import { SnackbarTypes } from '@/shared/snackbars/snackbar'

export interface Snackbar {
  title: string
  text: string
  type: SnackbarTypes
  id?: number
}

export enum SnackbarActionNames {
  SNACKBAR_ADD = '[Snackbar] Snackbar Add',
  SNACKBAR_REMOVE = '[Snackbar] Snackbar Remove',
}

export const addSnackbar = createAction(SnackbarActionNames.SNACKBAR_ADD)<{ snackbar: Snackbar }>()
export const removeSnackbar = createAction(SnackbarActionNames.SNACKBAR_REMOVE)<{ id: number }>()

export type TSnackbarActions = ActionType<typeof addSnackbar | typeof removeSnackbar>
