import { COLLECTIONS } from "@/firebase/constants";
import FirebaseUsage from "@/firebase/firebase.usage";
import HandleAsyncErrors from "@/utils/decorators/handleAsyncErrors";
import { UserModel } from "@/models/responces/user.model"

export default abstract class UserTransactions {
  @HandleAsyncErrors()
  public static async editUser(user: UserModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.USERS).doc(user.id).update(user)
  }
}