import { ActionNames, TWorkspaceActions } from './workspace.actions'
import { MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'

export class StateModel {
  workspace: WorkspaceModel | null = null
  ownedWorkspaces: WorkspaceModel[] | null = null
  memberWorkspaces: WorkspaceModel[] | null = null
  workspaceRole: MemberRoles | null = null
}

export const initialState = new StateModel()

export function ProcessReducer(state = initialState, action: TWorkspaceActions): StateModel {
  switch (action.type) {
    case ActionNames.SET_WORKSPACE: {
      return {
        ...state,
        workspace: action.payload.workspace,
        workspaceRole: action.payload.role,
      }
    }
    case ActionNames.SET_OWNED_WORKSPACES: {
      const isChosenWorkspaceUpdated = action.payload.find(w => w.id === state?.workspace?.id || '')

      if(isChosenWorkspaceUpdated) {
        return {
          ...state,
          workspace: isChosenWorkspaceUpdated,
          ownedWorkspaces: action.payload
        }
      }

      return {
        ...state,
        ownedWorkspaces: action.payload,
      }
    }
    case ActionNames.SET_MEMBER_WORKSPACES: {
      const isChosenWorkspaceUpdated = action.payload.find(w => w.id === state?.workspace?.id || '')

      if(isChosenWorkspaceUpdated) {
        return {
          ...state,
          workspace: isChosenWorkspaceUpdated,
          memberWorkspaces: action.payload
        }
      }

      return {
        ...state,
        memberWorkspaces: action.payload,
      }
    }
    default:
      return state
  }
}
