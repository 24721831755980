import React, { useCallback, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import gearIcon from '@/assets/icons/gear-icon.svg'
import css from './css.module.scss'
import DefineProcessesDialog from '@/pages/process/DefineProcessesDialog'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootStateModel } from '@/store/root-reducer'

const ProcessSettings = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)

  const toggleProcessDialog = useCallback(() => {
    setOpenDialog(openDialog => !openDialog)
  }, [])

  return (
    <>
      <div className={css['wrapper']}>
        <Box className={css['wrapper__settings']} onClick={toggleProcessDialog}>
          <img src={gearIcon} alt="" />
          <Typography variant={'button'} className={classNames(css['wrapper__settings__text'], {
            [css['wrapper__settings__text--hidden']]: isSidebarCollapsed
          })}>
            Settings
          </Typography>
        </Box>
        {openDialog && <DefineProcessesDialog open={openDialog} onClose={toggleProcessDialog} />}
      </div>
    </>
  )
}

export default ProcessSettings
