import HandleAsyncErrors from '@/utils/decorators/handleAsyncErrors'
import { runTransaction } from '@/firebase/helpers'
import FirebaseUsage from '@/firebase/firebase.usage'
import { COLLECTIONS } from '@/firebase/constants'
import { AddNoteModel } from '@/models/request-body-models/add-note.model'
import { NoteModel } from '@/models/responces/note.model'

export default abstract class NoteTransactions {
  @HandleAsyncErrors()
  public static async AddNote(note: AddNoteModel) {
    await runTransaction(async transaction => {
      const newNoteDocRef = FirebaseUsage.database().collection(COLLECTIONS.NOTES).doc()

      transaction.set<NoteModel>(newNoteDocRef, {
        ...note,
        id: newNoteDocRef.id,
      })
    })
  }

  @HandleAsyncErrors()
  public static async EditNote(note: NoteModel) {
    await FirebaseUsage.database().collection(COLLECTIONS.NOTES).doc(note.id).update(note)
  }
}
