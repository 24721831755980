import { TaskModel } from '@/models/responces/task.model'
import TaskTransactions from '@/store/task/task.transactions'
import { removeItem } from '@/store/calendar/calendar.actions'
import { ThunkResult } from '@/store/store.types'
import store from '@/store/store'
import { setAssignedTasks } from '../project/project.actions'

export default class TaskThunk {
  static editTask(task: TaskModel, projectId?: string, newTasksList?: TaskModel[], oldTasksList?: TaskModel[]) {
    return async () => {
      if (projectId && newTasksList && oldTasksList) {
        try {
          store.dispatch(setAssignedTasks({ projectId, taskList: newTasksList }))
          await TaskTransactions.EditTask(task)
        } catch (e) {
          store.dispatch(setAssignedTasks({ projectId, taskList: oldTasksList }))
          console.log('Error when editing task', e)
        }
      } else {
        try {
          await TaskTransactions.EditTask(task)
        } catch (e) {
          console.log('Error when editing task', e)
        }
      }
    }
  }
  static unassignTask(task: TaskModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await TaskTransactions.UnassignTask(task)
        dispatch(removeItem({ id: task.id }))
      } catch (e) {
        console.log('Error when unassigning task', e)
      }
    }
  }

  static deleteTask(task: TaskModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await TaskTransactions.DeleteTask(task)
        dispatch(removeItem({ id: task.id }))
      } catch (err) {
        console.log('Error while task deletion')
      }
    }
  }

  static createTask(task: TaskModel): ThunkResult<Promise<void>> {
    return async dispatch => {
      try {
        await TaskTransactions.CreateTask(task)
      } catch (err) {
        console.log('Error while task creation')
      }
    }
  }
}
