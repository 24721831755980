import { MemberRoles } from '@/models/responces/workspace.model'
import { RootStateModel } from '@/store/root-reducer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useRequireRole = (role: MemberRoles) => {
  const selectedWorkspaceUserRole = useSelector<RootStateModel, MemberRoles | null>(
    state => state.workspace.workspaceRole
  )
  const [isRoleAllowed, setIsRoleAllowed] = useState(false)

  useEffect(() => {
    const isAllowed = selectedWorkspaceUserRole === role

    setIsRoleAllowed(isAllowed)
  }, [selectedWorkspaceUserRole, role])

  return { isRoleAllowed }
}
