import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import { CirclePicker, ColorResult } from 'react-color'
import { palette } from '@/constants/material-colors'

const ColorPickerShared: React.FC<{ color: string; changeColor: (value: string) => void }> = ({
  color,
  changeColor,
}) => {
  const [showPicker, setShowPicker] = useState(false)

  const toggleShowPicker = useCallback(() => {
    setShowPicker(showPicker => !showPicker)
  }, [])

  const onColorChange = useCallback(
    (color: ColorResult) => {
      changeColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    },
    [changeColor]
  )

  return (
    <Box className={css['picker-wrapper']}>
      <Box className={css['picker-wrapper__color-box']} style={{ backgroundColor: color }} onClick={toggleShowPicker} />
      {showPicker && (
        <>
          <Box className={css['picker-wrapper__popover']}>
            <Box className={css['picker-wrapper__popover__cover']} onClick={toggleShowPicker} />
            <CirclePicker width={'480px'} circleSpacing={8} circleSize={20} colors={palette} onChange={onColorChange} color={color} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default ColorPickerShared
