import React from 'react'
import { Box, Typography } from '@material-ui/core'
import addIcon from '@/assets/icons/add-link-icon.svg'
import css from './css.module.scss'

interface InjectedProps {
  text: string
  onClick: () => void
}

const AddLinkShared: React.FC<InjectedProps> = ({ onClick, text }) => {
  return <Box onClick={onClick} className={css['wrapper']}>
    <img src={addIcon} alt=""/>
    <Typography variant={'button'} className={css['wrapper__text']}>{text}</Typography>
  </Box>
}

export default AddLinkShared
