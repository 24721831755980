import React, { useMemo } from 'react'
import { Box, Drawer, IconButton, Typography } from '@material-ui/core'
import expandIcon from '../../assets/icons/expand-icon.svg'
import css from './css.module.scss'
import { TaskModel } from '@/models/responces/task.model'
import { millisecondsToHours } from '@/utils/transform-time'
import { ProjectModel } from '@/models/responces/project.model'

interface InjectedProps {
  open: boolean
  onClose: () => void
  task: TaskModel
  projectsList: ProjectModel[]
}

const TaskDetailsDrawer: React.FC<InjectedProps> = ({ open, onClose, task, projectsList }) => {
  const projectTitle = useMemo(() => {
    const project = projectsList.find(p => p.id === task.projectId)

    if (project) {
      return project.name
    }

    return ''
  }, [projectsList, task])

  return (
    <Drawer onClose={onClose} classes={{ paper: css['paper'], root: css['drawer'] }} anchor="right" open={open}>
      <Box className={css['drawer__header']} onClick={onClose}>
        <Typography variant="h1" className={css['drawer__header__text']}>
          Task Details
        </Typography>
        <IconButton>
          <img src={expandIcon} alt="" className={css['drawer__img']} />
        </IconButton>
      </Box>
      <Box className={css['drawer__content']}>
        <Typography variant="h2" className={css['drawer__content__title']}>
          Project Name
        </Typography>
        <Typography variant="caption" className={css['drawer__content__text']}>
          {projectTitle}
        </Typography>
        <Typography variant="h2" className={css['drawer__content__title']}>
          Task Name
        </Typography>
        <Typography variant="caption" className={css['drawer__content__text']}>
          {task.name}
        </Typography>
        <div className={css['drawer__content__divider']} />
        <Typography variant="h2" className={css['drawer__content__title']}>
          Stages
        </Typography>
        {task.process.stages.map(stage => {
          return (
            <div className={css['drawer__content__stages']} key={stage.id}>
              <Typography variant="h2" className={css['drawer__content__title']}>
                {stage.name}
              </Typography>
              <Typography
                variant="h2"
                style={{ fontWeight: 400, fontFamily: 'ProximaNova regular' }}
                className={css['drawer__content__title']}
              >
                {millisecondsToHours(stage.workHours)} Hours
              </Typography>
              <Typography
                variant="h2"
                className={
                  css[`${stage.isActive ? 'drawer__content__text--active' : 'drawer__content__text--inactive'}`]
                }
                style={{ fontWeight: 400, fontFamily: 'ProximaNova regular' }}
              >
                {stage.isActive ? 'Active' : 'Inactive'}
              </Typography>
            </div>
          )
        })}
      </Box>
    </Drawer>
  )
}

export default TaskDetailsDrawer
