import React, { useCallback } from 'react'
import { Box, TextField } from '@material-ui/core'
import css from './css.module.scss'
import Loading from '@/components/wrappers/loading'

interface InjectedProps {
  value: string
  onChange: (value: string) => void
  onBlur: () => void
  placeholder: string
  isLoading?: boolean
}

const TextAreaShared: React.FC<InjectedProps> = ({ value, onChange, onBlur, placeholder, isLoading }) => {
  const changeValue = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange(evt.target.value)
    },
    [onChange]
  )

  return (
    <Loading isLoading={!!isLoading}>
      <Box className={css['wrapper']}>
        <TextField
          classes={{ root: css['wrapper__input'] }}
          value={value}
          onChange={changeValue}
          placeholder={placeholder}
          variant="outlined"
          multiline
          onBlur={onBlur}
          disabled={isLoading}
        />
      </Box>
    </Loading>

  )
}

export default TextAreaShared
