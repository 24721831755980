import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firebaseConfig } from '@/firebase/firebase.config'

export default class FirebaseUsage {
  public static app: firebase.app.App = firebase.initializeApp(firebaseConfig)

  public static functions(functionName: string) {
    return FirebaseUsage.app.functions().httpsCallable(functionName)
  }

  public static initializeApp() {
    return FirebaseUsage.app
  }

  public static auth(): firebase.auth.Auth {
    return firebase.auth()
  }

  public static database() {
    return FirebaseUsage.app.firestore()
  }

  public static fieldValue() {
    return firebase.firestore.FieldValue
  }
}
