import React from 'react'
import css from './css.module.scss'
import { CircularProgress } from '@material-ui/core'

export const Loading: React.FC<{isLoading: boolean}> = ({ isLoading }) => {
  return (
    isLoading ? (
      <div className={css['loading-wrap__mask']}>
        <CircularProgress className={css['loading-wrap__progress']} />
      </div>
    ) : <></>
  )
}
export default Loading
