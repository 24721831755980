import React from 'react'
import css from './css.module.scss'
import { CircularProgress } from '@material-ui/core'
import { ComponentProps } from '@/components/wrappers/loading/models'

export const Loading: React.FC<ComponentProps> = ({ isLoading, children }) => {
  const LoadingMask = (
    <div className={css['loading-wrap__mask']}>
      <CircularProgress className={css['loading-wrap__progress']} />
    </div>
  )
  return (
    <div className={css['loading-wrap']}>
      {children}
      {isLoading && LoadingMask}
    </div>
  )
}
export * from './models'
export default Loading
