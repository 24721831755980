import { Invitation } from '@/models/responces/invitation.model'
import { UserModel } from '@/models/responces/user.model'
import { WorkspaceModel } from '@/models/responces/workspace.model'
import { RootStateModel } from '@/store/root-reducer'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import firebase from 'firebase/app'
import InvitationTransactions from '@/store/invitation/invitation.transactions'
import { Typography } from '@material-ui/core'
import DefaultDialog from '../dialog/DefaultDialog'
import { Email, EmailTextArea } from './EmailTextArea'
import ButtonShared from '../button/ButtonShared'
import css from './css.module.scss'
import researchProject from '@/assets/images/research-project.svg'

export const InviteMembersDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [emails, setEmails] = useState<Email[]>([])
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const selectedWorkspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)

  const onEmailsChange = useCallback((e: Email[]) => {
    setEmails(e)
  }, [])

  const sendInvitations = useCallback(async () => {
    if (!selectedWorkspace || !user) {
      return
    }

    const promises: Promise<void>[] = []

    emails.forEach(e => {
      const invitationToCreate: Invitation = {
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        invitedById: user.id,
        invitedByName: user.name,
        workspaceId: selectedWorkspace.id,
        workspaceName: selectedWorkspace.name || 'Default name for test',
        userEmail: e.email,
      }

      promises.push(InvitationTransactions.createInvitation(invitationToCreate))
    })

    await Promise.allSettled(promises)

    onClose()
  }, [emails, selectedWorkspace, user, onClose])

  return (
    <DefaultDialog open={open} onClose={onClose}>
      <div className={css['invite-member-dialog']}>
        <div style={{ marginBottom: '40px' }} className={css['row']}>
          <img alt={'Project Research'} src={researchProject} />
          <div style={{ flexDirection: 'column', marginLeft: '17px' }} className={css['row']}>
            <Typography variant={'h1'}>Invite someone to this workspace</Typography>
            <span className={css['sub-text']}>Find member by email</span>
          </div>
        </div>
        <div style={{ flexDirection: 'column' }} className={css['row']}>
          <span className={css['input-label']}>Email</span>
          <EmailTextArea onEmailsChange={onEmailsChange} />
        </div>
      </div>
      <div className={css['footer']}>
        <ButtonShared className={css['cancel-button']} color={'secondary'} onClick={onClose} text={'Cancel'} />
        <ButtonShared disabled={!emails.length || !!emails.find(e => !e.isValid)} color={'primary'} onClick={sendInvitations} text={'Send Invitation'} />
      </div>
    </DefaultDialog>
  )
}
