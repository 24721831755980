import React, { useCallback, useMemo, useRef, useState } from 'react'
import { coordinateToTimeRatio } from './lib/Calendar'
import { getSumOffset, getSumScroll } from './lib/dom-helpers'
import css from './css.module.scss'
import { TaskModel } from '@/models/responces/task.model'
import { useDispatch } from 'react-redux'
import { setCurrentlyDraggable, setDraggableCoordinates } from '@/store/calendar/calendar.actions'
import Draggable from 'react-draggable'
import { useLocation } from 'react-router-dom'
import RouteNameConstants from '@/constants/route-name.constants'
import { useRequireRole } from '@/utils/useRequireRole'
import { MemberRoles } from '@/models/responces/workspace.model'

interface InjectedProps {
  handleDrop: any
  timelineRef: any
  scrollRef: any
  task: TaskModel
}

const DraggableSharedTask: React.FC<InjectedProps> = props => {
  const { task, children, timelineRef, scrollRef, handleDrop } = props
  const item = useRef(null)
  const dispatch = useDispatch()
  const [isShown, setIsShown] = useState(true)
  const location = useLocation()
  const { isRoleAllowed } = useRequireRole(MemberRoles.OWNER)
  const isDraggable = useMemo(() => {
    return isRoleAllowed && location.pathname.includes(`/${RouteNameConstants.WORKSPACES}`)
  }, [location, isRoleAllowed])

  const handleStop = useCallback(
    (e: any) => {
      dispatch(setCurrentlyDraggable(null))
      dispatch(setDraggableCoordinates(null))

      const { canvasTimeStart, visibleTimeStart, visibleTimeEnd, groupTops, width } = timelineRef.current.state

      const canvasWidth = width * 3
      const zoom = visibleTimeEnd - visibleTimeStart
      const canvasTimeEnd = zoom * 3 + canvasTimeStart
      const ratio = coordinateToTimeRatio(canvasTimeStart, canvasTimeEnd, canvasWidth)

      const { offsetLeft, offsetTop } = getSumOffset(scrollRef.current)
      const { scrollLeft, scrollTop } = getSumScroll(scrollRef.current)
      const { pageX, pageY } = e

      const x = pageX - offsetLeft + scrollLeft
      const y = pageY - offsetTop + scrollTop

      const start = x * ratio + canvasTimeStart

      let groupKey = ''
      for (const key of Object.keys(groupTops)) {
        const groupTop = groupTops[key]
        if (y > groupTop) {
          groupKey = key
        } else {
          break
        }
      }

      if (groupKey === '' || pageX < offsetLeft || pageX > offsetLeft + width) {
        setIsShown(true)
        return
      }

      handleDrop({ task: task, start, groupKey })
    },
    [task, handleDrop, scrollRef, timelineRef, dispatch]
  )

  const handleStart = useCallback(
    e => {
      e.stopPropagation()
      const { pageX, pageY } = e
      dispatch(setCurrentlyDraggable(task))
      dispatch(setDraggableCoordinates({ x: pageX, y: pageY }))
      setIsShown(false)
    },
    [task, dispatch]
  )

  const handleDrag = useCallback(
    e => {
      const { pageX, pageY } = e
      dispatch(setDraggableCoordinates({ x: pageX, y: pageY }))
    },
    [dispatch]
  )

  return (
    <Draggable
      disabled={!isDraggable}
      handle=".handle"
      position={{ x: 0, y: 0 }}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <div className="handle">
        <div ref={item} className={css[`${isShown ? 'draggable' : 'draggable-hidden'}`]}>
          {children}
        </div>
      </div>
    </Draggable>
  )
}

export default DraggableSharedTask
