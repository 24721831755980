import { TSnackbarActions,SnackbarActionNames } from './snackbar.actions'
import { Snackbar } from '@/store/snackbar/snackbar.actions'

export class StateModel {
  snackbars: Snackbar[] = [];
  counter: number = 0;
}

export const initialState = new StateModel()

export function SnackbarReducer(state = initialState, action: TSnackbarActions): StateModel {
  switch (action.type) {
    case SnackbarActionNames.SNACKBAR_ADD:
      return {
        ...state,
        counter: state.counter + 1,
        snackbars: [
          ...state.snackbars,
          {
            ...action.payload.snackbar,
            id: state.counter
          }
        ]
      };
    case SnackbarActionNames.SNACKBAR_REMOVE:
      return {
        ...state,
        snackbars: state.snackbars.filter(s => s.id !== action.payload.id)
      };
    default:
      return state
  }
}