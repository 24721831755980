import React, { useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import css from './css.module.scss'
import ProjectManaging from '@/pages/project-managing/project-managing'
import ProcessSettings from '@/pages/process/ProcessSettings'
import CalendarView from '@/pages/calendar/CalendarView'
import ProjectList from '@/pages/project/components/project-list-sidebar/ProjectList'
import Header from '@/shared/header/Header'
import logo from '../../assets/icons/logo.svg'
import { useHistory, useParams } from 'react-router-dom'
import { MemberRoles, WorkspaceModel } from '@/models/responces/workspace.model'
import { RootStateModel } from '@/store/root-reducer'
import { useSelector, useDispatch } from 'react-redux'
import RouteNameConstants from '@/constants/route-name.constants'
import { setWorkspace } from '@/store/workspace/workspace.actions'
import { UserModel } from '@/models/responces/user.model'
import { NoWorkspaces } from './NoWorkspaces'
import { RequireRoleHOC } from '@/utils/RequireRoleHOC'
import classNames from 'classnames'
import { CollapseIcon } from './CollapseIcon'

interface Params {
  workspaceId: string
}

const Workspace = () => {
  const params = useParams<Params>()
  const ownedWorkspaces = useSelector<RootStateModel, WorkspaceModel[] | null>(state => state.workspace.ownedWorkspaces)
  const memberWorkspaces = useSelector<RootStateModel, WorkspaceModel[] | null>(
    state => state.workspace.memberWorkspaces
  )
  const currentWorkspace = useSelector<RootStateModel, WorkspaceModel | null>(state => state.workspace.workspace)
  const user = useSelector<RootStateModel, UserModel | null>(state => state.authorization.user)
  const history = useHistory()
  const isSidebarCollapsed = useSelector<RootStateModel, boolean>(state => state.sidebar.isCollapsed)

  const dispatch = useDispatch()

  const workspaces = useMemo(() => {
    if (ownedWorkspaces && memberWorkspaces) {
      return [...ownedWorkspaces, ...memberWorkspaces]
    }

    if (ownedWorkspaces) {
      return [...ownedWorkspaces]
    }

    if (memberWorkspaces) {
      return [...memberWorkspaces]
    }

    return null
  }, [ownedWorkspaces, memberWorkspaces])

  useEffect(() => {
    const { workspaceId } = params
    if (!ownedWorkspaces || !memberWorkspaces || !user) return
    const isWorkspaceExist = workspaces && workspaces.find(w => w.id === workspaceId)

    if (workspaceId && !isWorkspaceExist) {
      history.push(`/${RouteNameConstants.WORKSPACES}`)
      return
    }

    if (!workspaceId && workspaces && workspaces.length) {
      const [workspace] = workspaces
      history.push(`/${RouteNameConstants.WORKSPACES}/${workspace.id}`)
      return
    }

    if (workspaceId && workspaces) {
      if (!currentWorkspace || (currentWorkspace && workspaceId !== currentWorkspace.id)) {
        // history.push(`/${RouteNameConstants.WORKSPACES}/${workspaceId}`)
        const workspaceToSet = workspaces.find(w => w.id === workspaceId)
        let userRole = MemberRoles.VIEWER

        if (workspaceToSet && workspaceToSet.ownerId === user.id) {
          userRole = MemberRoles.OWNER
        }

        dispatch(setWorkspace({ workspace: workspaceToSet!, role: userRole }))
      }
    }
  }, [params, history, workspaces, dispatch, currentWorkspace, user, ownedWorkspaces, memberWorkspaces])

  if (!params.workspaceId && workspaces) {
    return <NoWorkspaces />
  }

  return (
    <Box className={css['root']}>
      <Box className={css['wrapper']}>
        <Box
          className={classNames(css['wrapper__sidebar'], {
            [css['wrapper__sidebar--collapsed']]: isSidebarCollapsed,
          })}
        >
          <img src={logo} alt="" className={css['wrapper__sidebar__logo']} />
          <ProjectList />
          <RequireRoleHOC role={MemberRoles.OWNER}>
            <ProjectManaging />
            <ProcessSettings />
          </RequireRoleHOC>
          <CollapseIcon />
        </Box>
        <Box
          className={classNames(css['wrapper__calendar'], {
            [css['wrapper__calendar--collapsed']]: isSidebarCollapsed,
          })}
        >
          <Header />
          <CalendarView />
        </Box>
      </Box>
    </Box>
  )
}

export default Workspace
