import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import css from './css.module.scss'
import crossIcon from '@/assets/icons/cross-icon.svg'

interface InjectedProps {
  onClose: () => void
  open: boolean
  className?: string
}

const DefaultDialog: React.FC<InjectedProps> = ({ open, onClose, children, className }) => {
  return (
    <Dialog onClose={onClose} open={open} className={className || ''} classes={{ paper: css['dialog-default'] }}>
      <img src={crossIcon} alt="" onClick={onClose} className={css['dialog-default__close-icon']} />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default DefaultDialog
