import FirebaseUsage from '../firebase.usage'
import { COLLECTIONS } from '../constants'
import { DefaultSubscribeCallback } from '@/store/middleware/middlewares'
import { NoteModel } from '@/models/responces/note.model'

export class NoteSubscription {
  static note(userId: string, callback: DefaultSubscribeCallback<NoteModel>) {
    return FirebaseUsage.database()
      .collection(COLLECTIONS.NOTES)
      .where('ownerId', '==', userId)
      .onSnapshot(data => {
        let note: NoteModel | null = null
        data.docs.forEach(doc => {
          note = doc.data() as NoteModel
        })
        if (note) {
          callback(note)
        }
      })
  }
}
